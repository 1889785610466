/* eslint-disable no-unused-vars */
/* global JitsiMeetJS, config */
import cameraOffPoster from '../../assets/img/RollingLoader.gif';
import { makeObservable, configure, observable, action } from 'mobx';
import {
  getLoggedInUserObject,
  getParticipantDisplayName,
  getRandomColorHex,
  isBrowserFirefox,
  isBrowserSafari,
  isEmailValid,
  shortenString,
} from '../../helpers/utils';
import { getUserAccount } from '../../services/LoginService';
import {
  getMeetingById,
  getGuestUserName,
  updateContact,
  getGroupDetails,
  searchGroups,
  searchContacts,
  createContact,
  inviteUser,
} from '../../services/MeetingService';
import firebaseconfig, { analytics } from '../../firebaseconfig';
import React from 'react';
import Avatar from 'react-avatar';
import { debounce } from 'throttle-debounce';
import { v4 as uuidv4 } from 'uuid';
import { createJitsiStreamBackgroundEffect } from '../../features/stream-effects/virtual-background';
import _ from 'lodash';
import { toastError, toastSuccess, toastWarning } from '../../helpers/toastNotification';
import Honeybadger from '@honeybadger-io/js';
import recordingOnAudio from '../../assets/recordingOn.mp3';
import recordingOffAudio from '../../assets/recordingOff.mp3';

const confOptions = { openBridgeChannel: true };
const options = {
  hosts: {
    domain: process.env.REACT_APP_JITSI_ENDPOINT,
    muc: 'conference.' + process.env.REACT_APP_JITSI_ENDPOINT, // FIXME: use XEP-0030
  },
  serviceUrl: `https://${process.env.REACT_APP_JITSI_ENDPOINT}/http-bind`, // FIXME: use xep-0156 for that

  // The name of client node advertised in XEP-0115 'c' stanza
  clientNode: 'http://jitsi.org/jitsimeet',
};
const TRACK_TYPE = Object.freeze({
  VIDEO: 'video',
  DESKTOP: 'desktop',
  AUDIO: 'audio',
});
const initOptions = {
  disableAudioLevels: false,

  // The ID of the jidesha extension for Chrome.
  desktopSharingChromeExtId: null,

  // Whether desktop sharing should be disabled on Chrome.
  desktopSharingChromeDisabled: false,
  // The media sources to use when using screen sharing with the Chrome
  // extension.
  desktopSharingChromeSources: ['screen', 'window'],

  // Required version of Chrome extension
  desktopSharingChromeMinExtVersion: '0.1',

  // Whether desktop sharing should be disabled on Firefox.
  desktopSharingFirefoxDisabled: false,

  // disableSimulcast: false,
  enableTalkWhileMuted: true,
  enableNoisyMicDetection: true,
  disableSimulcast: false,
};
configure({
  enforceActions: 'never',
});
const AUDIO_TRACK = {
  MUTE: 'Muted',
  UNMUTE: 'Unmuted',
  TRACK_NOT_AVAILABLE: 'Track not available',
};
export default class JitsiConferenceProvider {
  constructor() {
    makeObservable(this, {
      isValid: observable,
      isModerator: observable,
      isAudioMuted: observable,
      isVideoMuted: observable,
      isJoined: observable,
      participantListAudio: observable,
      currentUser: observable,
      participantsList: observable,
      messages: observable,
      message: observable,
      guestUserDisplayName: observable,
      unreadCount: observable,
      isScreenSharingOn: observable,
      hasMoreThanOneParticipant: observable,
      isLocalStreaming: observable,
      idTrackMap: observable,
      smallScreensMap: observable,
      idDisplayNameMap: observable,
      connectionFailed: observable,
      screenShareOnBigScreen: observable,
      leaveMeetingWarning: observable,
      showConnectionStatus: observable,
      hideParticipantTiles: observable,
      startAudioMuted: observable,
      startVideoMuted: observable,
      isPreLaunch: observable,
      showConnectionFailedModal: observable,
      connectionFailedCountdownSeconds: observable,
      isBigScreenHolderMuted: observable,
      speakerOnScreen: observable,
      isTileView: observable,
      isNoteView: observable,
      idAvatarColorMap: observable,
      isInviteComponentVisible: observable,
      isMutedNotify: observable,
      moderatorName: observable,
      muteButtonClick: observable,
      cameraOptions: observable,
      microphoneOptions: observable,
      cameraDeviceId: observable,
      micDeviceId: observable,
      meetingModerator: observable,
      uploadModal: observable,
      documentSideBar: observable,
      documentList: observable,
      photos: observable,
      documentLoading: observable,
      documentMessage: observable,
      wasAdded: observable,
      docBadge: observable,
      selfParticipantId: observable,
      createJitsiLocalTrack: action.bound,
      localSmallScreen: observable,
      videoTrackLocalState: observable,
      isCardToggled: observable,
      onTrackRemoved: action.bound,
      remoteVideoTrackMuteChangedEventHandler: action.bound,
      onRemoteTrack: action.bound,
      tileAttachVideo: action.bound,
      onUserJoined: action.bound,
      onUserLeft: action.bound,
      onVideoMuteStateChanged: action.bound,
      attachVideo: action.bound,
      onAudioMuteStateChanged: action.bound,
      handleMuteAllParticipant: action.bound,
      restrictAllUserForMute: action.bound,
      restrictAllUserForChat: action.bound,
      handleAudioStatus: observable,
      getMediaDevices: action.bound,
      handleMediaDevices: action.bound,
      handleDisposeTracks: action.bound,
      attachTrack: action.bound,
      detachTrack: action.bound,
      onLocalTracks: action.bound,
      conditionalSwitchBigScreen: action.bound,
      switchBigScreen: action.bound,
      connectMeeting: action.bound,
      handleMuteRemoteParticipant: action.bound,
      room: observable,
      streamCommandReceived: observable,
      shareScreen: action.bound,
      switchVideo: action.bound,
      isGuest: observable,
      isBrowserWarning: observable,
      desktopTrackLocal: observable,
      tabShareAudioTrack: observable,
      onConferenceJoined: action.bound,
      setFlagChangeClick: action.bound,
      getProfilePicture: action.bound,
      handleTileView: action.bound,
      handleNotesView: action.bound,
      meeting: observable,
      disableJoinButton: observable,
      setStartAudioMuted: action.bound,
      setStartVideoMuted: action.bound,
      stopScrenShare: action.bound,
      displayName: observable,
      reset: action.bound,
      connectionQualityMap: observable,
      pinParticipant: action.bound,
      errorMessage: observable,
      connectionBandwidthQuality: observable,
      isDeviceChanged: observable,
      isGuestUserNameChange: observable,
      lastDominantUser: observable,
      idAudioLevelMap: observable,
      isInternalUser: observable,
      isExapandVideo: observable,
      isReallyLowBandWidthFun: observable,
      audioMap: observable,
      presenterName: observable,
      presenterList: observable,
      newAudioDevice: observable,
      isQuickCall: action.bound,
      virtualBackgroundValue: observable,
      hasPermissions: observable,
      virtualBackgroundType: observable,
      hasAudioPermissionIssue: observable,
      hasVideoPermissionIssue: observable,
      jitsiErrorMessage: observable,
      recordingNotification: observable,
      recordingLimitReached: observable,
      redordingMessage: observable,
      restrictedMuteMessage: observable,
      pinnedParticipant: observable,
      pinnedParticipantScreen: observable,
      mainVideo: observable,
      mainVideoId: observable,
      isRestrictedAudio: observable,
      isRestrictedChat: observable,
      isUnauthorisedModal: observable,
      screenshareIds: observable,
      firstTimeSharing: observable,
      isRecordingStartPrelaunch: observable,
      isAudioRecordingStarted: observable,
      isVideoRecordingStarted: observable,
      isEntireScreenShared: observable,
      isAddedAudioTrack: observable,
      kickUser: action.bound,
      startVideoRecording: action.bound,
      stopVideoRecording: action.bound,
      handleAddParticipantModal: action.bound,
      handleCreateParticipants: action.bound,
      handleSelectUsersChange: action.bound,
      handleRemoveUser: action.bound,
      handleNewInvitees: action.bound,
      videoRecorderSessionId: observable,
      webinarSpeakers: observable,
      localAudioLevel: observable,
      isAddParticipantModal: observable,
      isSearchingUsers: observable,
      newOption: observable,
      searchResult: observable,
      selectedUsers: observable,
      newParticipantsList: observable,
      emails: observable,
      isLoadingRecording: observable,
      allDevices: observable,
      outputDeviceId: observable,
      changeAudioOutput: action.bound,
      shouldFlipVideo: observable,
      participantFlipVideo: observable,
      isBadBandwidth: observable,
      flipOwnVideo: action.bound,
      flipParticipantVideo: action.bound,
    });
  }
  remoteTracks = {};
  mainVideo = '';
  mainVideoId = '';
  screenshareIds = [];
  recordingNotification = false;
  isDeviceChanged = false;
  connectionStats = null;
  isValid = true;
  sessionId = '';
  isModeratorAlert = true;
  recordingStatus = false;
  isModerator = false;
  isAudioMuted = false;
  isVideoMuted = false;
  isJoined = false;
  participantArray = [];
  participantListAudio = [];
  currentUser = {};
  participantsList = [];
  messages = [];
  message = '';
  guestUserDisplayName = '';
  presenterName = '';
  presenterList = [];
  unreadCount = 0;
  isScreenSharingOn = false;
  hasMoreThanOneParticipant = false;
  participantElement = [];
  isLocalStreaming = false;
  idTrackMap = new Map();
  smallScreensMap = new Map();
  bigScreensMap = new Map();
  idDisplayNameMap = new Map();
  connectionFailed = false;
  screenShareOnBigScreen = false;
  leaveMeetingWarning = false;
  showConnectionStatus = true;
  hideParticipantTiles = false;
  startAudioMuted = false;
  startVideoMuted = false;
  isPreLaunch = false;
  showConnectionFailedModal = false;
  connectionFailedCountdownSeconds = 15;
  isBigScreenHolderMuted = false;
  speakerOnScreen = null;
  isTileView = true;
  isNoteView = false;
  idAvatarColorMap = new Map();
  isInviteComponentVisible = false;
  isMutedNotify = false;
  moderatorName = '';
  muteButtonClick = false;
  cameraOptions = [];
  microphoneOptions = [];
  cameraDeviceId = [];
  micDeviceId = [];
  meetingModerator = [];
  uploadModal = false;
  documentSideBar = false;
  documentList = [];
  photos = [];
  documentLoading = false;
  documentMessage = '';
  wasAdded = false;
  docBadge = 0;
  connection = {};
  isVideo = true;
  thisconnection = null;
  room = null;
  localTracks = [];
  isVideoAvailable = false;
  isAudioAvailable = false;
  myJitsiId;
  displayName = '';
  profilePicture;
  participantsDetails = [];
  eventSource;
  pinnedParticipant = null;
  pinnedParticipantScreen = null;
  meeting = {};
  selfParticipantId = null;
  streamCommandReceived = false;
  meetingStatusSocket;
  recorder = null;
  socket = null;
  setConnected = false;
  base64data = null;
  reader = null;
  hasOtherParticipantSharedScreen = false;
  desktopTrackLocal = null;
  tabShareAudioTrack = null;
  videoTrackLocal = null;
  audioTrackLocal = null;
  localSmallScreen = null;
  videoTrackLocalState = null;
  isCardToggled = false;
  connectionQualityMap = null;
  isGuest = false;
  isBrowserWarning = false;
  disableJoinButton = false;
  recordingAudio = null;
  errorMessage = false;
  isGuestUserNameChange = false;
  connectionBandwidthQuality = null;
  lastDominantUser = 0;
  idAudioLevelMap = new Map();
  isInternalUser = false;
  isExapandVideo = false;
  audioMap = new Map();
  newAudioDevice = '';
  hasVirtualBackground = false;
  virtualBackgroundValue = 'none';
  virtualBackgroundType = 'none';
  hasPermissions = false;
  hasAudioPermissionIssue = false;
  hasVideoPermissionIssue = false;
  jitsiErrorMessage = '';
  recordingLimitReached = false;
  redordingMessage = '';
  restrictedMuteMessage = '';
  isRestrictedAudio = false;
  isRestrictedChat = false;
  isUnauthorisedModal = false;
  firstTimeSharing = true;
  isRecordingStartPrelaunch = false;
  isAudioRecordingStarted = false;
  isVideoRecordingStarted = false;
  isEntireScreenShared = false;
  isAddedAudioTrack = false;
  videoRecorderSessionId = null;
  isAddParticipantModal = false;
  isSearchingUsers = false;
  webinarSpeakers = [];
  localAudioLevel = 0;
  newOption = [];
  searchResult = [];
  selectedUsers = [];
  newParticipantsList = [];
  emails = [];
  isLoadingRecording = false;
  outputDeviceId = '';
  allDevices = [];
  shouldFlipVideo = true;
  isBadBandwidth = false;
  participantFlipVideo = [];
  reset = async () => {
    // Disconnect - unload
    const leaveRoom = async () => {
      // try {
      //   //console.log('Disposing AudioTrack', this.audioTrackLocal, this.localTracks);
      //   if (this.audioTrackLocal && !this.audioTrackLocal.disposed) {
      //     await this.audioTrackLocal.dispose();
      //     this.room && this.room.removeTrack(this.audioTrackLocal);
      //   }
      // } catch (error) {
      //   console.log(error, 'Leave room - Audio', this.audioTrackLocal);
      // }
      // try {
      //   console.log('Disposing Desktop', this.desktopTrackLocal);
      //   if (this.desktopTrackLocal && !this.desktopTrackLocal.disposed) {
      //     await this.desktopTrackLocal.dispose();
      //     this.room && this.room.removeTrack(this.desktopTrackLocal);
      //     //this.videoTrackLocal = undefined;
      //   }
      // } catch (error) {
      //   console.log(error, 'Leave room - Desktop');
      // }
      // try {
      //   if (this.videoTrackLocal && !this.videoTrackLocal.disposed) {
      //     if (this.hasVirtualBackground === true) {
      //       await this.videoTrackLocal.setEffect(undefined);
      //     }
      //     await this.videoTrackLocal.dispose();
      //     this.room && this.room.removeTrack(this.videoTrackLocal);
      //     //console.log('Disposing Videotrack', this.videoTrackLocal, this.room, this.localTracks);
      //   }
      // } catch (error) {
      //   console.log(error, 'Leave room - Video');
      // }
      await this.localTracks.map((track) => !track.isMuted() && track.mute());
      const promiseBatch = await this.localTracks.map((track) => !track.disposed && track.dispose());

      await Promise.all(promiseBatch);
      await this.room.leave();
      this.meetingStatusSocket.close();
      if (!this.videoTrackLocal.isMuted()) await this.videoTrackLocal.mute();

      await this.connection.disconnect();
      // const promiseBatch = this.localTracks.map((track) => !track.disposed && track.dispose());
      // await Promise.all(promiseBatch).then(() => (this.localTracks = []));

      // if (this.room) await this.room.leave().then((this.room = null));
      //console.log(this.localTracks, promiseBatch, this.videoTrackLocal, this.desktopTrackLocal);
    };

    try {
      //console.log(this.connection, 'connection');
      await leaveRoom();
    } catch (error) {
      console.log('error', error);

      // console.log('error while disposing' + error);
    }
    this.remoteTracks = {};
    this.mainVideo = '';
    this.mainVideoId = '';
    this.screenshareIds = [];
    this.isDeviceChanged = false;
    this.isExapandVideo = false;
    this.connectionStats = null;
    this.isValid = true;
    this.sessionId = '';
    this.isModeratorAlert = true;
    this.recordingStatus = false;
    this.isModerator = false;
    this.isAudioMuted = false;
    this.isVideoMuted = false;
    this.isJoined = false;
    this.participantArray = [];
    this.participantListAudio = [];
    this.currentUser = {};
    this.participantsList = [];
    this.messages = [];
    this.message = '';
    this.guestUserDisplayName = '';
    this.presenterName = '';
    this.presenterList = [];
    this.unreadCount = 0;
    this.isScreenSharingOn = false;
    this.hasMoreThanOneParticipant = false;
    this.participantElement = [];
    this.isLocalStreaming = false;
    this.idTrackMap = new Map();
    this.smallScreensMap = new Map();
    this.idDisplayNameMap = new Map();
    this.connectionFailed = false;
    this.screenShareOnBigScreen = false;
    this.leaveMeetingWarning = false;
    this.showConnectionStatus = true;
    this.hideParticipantTiles = false;
    this.startAudioMuted = false;
    this.startVideoMuted = false;
    this.isPreLaunch = false;
    this.showConnectionFailedModal = false;
    this.connectionFailedCountdownSeconds = 15;
    this.isBigScreenHolderMuted = false;
    this.speakerOnScreen = null;
    this.isTileView = true;
    this.isNoteView = false;
    this.idAvatarColorMap = new Map();
    this.isInviteComponentVisible = false;
    this.isMutedNotify = false;
    this.moderatorName = '';
    this.muteButtonClick = false;
    this.cameraOptions = [];
    this.microphoneOptions = [];
    this.cameraDeviceId = [];
    this.micDeviceId = [];
    this.meetingModerator = [];
    this.uploadModal = false;
    this.documentSideBar = false;
    this.documentList = [];
    this.photos = [];
    this.documentLoading = false;
    this.documentMessage = '';
    this.wasAdded = false;
    this.docBadge = 0;
    this.connection = {};
    this.isVideo = true;
    this.thisconnection = null;
    this.isVideoAvailable = false;
    this.isAudioAvailable = false;
    this.myJitsiId = null;
    this.displayName = '';
    this.profilePicture = null;
    this.participantsDetails = [];
    this.pinnedParticipant = null;
    this.pinnedParticipantScreen = null;
    this.meeting = {};
    this.isCardToggled = false;
    this.isMeetingLoaded = false;
    this.localSmallScreen = null;
    this.selfParticipantId = null;
    this.connectionQualityMap = null;
    this.tabShareAudioTrack = null;
    this.desktopTrackLocal = null;
    this.isGuest = false;
    this.isBrowserWarning = false;
    this.disableJoinButton = false;
    this.recorder = null;
    this.streamCommandReceived = false;
    this.errorMessage = false;
    this.isGuestUserNameChange = false;
    this.connectionBandwidthQuality = null;
    this.lastDominantUser = 0;
    this.idAudioLevelMap = new Map();
    this.isInternalUser = false;
    this.audioMap = new Map();
    this.newAudioDevice = '';
    this.virtualBackgroundValue = 'none';
    this.hasPermissions = false;
    this.virtualBackgroundType = 'none';
    this.hasAudioPermissionIssue = false;
    this.hasVideoPermissionIssue = false;
    this.jitsiErrorMessage = '';
    this.hasVirtualBackground = false;
    this.localTracks = [];
    this.recordingNotification = false;
    this.recordingLimitReached = false;
    this.isUnauthorisedModal = false;
    this.redordingMessage = '';
    this.restrictedMuteMessage = '';
    this.isRecordingStartPrelaunch = false;
    this.isEntireScreenShared = false;
    this.isAddedAudioTrack = false;
    this.firstTimeSharing = true;
    this.videoRecorderSessionId = null;
    this.isAddParticipantModal = false;
    this.webinarSpeakers = [];
    this.newOption = [];
    this.searchResult = [];
    this.selectedUsers = [];
    this.newParticipantsList = [];
    this.emails = [];
    this.isLoadingRecording = false;
    this.outputDeviceId = '';
    this.allDevices = [];
    this.shouldFlipVideo = true;
    this.isBadBandwidth = false;
    this.participantFlipVideo = [];
  };

  flipOwnVideo = async (status) => {
    console.log('status', status);
    this.shouldFlipVideo = status;
    const videoTrack = (await this.room) && this.room.getLocalTracks().find((t) => t.getType() === 'video');
    const id = videoTrack && videoTrack.getParticipantId();
    console.log('id', id);
    const videoElement = id && document.getElementById(id);
    if (videoElement) {
      if (status) {
        videoElement.classList.add('video-flip', 'mirror-video');
      } else {
        videoElement.classList.remove('video-flip', 'mirror-video');
      }
    }
  };
  flipParticipantVideo = (participantId) => {
    const videoElement = participantId && document.getElementById(participantId);
    if (this.participantFlipVideo.includes(participantId)) {
      this.participantFlipVideo = this.participantFlipVideo.filter((id) => id !== participantId);
      if (videoElement) {
        videoElement.classList.add('video-flip', 'mirror-video');
      }
    } else {
      this.participantFlipVideo = [...this.participantFlipVideo, ...[participantId]];
      if (videoElement) {
        videoElement.classList.remove('video-flip', 'mirror-video');
      }
    }
  };

  startVideoRecording = async () => {
    this.isLoadingRecording = true;
    const accountID = this.isGuest ? sessionStorage.getItem('guestAccountId') : localStorage.getItem('accountId');
    try {
      Honeybadger.notify('startVideoRecording_START', {
        context: { where: 'start', meetingId: this.meeting.meetingId, accountId: accountID },
      });
      try {
        let userMessagesRef = firebaseconfig.database().ref('local/user-messages').child(this.meeting.meetingId).child('meetingFlags');
        await userMessagesRef.set({ isAudioRecordingStarted: true, isVideoRecordingStarted: true });
      } catch (error) {
        Honeybadger.notify(`Start Recording ${error}`, {
          context: {
            env: process.env.REACT_APP_ENVIRONMENT_NAME,
            user: this.currentUser,
            tags: `Recording - ${this.meeting.meetingId} - Error`,
            accountId: accountID,
            isVideoRecordingStarted: true,
          },
        });
      }
      this.room
        .startRecording({ mode: 'file', appData: null })
        .then((res) => {
          if (res) {
            console.log('res', res);
            // this.videoRecorderSessionId = res._sessionID;
          }
        })
        .catch((error) => {
          console.log('error', error);
          Honeybadger.notify('startVideoRecording_CATCH_ERROR', {
            context: { where: 'catch error', meetingId: this.meeting.meetingId, error: JSON.stringify(error), accountId: accountID },
          });
        });
    } catch (err) {
      toastError('Could not start video recording', 'VIDEO_RECORDING_FAIL');
      Honeybadger.notify('startVideoRecording_CATCH_ERR', {
        context: {
          where: 'Could not start video recording',
          meetingId: this.meeting.meetingId,
          error: JSON.stringify(err),
          accountId: accountID,
        },
      });
    }
  };
  stopVideoRecording = async () => {
    const accountID = this.isGuest ? sessionStorage.getItem('guestAccountId') : localStorage.getItem('accountId');
    console.log('videoRecorderSessionId', this.videoRecorderSessionId);
    this.isLoadingRecording = false;
    try {
      Honeybadger.notify('stopVideoRecording_STOP', {
        context: {
          where: 'stop',
          meetingId: this.meeting.meetingId,
          videoRecorderSessionId: this.videoRecorderSessionId,
          accountId: accountID,
        },
      });
      this.recordingAudio.sendLocalStreamStopPing();
      try {
        let userMessagesRef = firebaseconfig.database().ref('local/user-messages').child(this.meeting.meetingId).child('meetingFlags');
        await userMessagesRef.set({ isAudioRecordingStarted: false, isVideoRecordingStarted: false });
      } catch (error) {
        Honeybadger.notify(`Start Recording ${error}`, {
          context: {
            env: process.env.REACT_APP_ENVIRONMENT_NAME,
            user: this.currentUser,
            tags: `Recording - ${this.meeting.meetingId} - Error`,
            isVideoRecordingStarted: false,
            accountId: accountID,
          },
        });
      }
      this.room
        .stopRecording(this.videoRecorderSessionId)
        .then()
        .catch((error) => {
          console.log('error', error);
          Honeybadger.notify('stopVideoRecording_CATCH_ERROR', {
            context: {
              where: 'catch error',
              accountId: accountID,
              meetingId: this.meeting.meetingId,
              videoRecorderSessionId: this.videoRecorderSessionId,
              error: JSON.stringify(error),
            },
          });
        });
    } catch (err) {
      console.log('err stop', err);
      Honeybadger.notify('stopVideoRecording_CATCH_ERR', {
        context: {
          accountId: accountID,
          where: 'Could not stop video recording',
          meetingId: this.meeting.meetingId,
          videoRecorderSessionId: this.videoRecorderSessionId,
          error: JSON.stringify(err),
        },
      });
    }
  };
  // dynamicOption = () => {
  //   const updatedOptions = {
  //     ...options,
  //     serviceUrl: `https://${process.env.REACT_APP_JITSI_ENDPOINT}/http-bind`,
  //   };
  //   return updatedOptions;
  // };
  kickUser = async (id) => {
    try {
      await this.room.kickParticipant(id && id.toString());
    } catch (err) {
      console.log('err', err);
    }
  };

  handleNewInvitees = async (e) => {
    e.preventDefault();
    let users = [];
    let guestUser = [];
    let guestUserCounter = 0;

    this.selectedUsers.forEach((options, i) => {
      if (options.__isNew__ || options.result.guestUser) {
        guestUser[guestUserCounter++] = options.value;
      } else users[i - guestUserCounter] = options.result;
    });

    let body = {
      usersEmails: users,
      guestEmails: guestUser,
    };
    try {
      let updatedMeeting = await inviteUser(this.meeting.meetingId, body);
      console.log('updateMeeting :>> ', updatedMeeting);
      if (updatedMeeting) {
        this.isSearchingUsers = false;
        this.newOption = [];
        this.searchResult = [];
        this.selectedUsers = [];
        this.newParticipantsList = [];
        this.emails = [];
        this.isAddParticipantModal = false;
        toastSuccess('Invite sent successfully', 'SUCCESS_INVITE_SEND');
      }
    } catch (error) {
      console.log(error);
      this.isSearchingUsers = false;
      this.newOption = [];
      this.searchResult = [];
      this.selectedUsers = [];
      this.newParticipantsList = [];
      this.emails = [];
      toastError('Failed to send invite', 'FAIL_INVITE_SEND');
    }
  };

  /** Getting autocomplete results */
  getResult = async (keyword) => {
    this.waitingFor = keyword;
    if (keyword === '') {
      this.isSearchingUsers = false;
      return;
    }
    this.isSearchingUsers = true;
    Promise.all([getLoggedInUserObject().role === 'ROLE_ADMIN' ? searchGroups(keyword) : null, searchContacts(keyword)])
      .then((response) => {
        if (this.waitingFor === keyword) {
          const options = response
            .filter(Boolean)
            .map((results) => {
              return results.map((user) => {
                if (Boolean(user.groupId)) {
                  return {
                    label: (
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="searchUser">{user.groupName}</p>
                        <div className="groupIndicator">
                          <span>Group</span>
                        </div>
                      </div>
                    ),
                    value: user.groupName,
                    name: user.groupName,
                    groupId: user.groupId,
                  };
                } else {
                  return {
                    label: (
                      <p className="searchUser">
                        {user.firstName ? user.firstName : user.email}{' '}
                        <small>
                          {user.firstName ? user.email : ''}
                          {user.guestUser ? ' (Guest User)' : ''}
                        </small>
                      </p>
                    ),
                    value: user.email,
                    result: user,
                    name: Boolean(user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : user.email,
                  };
                }
              });
            })
            .flat();
          this.searchResult = options;
          this.isSearchingUsers = false;
        }
      })
      .catch((error) => {
        this.isSearchingUsers = false;
        const { data } = (error && error.response) || {};
        if (data) {
          toastError(data.error || data.message, 'SEARCH_PARTICIPANTS_FAILED');
        } else {
          toastError(error.message || 'Something went wrong', 'SEARCH_PARTICIPANTS_FAILED');
        }
      });
  };

  /** Displaying results in the search dropdown */
  handleInputChange = (input) => {
    this.emails = [];
    debounce(300, () => {
      this.getResult(input);
    })();
  };

  handleRemoveUser = (email) => {
    const newUserList =
      this.selectedUsers && this.selectedUsers.length > 0 ? this.selectedUsers.filter((user) => user.value !== email) : [];

    this.handleSelectUsersChange(newUserList);
  };

  handleSelectUsersChange = async (selectedOptions) => {
    const selectedGroup = selectedOptions.find((group) => group.groupId);

    if (Boolean(selectedGroup)) {
      // this.setState({ isSearchingUsers: true });
      this.isSearchingUsers = true;
      getGroupDetails(selectedGroup.groupId)
        .then((response) => {
          if (response && response.userGroups) {
            this.isSearchingUsers = false;
            const groupUsers = response.userGroups.map((userGroup) => {
              const userData = { ...userGroup.users, ...{ ...userGroup.contacts, guestUser: Boolean(userGroup.contacts) ? true : false } };
              return {
                label: (
                  <p className="searchUser">
                    {userData.firstName ? userData.firstName : userData.email}{' '}
                    <small>
                      {userData.firstName ? userData.email : ''}
                      {userData.guestUser ? ' (Guest User)' : ''}
                    </small>
                  </p>
                ),
                value: userData.email,
                result: userData,
                name: Boolean(userData.firstName || userData.lastName) ? `${userData.firstName} ${userData.lastName}` : userData.email,
              };
            });
            this.selectedUsers = [...this.newOption, ...groupUsers].filter(
              (thing, index, self) => index === self.findIndex((t) => t.value === thing.value && t.value === thing.value)
            );
            this.newOption = [...this.newOption, ...groupUsers].filter(
              (thing, index, self) => index === self.findIndex((t) => t.value === thing.value && t.value === thing.value)
            );
          }
        })
        .catch((error) => {
          this.isSearchingUsers = false;
          const { data } = (error && error.response) || {};
          if (data) {
            toastError(data.error || data.message, 'GROUP_PARTICIPANTS_FAILED');
          } else {
            toastError(error.message || 'Something went wrong', 'GROUP_PARTICIPANTS_FAILED');
          }
        });
    } else {
      this.selectedUsers = selectedOptions;
      this.newOption = selectedOptions;
    }
    this.emails = [];
  };

  handleCreateParticipants = async (inputValue) => {
    console.log(inputValue);
    if (!isEmailValid(inputValue)) {
      toastError('Invalid email', 'HANDLE_CREATE_PARTICIPANT');
      return;
    }
    let requestBody = {
      email: inputValue,
      firstName: '',
      lastName: '',
    };
    const createContactRes = await createContact(requestBody);
    if (createContactRes && createContactRes.data && createContactRes.data.active) {
      const newOption = {
        label: inputValue,
        value: inputValue,
        __isNew__: true,
        name: inputValue,
      };
      this.newParticipantsList.push(newOption.value);
      toastSuccess('Participant Added Successfully', 'ADDED_PARTICIPANT');
    } else {
      toastError('Something went wrong, Please try again to add participant', 'FAILED_CREATE_PARTICIPANT');
    }
  };

  handleAddParticipantModal = () => {
    this.isAddParticipantModal = !this.isAddParticipantModal;
    if (!this.isAddParticipantModal) {
      this.isSearchingUsers = false;
      this.newOption = [];
      this.searchResult = [];
      this.selectedUsers = [];
      this.newParticipantsList = [];
      this.emails = [];
    }
  };

  getDeviceList = async () => {
    try {
      const safariPermission = await navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((devices) => {
          return devices;
        })
        .catch(() => false);
      // let devices = await navigator.mediaDevices.enumerateDevices();
      // console.log(devices);
      const videoPermission = (await JitsiMeetJS.mediaDevices.isDevicePermissionGranted('video')) || safariPermission;
      console.log('videoPermission', videoPermission);
      return ['audio', videoPermission && this.cameraOptions && this.cameraOptions.length !== 0 && 'video'].filter(Boolean);
    } catch (error) {
      console.log('error camera :>> ', error);
    }
  };

  init = (recordingAudio) => {
    try {
      JitsiMeetJS.init(window.config);
      this.checkBrowserWarning();
      this.connection = new JitsiMeetJS.JitsiConnection(null, null, { ...(window.config || {}) });
      console.log('this.connection', this.connection);
      // if you want to join call from localhost we need to uncomment below line and comment above one.
      // this.connection = new JitsiMeetJS.JitsiConnection(null, null, window.location.host.includes('localhost') ? options : window.config);
      JitsiMeetJS.mediaDevices.enumerateDevices(this.getMediaDevices);

      this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess);

      this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);
      this.connection.addEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect);
      this.connection.addEventListener(JitsiMeetJS.events.conference.NOISY_MIC, this.handleNoisymic);
      this.connection.addEventListener(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, this.handleNoisymic);

      JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, (devices) => {
        console.log('devices event', devices);
        if (devices && this.isJoined) {
          this.onDeviceListChanged(devices);
        }
      });
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
      this.recordingAudio = recordingAudio;
      this.isVideoMuted = this.meeting.isQuickCallMeeting ? true : false;
    } catch (error) {
      console.log('error', error);
    }
  };
  checkBrowserWarning = () => {
    try {
      if (
        JitsiMeetJS &&
        JitsiMeetJS.util &&
        JitsiMeetJS.util.browser &&
        JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari')
      ) {
        this.isBrowserWarning = true;
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   *
   * @param selected
   */
  changeAudioOutput = async (selected) => {
    // eslint-disable-line no-unused-vars

    if (selected) {
      await JitsiMeetJS.mediaDevices.setAudioOutputDevice(selected);
    }
  };

  resetDevices = (devices) => {
    this.microphoneOptions = [];
    this.cameraOptions = [];
    this.listMediaDevices(devices);
  };

  onDeviceListChanged = async (devices) => {
    console.log('************** CHANGE IN DEVICE **************');
    console.log('devices :>> ', devices);
    let onlyNewDevices;
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    if (
      isiOS ||
      (JitsiMeetJS && JitsiMeetJS.util && JitsiMeetJS.util.browser && JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
    ) {
      console.log('goes inside ios');
      onlyNewDevices = devices.filter(
        (nDevice) =>
          nDevice.kind === 'audioinput' &&
          !this.microphoneOptions.find((device) => device.kind === 'audioinput' && device.deviceId === nDevice.deviceId)
      );
    } else {
      console.log('it is not IOS');
      onlyNewDevices = devices.filter(
        (nDevice) =>
          nDevice.kind === 'audiooutput' &&
          !this.microphoneOptions.find((device) => device.kind === 'audiooutput' && device.deviceId === nDevice.deviceId)
      );
    }
    console.log(
      'this.microphoneOptions',
      this.microphoneOptions.find((device) => device.kind === 'audioutput')
    );
    // if (!this.microphoneOptions) {
    // IMPORTANT - Need to verify if the isAudioRecordingStarted state is visible
    this.recordingAudio.isAudioRecordingStarted = false;
    console.log('Setting isAudioRecordingStarted - False ', this.recordingAudio.isAudioRecordingStarted);
    console.log('Stopping Local Streaming');

    try {
      await this.recordingAudio.stopLocalStreaming(); // it is getting stuck here ..
    } catch (e) {
      console.log('CATCH - STOP LOCAL STREAMING ', e);
    }

    // }
    console.log('onlyNewDevices', onlyNewDevices);
    this.newAudioDevice = onlyNewDevices && onlyNewDevices.length > 0 && onlyNewDevices[0].label;

    setTimeout(() => {
      this.newAudioDevice = '';
    }, 6000);

    // WATCH OUT FOR THE NEXT STEP - first media > handle disposed tracks > onLocalTracks
    !this.isAudioMuted && this.handleMediaDevices(this.microphoneOptions.filter((aud) => aud.kind === 'audioinput'));
    this.resetDevices(devices);
  };

  onAudioChanged = async (device) => {
    console.log('called onaudio change');
    // console.log(`track audio output device was changed to ${device}`);
    if (this.streamCommandReceived && this.isLocalStreaming && this.isDeviceChanged && !this.isPreLaunch) {
      this.isLocalStreaming = false;
      console.log('=== Is Audio Recording Started - False === inside onAudioChanged');
      this.isAudioRecordingStarted = false;
      console.log('goes inside if 1063');
      console.log('startLocalStreaming onAudioChanged');
      await this.recordingAudio.startLocalStreaming();
      setTimeout(() => {
        this.isDeviceChanged = false;
      }, 2000);
    }
  };

  listMediaDevices = (devices) => {
    this.allDevices = devices;
    devices.forEach((device, idx) => {
      if (device.kind === 'videoinput') {
        let cameraOptions = [...this.cameraOptions];
        cameraOptions = cameraOptions.filter((cam) => cam.value !== '');
        cameraOptions.push({
          label: device.label,
          value: device.deviceId,
          key: idx,
          kind: 'videoinput',
        });
        this.cameraOptions = cameraOptions;
        this.cameraDeviceId = cameraOptions[0];
      }
      if (device.kind === 'audioinput') {
        let microphoneOptions = [...this.microphoneOptions];
        microphoneOptions = microphoneOptions.filter((mic) => mic.value !== '');
        microphoneOptions.push({
          label: device.label,
          value: device.deviceId,
          key: idx,
          kind: device.kind,
        });
        this.microphoneOptions = microphoneOptions;
        this.micDeviceId = microphoneOptions[0];
      }
    });
  };

  getMediaDevices = (devices) => {
    console.log('devices', devices);
    this.listMediaDevices(devices);
    this.handleDisposeTracks();
  };

  createJitsiLocalTrackForScreenShare = async (type) => {
    try {
      const tracks = await JitsiMeetJS.createLocalTracks({
        devices: type,
      });
      tracks[0].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, async () => {
        if (this.room) {
          try {
            if (document.pictureInPictureElement) {
              document.exitPictureInPicture();
            }
          } catch (e) {
            console.log('e', e);
          }
          this.isScreenSharingOn = false;
          if (this.desktopTrackLocal) {
            this.isEntireScreenShared = false;
            await this.desktopTrackLocal.mute();
          }
        }
      });
      return tracks;
    } catch (error) {
      const { name } = error || {};
      if (name && name.includes('permission')) {
        toastError(
          'To enable screen-share, go to System Settings -> Privacy & Security -> Screen Recording, Add your browser. Please allow for your browser (Google Chrome, MS Edge or Firefox) to record your screen.',
          'PERMISSION_SCREEN_SHARE',
          15000
        );
      }

      if (type === TRACK_TYPE.VIDEO) {
        this.isVideoMuted = true;
        this.isBigScreenHolderMuted = true;
      }
    }
  };

  stopScrenShare = async () => {
    if (this.room) {
      this.isScreenSharingOn = false;
      try {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture();
        }
      } catch (e) {
        console.log('e', e);
      }
      console.log('this.desktopTrackLocal :>> ', this.desktopTrackLocal);
      if (this.desktopTrackLocal) {
        this.isEntireScreenShared = false;
        await this.desktopTrackLocal.mute();
        this.tabShareAudioTrack && (await this.tabShareAudioTrack.mute());
        this.tabShareAudioTrack && (await this.tabShareAudioTrack.track.stop());
        this.tabShareAudioTrack && (await this.tabShareAudioTrack.dispose());
      }
    }
  };

  handleMediaDevices = async (selectedOptions) => {
    console.log('first media');
    console.log('Selected Option - ', selectedOptions);

    if (selectedOptions.length >= 1) selectedOptions = selectedOptions[0];
    const { kind } = selectedOptions;

    if (kind === 'audioinput') {
      console.log('KIND - AUDIO INPUT');
      this.micDeviceId = selectedOptions;
      this.isDeviceChanged = true;
      setTimeout(async () => {
        await this.handleDisposeTracks('audioinput');
      }, 100);
    } else if (kind === 'videoinput') {
      console.log('Old', this.cameraDeviceId, 'New', selectedOptions);
      this.cameraDeviceId = selectedOptions;
      setTimeout(async () => {
        await this.handleDisposeTracks('videoinput');
      }, 100);
      console.log('After dispose track->', this.cameraDeviceId, this.videoTrackLocal);
    }
  };

  handleDisposeTracks = async (disposeType) => {
    console.log('dispose', disposeType);
    if (disposeType === 'videoinput') {
      if (this.videoTrackLocal) {
        // await this.videoTrackLocal.dispose();
        (await this.meeting) &&
          !this.meeting.hasEnded &&
          JitsiMeetJS.createLocalTracks({
            devices: ['video', 'audio'],
            cameraDeviceId: this.cameraDeviceId.value,
            micDeviceId: this.micDeviceId.value,
          })
            .then(async (tracks) => {
              const newTrack = tracks.find((track) => track.type === 'video');
              if (this.isJoined) {
                var userAgent = navigator.userAgent || navigator.vendor || window.opera;
                const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
                if (
                  isiOS ||
                  (JitsiMeetJS &&
                    JitsiMeetJS.util &&
                    JitsiMeetJS.util.browser &&
                    JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
                ) {
                  // await this.room.replaceTrack(this.videoTrackLocal, newTrack);
                  await this.videoTrackLocal.dispose();
                  await this.room.addTrack(newTrack);
                } else {
                  await this.room.replaceTrack(this.videoTrackLocal, newTrack);
                }
                await this.videoTrackLocal.dispose();
                this.videoTrackLocal = newTrack;
                newTrack.attach(document.getElementById(`${this.myJitsiId}`));
              } else {
                await this.videoTrackLocal.dispose();
                this.videoTrackLocal = newTrack;
                newTrack.attach(document.getElementById('prelaunch-localvideo'));
              }
              this.localTracks = tracks;
              newTrack.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onAudioChanged);
              newTrack.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, this.localVideoTrackMuteChangedEventHandler);
            })
            .catch((error) => {
              console.log(error, 'dispose');
            });
      }
    } else if (disposeType === 'audioinput') {
      console.log('***** MIC DEVICE ID ***** ', this.micDeviceId.value);
      if (this.audioTrackLocal) {
        await this.audioTrackLocal.dispose();
        (await this.meeting) &&
          !this.meeting.hasEnded &&
          JitsiMeetJS.createLocalTracks({ devices: ['audio'], micDeviceId: this.micDeviceId.value })
            .then(this.onLocalTracks) // ON LOCAL TRACKS >>>>>>>>>>>>>>>>>>>>>>>>
            .catch((error) => {
              console.log(error, 'dispose');
            });
      }
    } else {
      // if (this.videoTrackLocal) await this.videoTrackLocal.dispose();
      // if (this.audioTrackLocal) await this.audioTrackLocal.dispose();
      // if (this.desktopTrackLocal) await this.desktopTrackLocal.dispose();
      // try {
      //   this.localTracks.map(async (track) => {
      //     await track.dispose();
      //   });
      // } catch (error) {
      //   console.log('error while disposing' + error);
      // }
      this.meeting && !this.meeting.hasEnded && (await this.createLocalTracks());
    }
  };

  isQuickCall = async () => {
    if (this.meeting.isQuickCallMeeting) {
      if (this.meeting.isQuickCallMeeting) {
        this.isVideoMuted = true;
        await setTimeout(() => this.connectMeeting(), 1000);
        return true;
      }
    } else {
      return false;
    }
  };

  connectMeeting = async () => {
    try {
      this.isPreLaunch = false;
      console.log('this.videoTrackLocal :>> ', this.videoTrackLocal);
      console.log('this.hasVideoPermissionIssue :>> ', this.hasVideoPermissionIssue);
      console.log('this.cameraOptions :>> ', this.cameraOptions);
      if (
        this.hasVideoPermissionIssue ||
        (this.videoTrackLocal && !this.videoTrackLocal.track) ||
        (this.cameraOptions && this.cameraOptions.length === 0)
      ) {
        if (this.hasVideoPermissionIssue) {
          if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            await navigator.mediaDevices.getUserMedia({ video: true });
          } else {
            toastError(
              'It seems like there no camera attached to your device. Please ensure that your camera is attached and is functional.',
              'CAMERA_ERROR'
            );
          }
        }
        if (this.videoTrackLocal && !this.videoTrackLocal.track) {
          this.videoTrackLocal = await this.createJitsiLocalTrack(TRACK_TYPE.VIDEO);
        }
        toastWarning("Looks like we are having trouble accessing your camera, you can still join the meeting but others won't see you");
      }
      if (this.isGuestUserNameChange) {
        let requestBody = {
          email: this.currentUser.email,
          firstName: this.guestUserDisplayName,
          lastName: '',
        };
        updateContact(requestBody);
        analytics.logEvent('update_contact', { userId: localStorage.getItem('uid'), contact: JSON.stringify(requestBody) });
      }
      // this.videoTrackLocal && (await this.attachTrack(this.videoTrackLocal, this.selfParticipantId));
      this.meeting && !this.meeting.hasEnded && this.connection.connect();
      if (getLoggedInUserObject().email === this.meeting.createdBy) {
        analytics.logEvent('join_meeting', {
          userId: localStorage.getItem('uid'),
          meeting: this.meeting.meetingId,
          email: this.currentUser.email,
        });
      }
    } catch (err) {
      console.log(err, 'connectMeeting');
    }
  };
  /**
   * This function is called when we disconnect.
   */
  disconnect = () => {
    console.log('disconnect!');
    if (this.connection) {
      this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED, this.onConnectionSuccess);
      this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_FAILED, this.onConnectionFailed);
      this.connection.removeEventListener(JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED, this.disconnect);
    }
  };

  getGuestUserName = async () => {
    const guestUserDetails = JSON.parse(sessionStorage.getItem('guestUser'));
    const guestUserNameRes = await getGuestUserName(guestUserDetails.email);
    if (guestUserNameRes.length > 0) {
      this.guestUserDisplayName = guestUserNameRes[0].firstName + guestUserNameRes[0].lastName;
    }
  };

  getMeeting = async (meetingId, accountId) => {
    try {
      const accountID = this.isGuest ? sessionStorage.getItem('guestAccountId') : localStorage.getItem('accountId');
      if (accountID !== accountId) {
        let loginResponse = await getUserAccount(accountId, getLoggedInUserObject().userUid);

        //Replacing logged in user info
        localStorage.setItem('memoriaUser', JSON.stringify(loginResponse.data.users));
        localStorage.setItem('authorization', loginResponse.headers.authorization);
        localStorage.setItem('accountId', loginResponse.data.users.accountId);
        localStorage.setItem('accountName', loginResponse.data.accountName);
        localStorage.setItem('x-authorization', loginResponse.headers['x-authorization']);
      }
      this.meeting = await getMeetingById(meetingId);
      if (!this.meeting.isDeleted) {
        this.isMeetingLoaded = true;
      } else {
        this.meeting.hasEnded = true;
      }
    } catch (error) {
      /** TODO handle errors by status codes and appropriate error messages/actions */
      //this.props.history.push("/404");
      this.meeting.isInvalid = true;
      this.isUnauthorisedModal = true;
      console.log(error, 'getmeeting');
    }
  };

  onTrackRemoved = async (track) => {
    setTimeout(async () => {
      let map = this.idTrackMap;
      let id = track.getParticipantId();
      if (track.videoType === 'desktop') {
        let newSmallScreensMap = this.smallScreensMap;
        let newIdTrackMap = this.idTrackMap;
        newIdTrackMap.delete(id + 'screenshare');
        newSmallScreensMap.delete(id + 'screenshare');
        this.smallScreensMap = newSmallScreensMap;
        this.idTrackMap = newIdTrackMap;

        if (this.pinnedParticipant === id + 'screenshare') {
          this.pinnedParticipant = null;
          try {
            const localUserTrack = await this.room.getLocalTracks().find((t) => t.getType() === TRACK_TYPE.VIDEO);
            if (localUserTrack && !this.isVideoMuted) {
              this.mainVideoId = this.myJitsiId;
              await localUserTrack.attach(document.getElementById('localVideo1'));
            } else {
              let imageElement = this.getProfilePicture(this.currentUser.email) ? (
                <div className="thumbOuter">
                  <i>
                    <img
                      src={this.getProfilePicture(this.currentUser.email)}
                      alt="Smiley face"
                      className="small-screen"
                      id={this.myJitsiId}
                    />
                  </i>
                  <span className="displayname">{this.displayName}</span>
                </div>
              ) : (
                <div id={this.myJitsiId} className="img">
                  <Avatar className="small-screen" round={true} size={100} color={`#${'7b79b3'}`} name={this.displayName} />
                  <span className="displayname">{this.displayName}</span>
                </div>
              );
              this.mainVideo = imageElement;
            }
            this.pinnedParticipant = null;
          } catch (err) {
            console.log('errr', err);
          }
        }
      } else {
        if (track && track.getType() === TRACK_TYPE.VIDEO && (track.videoType === 'camera' || track.videoType === TRACK_TYPE.DESKTOP)) {
          this.isBigScreenHolderMuted = true;
          if (track.videoType === TRACK_TYPE.DESKTOP) {
            this.isScreenSharingOn = false;
            this.screenShareOnBigScreen = false;
            this.presenterName = '';
            track.detach(document.getElementById('localVideo1'));
            if (this.pinnedParticipant === track.ownerEndpointId) this.pinnedParticipant = null;
            if (this.pinnedParticipantScreen === track.ownerEndpointId) this.pinnedParticipantScreen = null;
          }
          //this.removeLocalTrack(track.getType());
        }
        if (map && track.getType() === TRACK_TYPE.VIDEO) {
          map.delete(id);
          this.idTrackMap = map;
          if (this.speakerOnScreen === id || track.videoType === TRACK_TYPE.DESKTOP) {
            if (!this.isTileView) this.hideParticipantTiles = false;
            const trackIndex = this.presenterList.findIndex((list) => list === track.ownerEndpointId);
            if (trackIndex > -1) {
              this.presenterList.splice(trackIndex, 1);
            }

            this.pinnedParticipant = null;
            this.hasOtherParticipantSharedScreen = false;
            let map = this.smallScreensMap;
            let imageElement = this.getProfilePicture(this.idDisplayNameMap.get(id)) ? (
              <div className="thumbOuter">
                <i>
                  <img
                    src={this.getProfilePicture(this.idDisplayNameMap.get(id))}
                    alt="Smiley face"
                    className="small-screen"
                    id={track.ownerEndpointId}
                  />
                </i>
                <span className="displayname">{shortenString(this.idDisplayNameMap.get(id), 12)}</span>
              </div>
            ) : (
              <div className="img" id={id}>
                <Avatar
                  className="small-screen"
                  round={true}
                  size={100}
                  color={`#${this.idAvatarColorMap.get(id)}`}
                  name={this.idDisplayNameMap.get(id)}
                />
                <span className="displayname">{shortenString(this.idDisplayNameMap.get(id), 12)}</span>
              </div>
            );
            map.set(id, imageElement);
            if (id) {
              this.smallScreensMap = map;
            }
            await this.onDominantSpeakerChange(id);
          }
        }
        map = this.idAudioTrackMap;
        if (map && track.getType() === 'audio') {
          map.delete(id);
          this.idAudioTrackMap = map;
        }
      }
    }, 2000);
  };

  //Take a track

  /** Fired when user role is changed */
  onUserRoleChanged = () => {
    // if (this.room.isModerator()) {
    if (this.currentUser && this.currentUser.email) {
      if (this.currentUser.email === this.meetingModerator.createdBy || this.currentUser.email === this.meeting.createdBy) {
        this.isModerator = true;
      }
    }
  };
  toggleBigScreenAvatar = (track) => {
    console.log('toggleBigScreenAvatar');
    if (track) {
      if (this.speakerOnScreen === this.myJitsiId) {
        this.IsBigScreenHolderMuted = this.isVideoMuted;
        setTimeout(() => {
          this.isVideoMuted && this.conditionalSwitchBigScreen(this.videoTrackLocal, this.myJitsiId);
        }, 100);
      } else if (this.speakerOnScreen === track.ownerEndpointId) {
        this.isBigScreenHolderMuted = track.track.muted;
        setTimeout(() => {
          !track.track.muted && this.conditionalSwitchBigScreen(track, track.ownerEndpointId);
        }, 100);
      }
    }
  };
  setTrackMap = (track, id) => {
    let map = this.idTrackMap ? this.idTrackMap : new Map();
    map.set(id, track);
    this.idTrackMap = map;
  };
  attachScreenShareforRemote = async (track) => {
    try {
      if (track) {
        const participant = track.getParticipantId();
        const id = participant + track.getType();
        console.log(`Attaching Desktop Track---->${track.videoType}`);
        document.getElementById('localVideo1') &&
          (await this.detachTrack('localVideo1').then(await track.attach(document.getElementById('localVideo1'))));
        this.mainVideoId = track.ownerEndpointId;
        this.mainVideo = 'video screenshare';
        await this.setTrackMap(track, track.ownerEndpointId + 'screenshare');

        this.mainVideo =
          track.ownerEndpointId && [...this.smallScreensMap].find((keyval) => keyval[0] === track.ownerEndpointId)
            ? [...this.smallScreensMap].find((keyval) => keyval[0] === track.ownerEndpointId)[1]
            : this.localSmallScreen;
        this.pinnedParticipantScreen = track.ownerEndpointId + 'screenshare';
        this.mainVideoId = track.ownerEndpointId;
        console.log('Pinned Screen share------->', this.pinnedParticipantScreen);
      }
    } catch (error) {
      console.log('attachScreenShareforRemote---->', error);
    }
  };

  attachRemotettrack = async (track) => {
    setTimeout(async () => {
      const participant = track.getParticipantId();
      const id = participant;
      if (track.videoType !== TRACK_TYPE.DESKTOP) {
        if (track.muted) {
          let map = this.smallScreensMap;
          let imageElement = this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId)) ? (
            <div className="thumbOuter">
              <i>
                <img
                  src={this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId))}
                  alt="Smiley face"
                  className="small-screen"
                  id={track.ownerEndpointId}
                />
              </i>
              <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
            </div>
          ) : (
            <div className="img" id={track.ownerEndpointId}>
              <Avatar
                className="small-screen"
                round={true}
                size={100}
                color={`#${this.idAvatarColorMap.get(track.ownerEndpointId)}`}
                name={this.idDisplayNameMap.get(track.ownerEndpointId)}
              />
              <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
            </div>
          );
          map.set(track.ownerEndpointId, imageElement);
          if (track.ownerEndpointId) {
            this.smallScreensMap = map;
          }
        } else {
          let newVideo = `<div class="videoOuter"><video class= 'remote-video-styles ${
            this.participantFlipVideo.includes(id) ? '' : 'video-flip'
          } small-screen' poster=${cameraOffPoster} autoplay id='${id}' /></div><span class="displayname">${this.getDisplayName(
            id
          )}</span>`;
          let smallScreensMap = this.smallScreensMap;
          smallScreensMap.set(id, newVideo);
          this.smallScreensMap = smallScreensMap;
          await this.attachTrack(track, id);
        }
        // setTimeout(async () => {
        let updatedMap = this.idTrackMap ? this.idTrackMap : new Map();
        updatedMap.set(id, track);
        this.idTrackMap = updatedMap;
        // }, 2000);
      } else {
        if (!track.muted) {
          if (this.isTileView) {
            await this.handleTileView();
          }
          let newVideo = `<div class="videoOuter"><video class= 'remote-video-styles small-screen' poster=${cameraOffPoster} autoplay id='${
            id + 'screenshare'
          }' /></div><span class="displayname">${this.getDisplayName(id)}'s Screen</span>`;
          let smallScreensMap = this.smallScreensMap;
          smallScreensMap.set(id + 'screenshare', newVideo);
          this.smallScreensMap = smallScreensMap;
          let map = this.idTrackMap ? this.idTrackMap : new Map();
          map.set(id + 'screenshare', track);
          this.idTrackMap = map;
          await this.attachTrack(track, id + 'screenshare');
          this.pinParticipant(id + 'screenshare');
        }
      }
    }, 2000);
  };
  attachLocaltrackOnRemote = async (track) => {
    try {
      const participant = track.getParticipantId();
      if (!this.remoteTracks[participant]) {
        this.remoteTracks[participant] = [];
      }

      this.remoteTracks[participant].push(track);
      const id = participant;
      // const id = participant + track.getType();
      this.selfParticipantId = participant;

      if (track.getType() === TRACK_TYPE.VIDEO && !this.isVideoMuted) {
        const newVideo = `<div class="videoOuter"><video class='remote-video-styles ${
          this.shouldFlipVideo ? 'video-flip  mirror-video' : ''
        } small-screen' poster=${cameraOffPoster}  autoPlay id='${id}' /></div><span class="displayname">${this.displayName}</span>`;
        this.localSmallScreen = newVideo;

        let existingVideoStream = await this.room.getLocalTracks().find((t) => t.getType() === 'video');

        if (existingVideoStream) {
          await this.attachTrack(existingVideoStream, id);
          this.mainVideo = this.localSmallScreen;
          existingVideoStream.attach(document.getElementById('localVideo1'));
        }
      }
      return;
    } catch (error) {
      console.log(`attachLocaltrackOnRemote ---->`, error);
    }
  };
  attachRemoteAudioTrack = async (track) => {
    try {
      console.log('Adding Remote Audio--->');
      const participant = track.getParticipantId();
      const id = participant + track.getType();
      if (track.ownerEndpointId) {
        this.audioMap.set(track.ownerEndpointId, track.muted);
        track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, async (_track) => {
          if (_track.getType() === 'audio' && _track.ownerEndpointId) {
            this.audioMap.set(_track.ownerEndpointId, _track.muted);
          }
        });

        let map = this.idAudioTrackMap ? this.idAudioTrackMap : new Map();
        map.set(participant, track);
        this.idAudioTrackMap = map;
        const newAudio = `<audio autoplay='1' id='${id}' />`;
        this.participantListAudio = [...this.participantListAudio, newAudio];
        setTimeout(async () => {
          await track.attach(document.getElementById(id));
        }, 100);
      }
    } catch (error) {
      console.log(`attachRemoteAudioTrack ---->`, error);
    }
  };
  attachRemoteVideoandScreen = async (track) => {
    try {
      console.log('Adding Remote Video/Screen--->', track, track.getParticipantId(), track.getType(), track.videoType, track.muted);

      const participant = track.getParticipantId();
      // this.videoTrackLocal && (await this.detachTrack(this.videoTrackLocal, 'localVideo1'));
      if (track.videoType === TRACK_TYPE.DESKTOP || track.videoType === 'camera') {
        await this.attachRemotettrack(track);

        track.addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log('remote track stoped'));
        // todo: remove  TRACK_VIDEOTYPE_CHANGED
        // track.addEventListener(JitsiMeetJS.events.track.TRACK_VIDEOTYPE_CHANGED, (type) => {
        //   this.setUnsetPresenterName(type, track);
        // });
        this.isBigScreenHolderMuted = false;
        track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, (track) => {
          this.remoteVideoTrackMuteChangedEventHandler(track);
        });

        // setTimeout(async () => {
        // let map = this.idTrackMap ? this.idTrackMap : new Map();
        // map.set(participant + `${track.videoType === 'desktop' ? 'screenshare' : ''}`, track);
        // this.idTrackMap = map;
        // }, 2000);
      } else {
        if (!this.pinnedParticipant) this.isBigScreenHolderMuted = true;
      }
    } catch (error) {
      console.log(`attachRemoteVideoandScreen ---->`, error);
    }
  };
  /**
   * Handles remote tracks
   * @param track JitsiTrack object
   */
  onRemoteTrack = async (track) => {
    try {
      console.log('Remote changes -onRemoteTrack', track, this.pinnedParticipant);

      if (track) {
        if (track.isLocal()) {
          await this.attachLocaltrackOnRemote(track);
          return;
        }
        switch (track.getType()) {
          case TRACK_TYPE.AUDIO:
            await this.attachRemoteAudioTrack(track);
            break;
          case TRACK_TYPE.VIDEO:
            await this.attachRemoteVideoandScreen(track);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.log('onRemoteTrack----->', error);
    }
  };
  setUnsetPresenterName(type, track) {
    try {
      if (type === TRACK_TYPE.DESKTOP) {
        const participantName = this.participantsList && this.participantsList.find((user) => user._id === track.ownerEndpointId);
        this.presenterList.push(participantName && participantName._id);
        this.presenterName = participantName && getParticipantDisplayName(participantName._displayName, 0);
        this.screenShareOnBigScreen = true;
        // this.isScreenSharingOn = true;
        this.isBigScreenHolderMuted = false;
        this.attachScreenShareforRemote(track);
        // this.pinParticipant(track.ownerEndpointId);
      } else {
        this.presenterName = '';
        this.screenShareOnBigScreen = false;
        //this.isScreenSharingOn = false;
        this.isBigScreenHolderMuted = true;
        this.pinnedParticipantScreen = undefined;
        this.onDominantSpeakerChange(track.ownerEndpointId);
      }
    } catch (error) {
      console.log('setUnsetPresenterName----->', error);
    }
  }
  hasDesktopTrackinRemote = () => {
    let hasDesktopTrack = undefined;
    for (const [key, value] of this.idTrackMap.entries()) {
      if (value && value.videoType === TRACK_TYPE.DESKTOP) {
        hasDesktopTrack = key;
        break;
      }
    }
    return hasDesktopTrack;
  };
  pinParticipant = async (id) => {
    try {
      let desktopTrackinRemote = await this.hasDesktopTrackinRemote();
      console.log(`For ${id} pinParticipant ScreenSharingOn-${this.isScreenSharingOn} desktopTrackinRemote - ${desktopTrackinRemote} `);
      this.pinnedParticipantScreen = null;

      if (id !== this.selfParticipantId) {
        let track = this.idTrackMap.get(id);
        console.log(track, '-From idTrack');
        console.log('id PIN  :>> ', id);
        if (track && track.getType() === TRACK_TYPE.VIDEO) {
          try {
            if (track.videoType === 'camera' && track.muted) {
              return;
            }
            console.log(track, '-Track found from IdTrack - attching now');
            this.mainVideo = `video ${id.includes('screenshare') ? 'screenshare' : ''}`;
            await track.attach(document.getElementById('localVideo1'));
            // this.room.selectParticipant(id);
            this.mainVideo = 'video';
            this.onDominantSpeakerChange(id);
            this.pinnedParticipant = id;
            this.mainVideoId = id;
          } catch (error) {
            console.log('error', error);
          }
        }
      }
    } catch (error) {
      console.log('pinParticipant---->', error.message);
    }
  };

  getLocalTrack = (type: string) => {
    if (this.room) {
      try {
        for (let track of this.room.getLocalTracks()) {
          let videoType = type === TRACK_TYPE.VIDEO ? (track.videoType = 'camera') : TRACK_TYPE.DESKTOP;
          if (track.getType() === type && track.videoType === videoType) {
            return { track };
          }
        }
      } catch (error) {
        console.log('getLocalTrack--->', error);
      }
    }
  };
  addorReplaceVideoTrack = async (track) => {
    if (this.room) {
      try {
        let existingVideoStream = this.room.getLocalTracks().find((t) => t && t.getType() === TRACK_TYPE.VIDEO);
        console.log(existingVideoStream, 'addorReplaceVideoTrack');
        if (existingVideoStream && track && track.getType() === TRACK_TYPE.VIDEO) {
          if (existingVideoStream.videoType === 'camera') existingVideoStream.mute();
          console.log('replaceTrack 1310 :>> ');

          var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
          if (
            isiOS ||
            (JitsiMeetJS &&
              JitsiMeetJS.util &&
              JitsiMeetJS.util.browser &&
              JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
          ) {
            // await this.room.replaceTrack(existingVideoStream, track);
            await existingVideoStream.dispose();
            await this.room.addTrack(track);
          } else {
            await this.room.replaceTrack(existingVideoStream, track);
          }
          await track.attach(document.getElementById('localVideo1'));
          this.mainVideo = 'video';
          this.mainVideoId = track.ownerEndpointId;
          switch (existingVideoStream.videoType) {
            case TRACK_TYPE.DESKTOP:
              this.desktopTrackLocal.dispose();
              break;
            default:
              this.videoTrackLocal.dispose();
          }
        } else {
          await this.room.addTrack(track);
          console.log(track, 'No Existing track found, adding new track');
        }
      } catch (error) {
        console.log('addorReplaceVideoTrack--->', error);
      }
    }
  };
  muteUnmuteVideoTrack = async () => {
    if (this.room) {
      try {
        console.log('muteUnmuteVideoTrack');
        let track = await this.room.getLocalTracks().find((t) => t.getType() === TRACK_TYPE.VIDEO);
        if (this.isVideoMuted) {
          (await this.videoTrackLocal) && this.videoTrackLocal.mute();
        } else {
          await track.unmute();
          (await this.videoTrackLocal) && this.videoTrackLocal.unmute();
          this.videoTrackLocal.setEffect(
            await createJitsiStreamBackgroundEffect(
              window.localStorage.getItem('virtualImageType'),
              window.localStorage.getItem('virtualImage')
            )
          );
          this.flipOwnVideo(this.shouldFlipVideo);
        }
      } catch (error) {
        console.log('muteUnmuteVideoTrack--->', error);
        this.isVideoMuted = true;
      }
    }
  };

  removeLocalTrack = async (type: string) => {
    if (this.room) {
      try {
        for (let track of this.room.getLocalTracks()) {
          console.log('removeLocalTrack');
          let videoType = type === TRACK_TYPE.VIDEO ? 'camera' : TRACK_TYPE.DESKTOP;
          if (track.getType() === TRACK_TYPE.VIDEO && track.videoType === videoType) {
            !track.isMuted && (await track.mute());
            await track.dispose();
            await this.room.removeTrack(track);
            if (videoType === TRACK_TYPE.VIDEO) {
              this.videoTrackLocal = undefined;
              this.isVideoMuted = true;
            } else if (videoType === TRACK_TYPE.DESKTOP) {
              this.desktopTrackLocal = undefined;
            }
          }
        }
      } catch (error) {
        console.log('removeLocalTrack--->', error);
      }
    }
  };
  shareScreen = async () => {
    this.hasPermissions = await JitsiMeetJS.mediaDevices.isDevicePermissionGranted('video');

    if (!this.hasPermissions) {
      toastError(
        'To enable screen-share, go to System Settings -> Privacy & Security -> Screen Recording, Add your browser. Please allow for your browser (Google Chrome, MS Edge or Firefox) to record your screen.',
        'PERMISSION_SCREEN_SHARE',
        10000
      );
      return;
    }
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    if (
      isiOS ||
      (JitsiMeetJS && JitsiMeetJS.util && JitsiMeetJS.util.browser && JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
    ) {
      if (this.participantsList.length >= 1) {
        toastError(
          'We are aware of occasional low quality video resolution issue on safari browser please use chrome, Firefox, Brave or Edge browser instead if you face the issue.',
          'PERMISSION_SCREEN_SHARE',
          15000
        );
      }
    }

    console.log('this.hasPermissions', this.hasPermissions);
    if (this.hasVideoPermissionIssue) this.isVideoMuted = true;
    //Create desktoptrack and replace with Videotrack
    try {
      if (this.firstTimeSharing) {
        console.log('firstTimeSharing :>> ', this.firstTimeSharing);
        let newCreatedTrack = await this.createJitsiLocalTrackForScreenShare([TRACK_TYPE.DESKTOP]);
        if (newCreatedTrack && newCreatedTrack.length > 1) {
          console.log('newCreatedTrack in if :>> ', newCreatedTrack);
          let findIndex = newCreatedTrack.findIndex((stream) => stream.videoType === TRACK_TYPE.DESKTOP);
          if (findIndex && findIndex !== 0) {
            this.desktopTrackLocal = newCreatedTrack[findIndex];
            console.log('findIndex :>> ', findIndex);
            this.tabShareAudioTrack = await newCreatedTrack.filter((track, index) => index !== findIndex)[0];
            console.log('this.desktopTrackLocal :>> ', this.desktopTrackLocal);
            console.log('tabShareAudioTrack :>> ', this.tabShareAudioTrack);
          }
          if (this.desktopTrackLocal) {
            if (this.desktopTrackLocal.sourceType === 'monitor') {
              this.isEntireScreenShared = true;
            } else {
              this.isEntireScreenShared = false;
            }
            await this.room.addTrack(this.desktopTrackLocal);
            // await this.room.addTrack(this.desktopTrackLocal[2]);
            this.firstTimeSharing = false;
            this.isScreenSharingOn = true;
            const participantName = this.participantsList && this.participantsList.find((user) => user._id === this.presenterList[0]);
            this.presenterName = participantName && getParticipantDisplayName(participantName._displayName, 0);
            this.screenShareOnBigScreen = true;
            this.isBigScreenHolderMuted = false;
            await this.desktopTrackLocal.attach(document.getElementById('own-screen-share'));
          }
        } else {
          console.log('newCreatedTrack in else :>> ', newCreatedTrack);
          this.desktopTrackLocal = newCreatedTrack && newCreatedTrack[0];
          if (this.desktopTrackLocal) {
            if (this.desktopTrackLocal.sourceType === 'monitor') {
              this.isEntireScreenShared = true;
            } else {
              this.isEntireScreenShared = false;
            }
            await this.room.addTrack(this.desktopTrackLocal);
            this.firstTimeSharing = false;
            this.isScreenSharingOn = true;
            const participantName = this.participantsList && this.participantsList.find((user) => user._id === this.presenterList[0]);
            this.presenterName = participantName && getParticipantDisplayName(participantName._displayName, 0);
            this.screenShareOnBigScreen = true;
            this.isBigScreenHolderMuted = false;
            await this.desktopTrackLocal.attach(document.getElementById('own-screen-share'));
          }
        }
      } else {
        let newCreatedTrack = await this.createJitsiLocalTrackForScreenShare([TRACK_TYPE.DESKTOP]);

        if (newCreatedTrack && newCreatedTrack.length > 1) {
          console.log('goes in if 1433');
          let findIndex = newCreatedTrack.findIndex((stream) => stream.videoType === TRACK_TYPE.DESKTOP);
          if (findIndex && findIndex !== 0) {
            var newScreenShare = newCreatedTrack[findIndex];
            this.tabShareAudioTrack = await newCreatedTrack.filter((track, index) => index !== findIndex)[0];
          }
          if (newScreenShare) {
            if (newScreenShare.sourceType === 'monitor') {
              this.isEntireScreenShared = true;
            } else {
              this.isEntireScreenShared = false;
            }
            console.log('replaceTrack 1443 :>> ');
            if (
              isiOS ||
              (JitsiMeetJS &&
                JitsiMeetJS.util &&
                JitsiMeetJS.util.browser &&
                JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
            ) {
              // await this.room.replaceTrack(this.desktopTrackLocal, newScreenShare);
              await this.desktopTrackLocal.dispose();
              await this.room.addTrack(newScreenShare);
            } else {
              await this.room.replaceTrack(this.desktopTrackLocal, newScreenShare);
            }
            this.isScreenSharingOn = true;
            const participantName = this.participantsList && this.participantsList.find((user) => user._id === this.presenterList[0]);
            this.presenterName = participantName && getParticipantDisplayName(participantName._displayName, 0);
            this.screenShareOnBigScreen = true;
            this.isBigScreenHolderMuted = false;
            this.desktopTrackLocal = newScreenShare;
            await this.desktopTrackLocal.attach(document.getElementById('own-screen-share'));
          }
        } else {
          console.log('goes in else 1454');
          newScreenShare = newCreatedTrack[0];
          if (newScreenShare) {
            if (newScreenShare.sourceType === 'monitor') {
              this.isEntireScreenShared = true;
            } else {
              this.isEntireScreenShared = false;
            }
            console.log('replaceTrack 1461 :>> ', this.desktopTrackLocal);
            console.log('newScreenShare 1467 :>> ', newScreenShare);
            if (
              isiOS ||
              (JitsiMeetJS &&
                JitsiMeetJS.util &&
                JitsiMeetJS.util.browser &&
                JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
            ) {
              // await this.room.replaceTrack(this.desktopTrackLocal, newScreenShare);
              await this.desktopTrackLocal.dispose();
              await this.room.addTrack(newScreenShare);
            } else {
              await this.room.replaceTrack(this.desktopTrackLocal, newScreenShare);
            }
            this.isScreenSharingOn = true;
            const participantName = this.participantsList && this.participantsList.find((user) => user._id === this.presenterList[0]);
            this.presenterName = participantName && getParticipantDisplayName(participantName._displayName, 0);
            this.screenShareOnBigScreen = true;
            this.isBigScreenHolderMuted = false;
            this.desktopTrackLocal = newScreenShare;
            await this.desktopTrackLocal.attach(document.getElementById('own-screen-share'));
          }
        }
      }
    } catch (error) {
      console.log('error', error);

      console.log('shareScreen---->', error);
    }
  };

  removeClassFromElementClassList = (element, styleClass) => !element && element.classList.remove(styleClass);

  addClassToElementClassList = (element, styleClass) => element.classList.add(styleClass);

  setStartAudioMuted = () => {
    if (this.isRestrictedAudio && this.isAudioMuted && !this.isModerator && !this.hasAudioPermissionIssue) {
      this.restrictedMuteMessage = 'moderator has restricted mic activity, cannot unmute right now';
      setTimeout(() => {
        this.restrictedMuteMessage = '';
      }, 3000);
    } else {
      this.startAudioMuted = !this.startAudioMuted;
      setTimeout(() => {
        this.onAudioMuteStateChanged();
      }, 100);
    }
  };

  setStartVideoMuted = async () => {
    console.log('this.isVideoMuted', this.isVideoMuted);
    this.startVideoMuted = !this.startVideoMuted;
    this.isVideoMuted = !this.isVideoMuted;
    console.log('setStartVideoMuted--->');

    setTimeout(async () => {
      if (this.videoTrackLocal) {
        if (this.isVideoMuted) {
          await this.videoTrackLocal.setEffect(await createJitsiStreamBackgroundEffect('none', 'none'));
          this.videoTrackLocal.mute();
        } else {
          this.videoTrackLocal.unmute();
          this.videoTrackLocal.setEffect(
            await createJitsiStreamBackgroundEffect(
              window.localStorage.getItem('virtualImageType'),
              window.localStorage.getItem('virtualImage')
            )
          );
        }
      }
    }, 100);
  };
  /** Muting of video tracks */
  onVideoMuteStateChanged = async () => {
    // debugger;
    try {
      if (!this.hasVideoPermissionIssue) {
        this.isVideoMuted = !this.isVideoMuted;
        await this.muteUnmuteVideoTrack();
      } else {
        this.jitsiErrorMessage = 'Camera is blocked, Please grant permission to access camera and refresh the page.';
        setTimeout(() => {
          this.jitsiErrorMessage = '';
        }, 4000);
      }
    } catch (error) {
      console.log('onVideoMuteStateChanged---->', error);
    }
  };
  attachLocalscreen = async () => {
    let newVideo = `<div class="videoOuter"><video class='remote-video-styles  ${
      this.shouldFlipVideo ? 'video-flip' : ''
    } small-screen' poster=${cameraOffPoster}  autoplay id='${this.myJitsiId}' /></div><span class="displayname">${
      this.displayName
    }</span>`;
    this.localSmallScreen = newVideo;
    if (!this.pinnedParticipant) {
      this.mainVideo = this.localSmallScreen;
    }
    if (this.room) {
      console.log(this.room && this.room.getLocalTracks(), 'loclmute');
      let track = this.room.getLocalTracks().find((t) => t.getType() === TRACK_TYPE.VIDEO);
      track && (await this.attachTrack(track, this.myJitsiId));
    } else {
      await this.attachTrack(this.videoTrackLocal, this.myJitsiId);
    }
  };
  //Local video track MuteChanged event hadler responsible for switching between avatar and video screens
  localVideoTrackMuteChangedEventHandler = async () => {
    try {
      console.log('localVideoTrackMuteChangedEventHandler', this.remoteTracks);
      if (this.isVideoMuted && this.room) {
        let track = this.room.getLocalTracks().find((t) => t.getType() === TRACK_TYPE.VIDEO);
        if (track && track.videoType === TRACK_TYPE.DESKTOP) {
          this.attachLocalscreen();
        } else {
          let imageElement = this.getProfilePicture(this.currentUser.email) ? (
            <div className="thumbOuter">
              <i>
                <img src={this.getProfilePicture(this.currentUser.email)} alt="Smiley face" className="small-screen" id={this.myJitsiId} />
              </i>
              <span className="displayname">{this.displayName}</span>
            </div>
          ) : (
            <div className="img" id={this.myJitsiId}>
              <Avatar className="small-screen" round={true} size={100} color={`#${'7b79b3'}`} name={this.displayName} />
              <span className="displayname">{this.displayName}</span>
            </div>
          );
          this.localSmallScreen = imageElement;
          if (!this.pinnedParticipant) {
            this.mainVideo = this.localSmallScreen;
          }
          this.isBigScreenHolderMuted = true;
        }
      } else {
        this.attachLocalscreen();
      }
    } catch (error) {
      console.log('localVideoTrackMuteChangedEventHandler---->', error);
    }
  };

  attackLocalVideoOnMain = async () => {
    try {
      const localUserTrack = await this.room.getLocalTracks().find((t) => t.getType() === TRACK_TYPE.VIDEO);
      console.log('localUserTrack: ', localUserTrack);
      if (localUserTrack && !this.isVideoMuted) {
        console.log('object if :>> ');
        await localUserTrack.attach(document.getElementById('localVideo1'));
      } else {
        console.log('object else :>> ');
        let imageElement = this.getProfilePicture(this.currentUser.email) ? (
          <div className="thumbOuter">
            <i>
              <img src={this.getProfilePicture(this.currentUser.email)} alt="Smiley face" className="small-screen" id={this.myJitsiId} />
            </i>
            <span className="displayname">{this.displayName}</span>
          </div>
        ) : (
          <div id={this.myJitsiId} className="img">
            <Avatar className="small-screen" round={true} size={100} color={`#${'7b79b3'}`} name={this.displayName} />
            <span className="displayname">{this.displayName}</span>
          </div>
        );
        this.mainVideo = imageElement;
      }
    } catch (err) {
      console.log('errr', err);
    }
  };
  //Remote video track MuteChanged event hadler responsible for switching between avatar and video screens
  remoteVideoTrackMuteChangedEventHandler = async (track) => {
    setTimeout(async () => {
      try {
        if (track && track.videoType === 'desktop') {
          const id = track.ownerEndpointId;
          if (track.muted) {
            let newSmallScreensMap = this.smallScreensMap;
            let newIdTrackMap = this.idTrackMap;
            newIdTrackMap.delete(id + 'screenshare');
            newSmallScreensMap.delete(id + 'screenshare');
            this.smallScreensMap = newSmallScreensMap;
            this.idTrackMap = newIdTrackMap;
            if (this.pinnedParticipant === id + 'screenshare') {
              this.pinnedParticipant = null;
              await this.attackLocalVideoOnMain();
            }

            const isUserScreenPresent = Array.from(newSmallScreensMap.keys()).find((keyname) => keyname.includes('screenshare'));
            if (!isUserScreenPresent && !this.isTileView) {
              await this.handleTileView();
            }
          } else {
            if (this.isTileView) {
              await this.handleTileView();
            }
            const newVideo = `<div class="videoOuter"><video class= 'remote-video-styles small-screen' poster=${cameraOffPoster} autoplay id='${
              id + 'screenshare'
            }' /></div><span class="displayname">${this.getDisplayName(id)}'s Screen</span>`;
            let smallScreensMapNew = this.smallScreensMap;
            let idTrackMapNew = this.idTrackMap;
            smallScreensMapNew.set(id + 'screenshare', newVideo);
            idTrackMapNew.set(id + 'screenshare', track);
            this.smallScreensMap = smallScreensMapNew;
            this.idTrackMap = idTrackMapNew;
            await this.attachTrack(track, id + 'screenshare');
            this.pinParticipant(id + 'screenshare');
          }
        } else if (track && !track.disposed) {
          let map = this.smallScreensMap;
          //this.toggleBigScreenAvatar(track);

          if (track.muted) {
            let imageElement = this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId)) ? (
              <div className="thumbOuter">
                <i>
                  <img
                    src={this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId))}
                    alt="Smiley face"
                    className="small-screen"
                    id={track.ownerEndpointId}
                  />
                </i>
                <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
              </div>
            ) : (
              <div className="img" id={track.ownerEndpointId}>
                <Avatar
                  className="small-screen"
                  round={true}
                  size={100}
                  color={`#${this.idAvatarColorMap.get(track.ownerEndpointId)}`}
                  name={this.idDisplayNameMap.get(track.ownerEndpointId)}
                />
                <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
              </div>
            );
            map.set(track.ownerEndpointId, imageElement);
            if (track.ownerEndpointId) this.smallScreensMap = map;

            if (
              (this.mainVideo && this.mainVideo.toString().includes(track.ownerEndpointId)) ||
              this.pinnedParticipant === track.ownerEndpointId
            ) {
              this.mainVideo = this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId)) ? (
                <div className="thumbOuter">
                  <i>
                    <img
                      src={this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId))}
                      alt="Smiley face"
                      className="small-screen"
                      id={track.ownerEndpointId}
                    />
                  </i>
                  <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
                </div>
              ) : (
                <div className="img" id={track.ownerEndpointId}>
                  <Avatar
                    className="small-screen"
                    round={true}
                    size={100}
                    color={`#${this.idAvatarColorMap.get(track.ownerEndpointId)}`}
                    name={this.idDisplayNameMap.get(track.ownerEndpointId)}
                  />
                  <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
                </div>
              );
              this.pinnedParticipant = null;
              this.mainVideoId = track.ownerEndpointId;
            }
            this.isBigScreenHolderMuted = true;
          }
          if (!track.muted) {
            let newVideo = `<div class="videoOuter"><video class= 'remote-video-styles ${
              this.participantFlipVideo.includes(track.ownerEndpointId) ? '' : 'video-flip'
            } small-screen'   autoplay id='${track.ownerEndpointId}' /></div><span class="displayname">${this.getDisplayName(
              track.ownerEndpointId
            )}</span>`;
            map.set(track.ownerEndpointId, newVideo);
            if (track.ownerEndpointId) {
              this.smallScreensMap = map;
              track && (await this.attachTrack(track, track.ownerEndpointId));
            }

            if (this.mainVideoId === track.ownerEndpointId) {
              this.mainVideo = `${track.ownerEndpointId}`;
              this.mainVideoId = track.ownerEndpointId;
              await track.attach(document.getElementById('localVideo1'));
            }
          }
        }
      } catch (error) {
        console.log('remoteVideoTrackMuteChangedEventHandler---->', error);
      }
    }, 2000);
  };
  readUploadedFileDataURL = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };

  uploadFile = async (event, type) => {
    if (!event.target || !event.target.files) {
      return;
    }
    const fileList = event.target.files;
    // Uploads will push to the file input's `.files` array. Get the last uploaded file.
    const latestUploadedFile = fileList.item(fileList.length - 1);

    try {
      const fileContents = await this.readUploadedFileDataURL(latestUploadedFile);
      // console.log(latestUploadedFile);
      await this.videoTrackLocal.setEffect(await createJitsiStreamBackgroundEffect('add-image', fileContents));
      this.hasVirtualBackground = true;
      this.virtualBackgroundValue = 'add-image';
      window.localStorage.setItem('virtualImage', fileContents);
      window.localStorage.setItem('virtualImageType', 'add-image');
    } catch (e) {
      console.log(e);
    }
  };

  setVirtualBackground = async (event) => {
    try {
      console.log('attaching', event && event.target);
      if (event && event.target) {
        let type = event.target.getAttribute('type');

        if (type === 'none') {
          await this.videoTrackLocal.setEffect(await createJitsiStreamBackgroundEffect(type, 'none'));
          this.hasVirtualBackground = false;
          this.virtualBackgroundValue = 'none';
          window.localStorage.removeItem('virtualImage');
          window.localStorage.removeItem('virtualImageType');
        } else if (type === 'add-image') {
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = 'image/png, image/jpeg, image/jpg';
          fileInput.addEventListener('change', this.uploadFile);
          fileInput.click();
        } else if (type === 'image' || type === 'blur') {
          let value = event.target.getAttribute('value');
          await this.videoTrackLocal.setEffect(await createJitsiStreamBackgroundEffect(type, value));
          this.hasVirtualBackground = true;
          this.virtualBackgroundValue = value;
          window.localStorage.setItem('virtualImage', value);
          window.localStorage.setItem('virtualImageType', type);
        }
        this.virtualBackgroundType = type;
      }
    } catch (error) {
      console.log('setVirtualBackground--->', error);
      await this.videoTrackLocal.dispose();
      await this.room.removeTrack(this.videoTrackLocal);
      this.videoTrackLocal = await this.createJitsiLocalTrack('video');
      if (event && event.target) {
        let type = event.target.getAttribute('type');
        let value = event.target.getAttribute('value');
        if (!_.isNil(value) && !_.isNil(type)) await this.videoTrackLocal.setEffect(await createJitsiStreamBackgroundEffect(type, value));
      }
    }
  };
  removeTrack = async (track) => {
    if (track.getType() === 'video' && track.videoType === 'camera') {
      await track.setEffect(undefined);
    }
    await this.room.removeTrack(track);
  };
  attachVideo = async () => {
    try {
      console.log('attach video');
      if (!this.videoTrackLocal.disposed) await this.videoTrackLocal.unmute();
      else if (this.localTracks.length > 1 && !this.localTracks[1].disposed) {
        this.videoTrackLocal = this.localTracks[1];
      } else {
        let _videoTrackLocal = await this.createJitsiLocalTrack('video');
        if (this.hasVirtualBackground) {
          await _videoTrackLocal.setEffect(
            await createJitsiStreamBackgroundEffect(this.virtualBackgroundType, this.virtualBackgroundValue)
          );
        }
        //await this.room.replaceTrack(this.desktopTrackLocal, this.videoTrackLocal);
        if (this.localTracks.length > 1) {
          console.log('replaceTrack 1820 :>> ');
          var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
          if (
            isiOS ||
            (JitsiMeetJS &&
              JitsiMeetJS.util &&
              JitsiMeetJS.util.browser &&
              JitsiMeetJS.util.browser.getName().toLowerCase().includes('safari'))
          ) {
            // await this.room.replaceTrack(this.videoTrackLocal, _videoTrackLocal);;
            await this.videoTrackLocal.dispose();
            await this.room.addTrack(_videoTrackLocal);
          } else {
            await this.room.replaceTrack(this.videoTrackLocal, _videoTrackLocal);
          }
          this.videoTrackLocal = _videoTrackLocal;
        } else {
          this.room &&
            (await this.room
              .addTrack(this.videoTrackLocal)
              .then(() => {
                // console.log('Local video track pushed in room from onVideoMuteStateChanged');
              })
              .catch((error) => {
                console.log(error);
                this.isVideoMuted = true;
              }));
        }
      }
      await this.attachTrack(this.videoTrackLocal, this.myJitsiId);

      await this.switchScreenIfNotOnlyUser();
    } catch (error) {
      console.log('attachVideo', error);
    }
  };

  /** Muting of audio tracks */
  onAudioMuteStateChanged = async () => {
    if (!this.hasAudioPermissionIssue) {
      if (this.audioTrackLocal) {
        if (this.isAudioMuted) {
          const emailAddress = this.isGuest
            ? JSON.parse(sessionStorage.getItem('guestUser')).email
            : JSON.parse(localStorage.getItem('memoriaUser')).email;
          const speakerData = this.webinarSpeakers.find((speaker) => speaker.name === this.displayName && speaker.ID === emailAddress);
          if (this.isRestrictedAudio && !speakerData && !this.isModerator) {
            this.restrictedMuteMessage = 'moderator has restricted mic activity, cannot unmute right now';
            setTimeout(() => {
              this.restrictedMuteMessage = '';
            }, 3000);
          } else {
            console.log('audiotrack unmute');
            await this.audioTrackLocal.unmute();
            this.room &&
              (await this.room
                .addTrack(this.audioTrackLocal)
                .then(() => {
                  // console.log('Local audio track pushed in room from onAudioMuteStateChanged');
                })
                .catch((error) => {
                  console.log(error);
                }));

            this.isAudioMuted = false;
            this.muteButtonClick = false;
          }
        } else {
          console.log('audiotrack mute');
          await this.audioTrackLocal.mute();
          this.isAudioMuted = true;
        }
      }
    } else {
      this.jitsiErrorMessage = 'Microphone is blocked, Please grant permission to access microphone and refresh the page.';
      setTimeout(() => {
        this.jitsiErrorMessage = '';
      }, 4000);
    }
  };

  handleMuteRemoteParticipant = async (jitsiId) => {
    const selectTrack = this.idAudioTrackMap && this.idAudioTrackMap.get(jitsiId);
    if (selectTrack && !selectTrack.isMuted()) {
      this.room.muteParticipant(jitsiId, false);
    }
  };

  handleMuteAllParticipant = () => {
    this.participantsList.forEach((participant) => {
      this.room.muteParticipant(participant._id, false);
    });
  };

  handleAudioStatus = (jitsiId) => {
    let audioStatus = this.connStats(jitsiId).audioTrackStatus;
    if (audioStatus === AUDIO_TRACK.UNMUTE) return false;
    else if (audioStatus === AUDIO_TRACK.MUTE) return true;
    else if (audioStatus === AUDIO_TRACK.TRACK_NOT_AVAILABLE) {
      const selectParticipant = this.participantsList && this.participantsList.find((user) => user._id === jitsiId);
      const selectUserTrack = selectParticipant && selectParticipant._tracks;
      if (selectUserTrack && selectUserTrack.length) {
        const userStatus = selectUserTrack[0].muted;
        return userStatus;
      }
    } else return true;
  };

  getAudioTrackLocal = () => {
    return this.audioTrackLocal;
  };
  //removing all the html video elements from track's container list
  detachTrack = async (track, id) => {
    try {
      console.log('detach');
      if (track && track.containers) {
        if (track.getType() === TRACK_TYPE.VIDEO && track.videoType === TRACK_TYPE.DESKTOP) this.presenterName = '';
        await track.containers.forEach(async (container) => {
          if (container && container.id === id) {
            await track.detach(container);
          }
        });
      }
    } catch (error) {
      console.log('detachTrack', error);
    }
  };
  //removing all the html video elements from track's container list
  detachAllContainers = async (track) => {
    try {
      console.log('detaching... ');
      if (track && track.containers) {
        await track.containers.forEach(async (container) => {
          if (container) {
            let id = container.id;
            console.log('detaching for... ', id);
            document.getElementById(id) &&
              (await track
                .detach(document.getElementById(id))
                .then((t) => console.log(t, 'After'))
                .catch((e) => console.log(e, 'Detach --->')));
          }
        });
      }
    } catch (error) {
      console.log('detaching...-->', error);
    }
  };
  //adding html video container only if its not already present in containers list
  attachTrack = async (track, ids) => {
    try {
      let id = ids && ids.trim();
      if (id) {
        console.log('attachTrack');
        if (track && track.getType() === TRACK_TYPE.VIDEO) {
          // if ((id === 'localVideo1' && !this.pinnedParticipant) || id !== 'localVideo1') {
          track && (await this.detachTrack(track, id));
          let doc = await document.getElementById(id);
          doc && (await track.attach(document.getElementById(id.trim())));
          console.log('Attched Track-->', id, track);
          if (id.includes('screenshare') && id !== 'localVideo1') {
            document.getElementById('localVideo1') && (await track.attach(document.getElementById('localVideo1')));
            this.mainVideoId = track.ownerEndpointId + 'screenshare';
            console.log('Attched Track-->', id, 'localVideo1');
            this.isBigScreenHolderMuted = false;
            if (track.getType === TRACK_TYPE.VIDEO) {
              this.isBigScreenHolderMuted = false;
            }
          }
          if (id === 'localVideo1' && track.ownerEndpointId) {
            // console.log(doc, id, 'Attach');
            document.getElementById(track.ownerEndpointId) && (await track.attach(document.getElementById(track.ownerEndpointId)));
            console.log('Attched Track-->', track.ownerEndpointId);
          }

          if ((!track.isMuted() && track.videoType === 'camera') || this.isScreenSharingOn) this.isBigScreenHolderMuted = false;
        }
      }
    } catch (error) {
      console.log('attachTrack', error);
    }
  };

  /**
   * prelaunch page, creates a new track and adds a new track in a room
   * what happens when the new track gets added ? the old gets replaced with the new
   * PROBLEM IS IN THIS CODE
   */
  onLocalTracks = async (tracks) => {
    console.log('=== ON LOCAL TRACKS ===', tracks);
    try {
      this.localTracks = tracks;
      // console.log(tracks, 'OnlocalTrack');
      this.hasPermissions = await JitsiMeetJS.mediaDevices.isDevicePermissionGranted('video');
      console.log('this.hasPermissions :>> ', this.hasPermissions);
      for (let i = 0; i < this.localTracks.length; i++) {
        this.localTracks[i].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => console.log('local track stopped'));
        this.localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED, this.onAudioChanged);

        if (this.localTracks[i].getType() === 'video') {
          this.localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, this.localVideoTrackMuteChangedEventHandler);
          console.log('this.localTracks[i] video:>> ', this.localTracks[i]);
          this.videoTrackLocal = this.localTracks[i];
          this.videoTrackLocalState = this.localTracks[i];
          if (this.videoTrackLocal && window.localStorage.getItem('virtualImage')) {
            this.videoTrackLocal.setEffect(
              await createJitsiStreamBackgroundEffect(
                window.localStorage.getItem('virtualImageType'),
                window.localStorage.getItem('virtualImage')
              )
            );
            this.hasVirtualBackground = true;
            this.virtualBackgroundValue = window.localStorage.getItem('virtualImage');
          }
          if (this.isVideoMuted) {
            this.videoTrackLocal.mute();
            this.isBigScreenHolderMuted = true;
          }
          setTimeout(async () => {
            await this.attachTrack(this.videoTrackLocal, 'prelaunch-localvideo');
          }, 1000);
        } else {
          console.log('==== On Local Tracks : Inside Else - Audio ====');
          this.localTracks[i].addEventListener(
            JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
            // eslint-disable-next-line no-loop-func
            (_track) => {
              if (_track.getType() === 'audio') {
                this.audioMap.set(this.selfParticipantId, _track.track.muted);
              }
              if (!this.isAudioMuted) {
                if (!this.muteButtonClick) {
                  this.isAudioMuted = true;
                  this.isMutedNotify = true;
                  setTimeout(() => {
                    this.isMutedNotify = false;
                  }, 5000);
                }
              }
            }
          );
          console.log('this.localTracks[i] :>> ', this.localTracks[i]);
          this.localTracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel) => {
            this.localAudioLevel = audioLevel;
          });
          this.audioTrackLocal = this.localTracks[i];
          const audio = `<audio autoplay='1' muted='true' id='localAudio0' />`;
          this.audio = audio;
          this.audioTrackLocalState = this.localTracks[i];
          this.startAudioMuted && this.onAudioMuteStateChanged();
          setTimeout(() => {
            this.audioTrackLocal.attach(document.getElementById('localAudio0'));
          }, 100);
          try {
            if (document.getElementById('localAudio0') && JitsiMeetJS.mediaDevices.isDeviceChangeAvailable('output')) {
              let outputDeviceId = '';
              JitsiMeetJS.mediaDevices.enumerateDevices(async (devices) => {
                const audCommon = devices.filter((d) => d.kind === 'audiooutput' && d.deviceId === this.micDeviceId.value);
                const audioOutputDevices = devices.filter(
                  (d) =>
                    d.kind === 'audiooutput' &&
                    d.label.match(/\(([^)]+)\)/) != null &&
                    d.label.match(/\(([^)]+)\)/).length > 0 &&
                    d.label.match(/\(([^)]+)\)/)[1] === this.micDeviceId.label.match(/\(([^)]+)\)/)[1]
                );
                if (audCommon && audCommon.length > 0) outputDeviceId = audCommon[0].deviceId;
                else if (audioOutputDevices != null && audioOutputDevices.length > 0) outputDeviceId = audioOutputDevices[0].deviceId;
                else outputDeviceId = devices.filter((d) => d.kind === 'audiooutput')[0].deviceId;
                this.outputDeviceId = outputDeviceId;
                await JitsiMeetJS.mediaDevices.setAudioOutputDevice(outputDeviceId);
              });
            }
          } catch (error) {
            console.log(error.message);
          }
          // this.writeLog("local-audio-track-created", "success", "info");
          //If user takes time to click on Camera/Microphone permission dialog,
          //and command start_recording has passed, it will not get audio track stream
          //so start recording again if command was received but wasn't able to start recording
          console.log('this.streamCommandReceived :>> ', this.streamCommandReceived);
          console.log('this.isLocalStreaming :>> ', this.isLocalStreaming);
          console.log('this.isPreLaunch :>> ', this.isPreLaunch);

          console.log(
            'this.streamCommandReceived && !this.isLocalStreaming && !this.isPreLaunch',
            this.streamCommandReceived && !this.isLocalStreaming && !this.isPreLaunch
          );

          if (this.streamCommandReceived && !this.isLocalStreaming && !this.isPreLaunch) {
            console.log('goes inside in if 2589 - startLocalStreaming');
            await this.recordingAudio.startLocalStreaming(); // it should get here no matter what to do a socket init
          }
        }

        //If user takes time to click on Camera/Microphone permission dialog,
        //then conference will be joined without tracks,
        //so adding tracks if conference has already joined.
        if (this.isJoined) {
          try {
            const localAudioTrack = await this.room.getLocalTracks().find((t) => t.getType() === TRACK_TYPE.AUDIO);
            await this.room.addTrack(this.localTracks[i]).then(() => {});
          } catch (er) {
            console.log(er);
          }
        }
      }
    } catch (error) {
      console.log('onLocalTrack', error);
    }
  };

  conditionalSwitchBigScreen = (track, id) => {
    console.log('conditionalSwitchBigScreen', track, id, this.pinnedParticipant);
    if (track) {
      this.switchBigScreen(track, id);
    }
  };

  switchBigScreen = async (track, id) => {
    setTimeout(async () => {
      try {
        if (track.getType() === TRACK_TYPE.VIDEO) {
          if ((id === this.selfParticipantId && this.isVideoMuted) || !track || track.track.muted || track.muted) {
            if (track.videoType !== TRACK_TYPE.DESKTOP && track.videoType !== 'camera') {
              this.isBigScreenHolderMuted = true;
            }
          }
          if (track) {
            console.log('here-1');
            track && track.videoType === 'camera' ? (this.screenShareOnBigScreen = false) : (this.screenShareOnBigScreen = true);
            this.isBigScreenHolderMuted = false;
            try {
              setTimeout(async () => {
                let desktopTrackinRemote = await this.hasDesktopTrackinRemote();
                if (!desktopTrackinRemote) track && (await this.attachTrack(track, 'localVideo1'));
                if (track.videoType === TRACK_TYPE.DESKTOP) {
                  await this.pinParticipant(id, 'switchbig');
                }
              }, 2000);

              // if (id) {
              //   this.room.selectParticipant(id);
              // }
            } catch (error) {
              // console.log("couldn't select for high quality video");
            }
          }

          this.speakerOnScreen = id;
          this.speakerOnScreen === this.selfParticipantId
            ? (this.hasMoreThanOneParticipant = false)
            : (this.hasMoreThanOneParticipant = true);
          this.isTileView && (await this.tileAttachVideo());
        } else {
          this.isBigScreenHolderMuted = true;
        }
      } catch (error) {
        console.log(error.message);
      }
    }, 2000);
  };

  tileAttachVideo = async (shouldAttachLocalVideo = false) => {
    let map = this.smallScreensMap;
    for (const [key, value] of this.idTrackMap.entries()) {
      const participant = key;
      const track = value;
      const id = participant;
      // Remote User
      //console.log(key, track, videoType, id, 'titlaattach');
      if (track.muted) {
        let imageElement = this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId)) ? (
          <div className="thumbOuter">
            <i>
              <img
                src={this.getProfilePicture(this.idDisplayNameMap.get(track.ownerEndpointId))}
                alt="Smiley face"
                className="small-screen"
                id={track.ownerEndpointId}
              />
            </i>
            <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
          </div>
        ) : (
          <div className="img" id={track.ownerEndpointId}>
            <Avatar
              className="small-screen"
              round={true}
              size={100}
              color={`#${this.idAvatarColorMap.get(track.ownerEndpointId)}`}
              name={this.idDisplayNameMap.get(track.ownerEndpointId)}
            />
            <span className="displayname">{shortenString(this.idDisplayNameMap.get(track.ownerEndpointId), 12)}</span>
          </div>
        );
        map.set(track.ownerEndpointId, imageElement);
        if (track.ownerEndpointId) this.smallScreensMap = map;
      } else {
        //console.log(track, 'titleattach');

        let newRemoteVideo = `<div class="videoOuter"><video class='remote-video-styles small-screen ${
          key.includes('screenshare') ? '' : 'video-flip'
        }' autoPlay id='${id}' /></div><span class="displayname">
        ${this.getDisplayName(track.ownerEndpointId)}${key.includes('screenshare') ? "'s Screen" : ''}</span>`;
        map.set(key, newRemoteVideo);
        if (track.ownerEndpointId) {
          this.smallScreensMap = map;
          setTimeout(async () => {
            track && (await this.attachTrack(track, id));
          }, 200);
        }
      }
      // Local User
      if (this.isVideoMuted) {
        let imageElement = this.getProfilePicture(this.currentUser.email) ? (
          <div className="thumbOuter">
            <i>
              <img src={this.getProfilePicture(this.currentUser.email)} alt="Smiley face" className="small-screen" id={this.myJitsiId} />
            </i>
            <span className="displayname">{this.displayName}</span>
          </div>
        ) : (
          <div className="img" id={this.myJitsiId}>
            <Avatar className="small-screen" round={true} size={100} color={`#${'7b79b3'}`} name={this.displayName} />
            <span className="displayname">{this.displayName}</span>
          </div>
        );
        this.localSmallScreen = imageElement;
      } else if (this.videoTrackLocal) {
        let newLocalVideo = `<div class="videoOuter"><video class='remote-video-styles ${
          this.shouldFlipVideo ? ' video-flip ' : ''
        } small-screen' autoplay id='${this.myJitsiId}' /></div><span class="displayname">${this.displayName}</span>`;
        if (this.videoTrackLocal) {
          this.localSmallScreen = newLocalVideo;
          setTimeout(async () => {
            this.videoTrackLocal && (await this.attachTrack(this.videoTrackLocal, this.myJitsiId));
          }, 100);
        }
      }
      // if(!this.isTileView ){(await this.remoteVideoTrackMuteChangedEventHandler(track))}
    }
    if (this.desktopTrackLocal && this.isScreenSharingOn) {
      await this.desktopTrackLocal.attach(document.getElementById('own-screen-share'));
    }
    if (this.isTileView) {
      this.pinnedParticipant = null;
    }
    const isUserScreenPresent = Array.from(this.smallScreensMap.keys()).find((keyname) => keyname && keyname.includes('screenshare'));
    if (!this.isTileView && !isUserScreenPresent && shouldAttachLocalVideo) {
      this.mainVideoId = this.myJitsiId;
      this.mainVideo = 'video';
      await this.attackLocalVideoOnMain();
    }
  };

  handleTileView = async (shouldAttachLocalVideo = false) => {
    this.isTileView = !this.isTileView;
    this.isBigScreenHolderMuted = !this.isBigScreenHolderMuted;
    return setTimeout(async () => {
      await this.tileAttachVideo(shouldAttachLocalVideo);
      if (this.isTileView) {
        this.hideParticipantTiles = true;
      } else {
        //await this.tileAttachVideo();
        // window.clearTimeout(remoteParticipantTimeOut);
        this.hideParticipantTiles = false;
      }
    }, 100);
  };

  handleNotesView = async () => {
    this.isNoteView = !this.isNoteView;
  };

  /** Create local audio and video tracks */
  createLocalTracks = async () => {
    const deviceList = await this.getDeviceList();
    console.log('deviceList', deviceList);
    JitsiMeetJS.createLocalTracks({
      devices: deviceList,
      ...(this.cameraDeviceId && { cameraDeviceId: this.cameraDeviceId && this.cameraDeviceId.value }),
      micDeviceId: this.micDeviceId.value,
    })
      .then(this.onLocalTracks)
      .catch(() => {
        // console.log(error, 'createLocalTracks');
        // // this.writeLog("track-creation-event", error.message, "error");
        // JitsiMeetJS.createLocalTracks({ devices: ['audio'] })
        //   .then(this.onLocalTracks)
        //   .catch((error) => {
        //     console.log(error, 'createLocalTracks');
        //     //this.requestPermissionMedia();
        //     // this.writeLog("track-creation-event", error.message, "error");
        //     JitsiMeetJS.createLocalTracks({ devices: this.getDeviceList() })
        //       .then(this.onLocalTracks)
        //       .catch((error) => {
        //         console.log(error, 'createLocalTracks');
        //         //this.requestPermissionMedia();
        //         // this.writeLog("track-creation-event", error.message, "error");
        //       });
        //   });
      });
  };
  setFlagChangeClick = () => {
    this.muteButtonClick = true;
  };

  /**
   * @param type{'video','audio','desktop'}
   * @returns Jitsi mediaTrack object
   */
  createJitsiLocalTrack = async (type) => {
    try {
      console.log('createJitsiLocalTrack');
      let tracks = await JitsiMeetJS.createLocalTracks({
        devices: [type],
        cameraDeviceId: this.cameraDeviceId.value,
        micDeviceId: this.micDeviceId.value,
      });
      tracks[0].addEventListener(
        JitsiMeetJS.events.track.TRACK_MUTE_CHANGED,
        type === 'video' ? this.localVideoTrackMuteChangedEventHandler : () => console.log('Track muted')
      );
      // return tracks[0]; //test screen share issue
      // console.info(tracks);
      return tracks.find((track) => track.type === 'video');
    } catch (error) {
      console.log('createJitsiLocalTrack---->', error);
      if (type === TRACK_TYPE.VIDEO) {
        this.isVideoMuted = true;
        this.isBigScreenHolderMuted = true;
      }
    }
  };
  /* Fired when DOMINANT_SPEAKER_CHANGED event occurs. */
  onDominantSpeakerChange = async (id, prev) => {
    console.log('onDominantSpeakerChange', this.room.getParticipants());
    this.lastDominantUser = id;
    // if (id && !this.pinnedParticipant) {
    //   this.room.selectParticipant(id);
    // }

    if (!this.isTileView && id) {
      if (id === this.selfParticipantId || this.pinnedParticipant) {
        return;
      }
      if (id === this.pinnedParticipantScreen) {
        let track = this.idTrackMap.get(id);
        if (track) {
          this.mainVideoId = id;
          track.attach(document.getElementById('localVideo1'));
          return;
        }
      }
      this.mainVideo =
        this.lastDominantUser && [...this.smallScreensMap].find((keyval) => keyval[0] === this.lastDominantUser)
          ? [...this.smallScreensMap].find((keyval) => keyval[0] === this.lastDominantUser)[1]
          : this.localSmallScreen;

      if (this.mainVideo.toString().includes(TRACK_TYPE.VIDEO)) {
        if (this.lastDominantUser && this.idTrackMap) {
          let track = this.idTrackMap.get(id);
          this.mainVideoId = id;
          track && track.attach(document.getElementById('localVideo1'));
          if (track && track.getType() === TRACK_TYPE.VIDEO && track.videoType !== TRACK_TYPE.DESKTOP) this.presenterName = '';
        }

        // !this.isTileView && track && this.conditionalSwitchBigScreen(track, id);
      }
    }
  };
  switchVideo = async () => {
    this.isScreenSharingOn = false;
    this.hasPermissions = await JitsiMeetJS.mediaDevices.isDevicePermissionGranted('video');
    console.log('switchvideo');
    this.screenShareOnBigScreen = false;
    this.presenterName = '';

    //Replace desktoptrack with videotrack, create videotrack and assign only if previous videotrack is absent
    try {
      await this.createJitsiLocalTrack(TRACK_TYPE.VIDEO).then(async (t) => {
        // await this.desktopTrackLocal.dispose();
        if (t) {
          console.log(t, this.videoTrackLocal, 'THEN');
          this.videoTrackLocal = t;
          this.isVideoMuted && this.videoTrackLocal && this.videoTrackLocal.mute();
          await this.addorReplaceVideoTrack(this.videoTrackLocal);
        }
      });

      await this.localVideoTrackMuteChangedEventHandler();
    } catch (error) {
      console.log('Switch Video---->', error);
      this.isBigScreenHolderMuted = true;
      this.isScreenSharingOn = false;
      this.screenShareOnBigScreen = false;
      this.presenterName = '';
    }

    // re-apply the background effects
    if (this.virtualBackgroundValue && this.virtualBackgroundType && this.hasVirtualBackground) {
      try {
        await this.videoTrackLocal.setEffect(
          await createJitsiStreamBackgroundEffect(this.virtualBackgroundType, this.virtualBackgroundValue)
        );
      } catch (err) {
        console.log(err, 're-apply background effects');
      }
    }
  };
  myLocalsmallScreen = () => {
    let imageElement = this.getProfilePicture(this.currentUser.email) ? (
      <div className="thumbOuter">
        <i>
          <img src={this.getProfilePicture(this.currentUser.email)} alt="Smiley face" className="small-screen" id={this.myJitsiId} />
        </i>
        <span className="displayname">{this.displayName}</span>
      </div>
    ) : (
      <div id={this.myJitsiId} className="img">
        <Avatar className="small-screen" round={true} size={100} color={`#${'7b79b3'}`} name={this.displayName} />
        <span className="displayname">{this.displayName}</span>
      </div>
    );
    this.localSmallScreen = imageElement;
  };
  isOrganizer = () => (this.meeting.createdBy === this.currentUser.email ? 'true' : 'false');
  onConferenceJoined = async () => {
    try {
      const authorizeToken = this.isGuest ? sessionStorage.getItem('guestAuthorization') : localStorage.getItem('authorization');
      const XAuthorizeToken = this.isGuest ? sessionStorage.getItem('guest-x-authorization') : localStorage.getItem('x-authorization');
      this.meetingStatusSocket = new WebSocket(
        process.env.REACT_APP_WEBSOCKET_BASE_URL +
          'meetingstatus?' +
          'acc_id=' +
          this.meeting.accountId +
          '&meeting_id=' +
          this.meeting.meetingId +
          '&user=' +
          this.currentUser.email +
          '&isorganizer=' +
          this.isOrganizer() +
          '&endtime=' +
          this.meeting.endTime +
          '&token=' +
          authorizeToken +
          '&refresh=' +
          XAuthorizeToken +
          '&deviceInfo=' +
          window.navigator.userAgent.replace(/[\s;]+/g, '')
      );

      this.meetingStatusSocket.onclose = (event) => {
        console.log('WebSocket is closed:', event);
      };

      this.meetingStatusSocket.onerror = (event) => {
        console.log('WebSocket error observed:', event);
        this.recorder = null;
      };
      this.meetingStatusSocket.onopen = () => {
        setTimeout(() => this.meetingStatusSocket.send('ping'), 5000);
      };
      this.meetingStatusSocket.onmessage = () => {
        setTimeout(() => this.meetingStatusSocket.send('ping'), 20000);
      };
    } catch (error) {
      console.log(error.message);
    }

    this.myJitsiId = this.room.myUserId();

    this.myLocalsmallScreen();
    this.speakerOnScreen = this.myJitsiId;
    // this.localSmallScreen = imageElement;
    this.showConnectionStatus = true;
    this.isJoined = true;

    if (this.meeting.isWebinarMode && this.isRestrictedAudio && !this.isAudioMuted) {
      this.onAudioMuteStateChanged();
    }

    this.showConnectionStatus = setTimeout(() => {
      this.showConnectionStatus = false;
      // if (isBrowserFirefox() || isBrowserSafari()) {
      //   toastWarning(
      //     'We apologize that some of our users may experience video issues on Safari and Firefox. Our team is working diligently to resolve these compatibility issues. However, we recommend using Chrome, Edge, or Brave for the excellent user experience. We appreciate your continued support and will release the fixes soon.',
      //     'FIREFOX_ISSUE',
      //     10000
      //   );
      // }
    }, 1000);
    setTimeout(() => {
      this.isBrowserWarning = false;
    }, 10000);

    setTimeout(() => {
      if (this.isRecordingStartPrelaunch) {
        console.log('startLocalStreaming on preLaunch');
        this.recordingAudio.startLocalStreaming();
      }
    }, 6000);
    this.recordingNotification = setTimeout(() => {
      this.recordingNotification = false;
    }, 4000);

    this.localTracks.forEach(async (track) => {
      (await !track.disposed) && this.room.addTrack(track);
    });

    console.log('onConferenceJoined');
  };

  onConnectionFailed = () => {
    this.showConnectionFailedModal = true;

    this.connectionFailedTimeout = setInterval(this.connectionFailedTimer, 1000);

    console.error('Connection Failed!');
    // this.writeLog("connection-failed", "connection-failed", "error");
  };

  initialConnectionFailedCountdownSeconds;
  connectionFailedTimer = () => {
    if (this.connectionFailedCountdownSeconds > 0) {
      this.connectionFailedCountdownSeconds = this.connectionFailedCountdownSeconds - 1;
    } else {
      clearInterval(this.connectionFailedTimeout);
      window.location.reload();
    }
  };

  checkTrackForP2P = (track) => {
    debounce(2000, () => {
      console.log('this.participantsList.length :>> ', this.participantsList.length);
      if (track.disposed && this.participantsList.length < 2) {
        this.room.sendCommand('P2P_DISPOSED_TRACK', { value: track.ownerEndpointId, attributes: { uuid: uuidv4() } });
      }
    })();
  };

  handleP2Ptracks = async ({ value }) => {
    console.log('value', value);
    console.log('this.myJitsiId value', this.myJitsiId);
    if (!this.isAddedAudioTrack && value === this.myJitsiId) {
      console.log('goes in if');
      this.isAddedAudioTrack = true;
      await this.handleMediaDevices(this.micDeviceId);
    }
  };

  onConnectionSuccess = async () => {
    // const roomName = this.meeting.title.replace(/[\W_]+/g, '_').toLowerCase();
    const roomName = 'account_' + this.meeting.accountId + '_' + this.meeting.meetingId;
    this.room = await this.connection.initJitsiConference(roomName, window.config);

    this.room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, async (statsObject) => {
      //TODO Get local connection quality status, info is crucial in case we want to show our own connection quality with Jitsi server
      // console.log(statsObject);
      // const handleMediaDevicesDebounced = debounce(6000, () => this.handleMediaDevices(this.micDeviceId));

      if (statsObject.connectionQuality < 25) {
        Honeybadger.notify('USER_BANDWIDTH', {
          context: statsObject,
        });

        if (!this.isVideoMuted && !this.isBadBandwidth) {
          this.isBadBandwidth = true;
          this.onVideoMuteStateChanged();
        }
        // if (!this.isAddedAudioTrack) {
        //   console.log('goes in if');
        //   this.isAddedAudioTrack = true;
        //   await this.handleMediaDevices(this.micDeviceId);
        // }
        // else {
        //   console.log('goes in else');
        //   handleMediaDevicesDebounced();
        // }
      } else if (statsObject.connectionQuality > 50) {
        if (this.isBadBandwidth) {
          // this.onVideoMuteStateChanged();
          this.isBadBandwidth = false;
        }
      }
      let map = this.connectionQualityMap ? this.connectionQualityMap : new Map();
      statsObject.name = this.displayName;
      this.connectionBandwidthQuality = statsObject;
      map.set(this.selfParticipantId, statsObject);
      this.connectionQualityMap = map;
    });

    this.room.on(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, (id, statsObject) => {
      //TODO Get remote user's connection quality status, info is crucial in case we want to show individual user's bandwidth
      let map = this.connectionQualityMap ? this.connectionQualityMap : new Map();
      let userMap = this.idDisplayNameMap ? this.idDisplayNameMap : new Map();
      statsObject.name = userMap.get(id);
      map.set(id, statsObject);
      this.connectionQualityMap = map;
    });
    this.room.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, this.onDominantSpeakerChange);

    this.room.on(JitsiMeetJS.events.conference.NO_AUDIO_INPUT, () => {
      console.log('NO_AUDIO_INPUT');
    });
    this.room.on(JitsiMeetJS.events.conference.RECORDER_STATE_CHANGED, (recordingStatus) => {
      console.log('gege', recordingStatus);
      if (recordingStatus && (recordingStatus._status === 'on' || recordingStatus._status === 'pending')) {
        if (recordingStatus && recordingStatus._status === 'on') {
          if (this.videoRecorderSessionId !== recordingStatus._sessionID) {
            const audioOn = new Audio(recordingOnAudio);
            audioOn.play();
            this.recordingAudio.sendLocalStreamStartPing();
          }
          this.videoRecorderSessionId = recordingStatus._sessionID;
          this.isLoadingRecording = false;
        }
      }
      if (this.videoRecorderSessionId === recordingStatus._sessionID && (!recordingStatus._status || recordingStatus._status === 'off')) {
        const audioOff = new Audio(recordingOffAudio);
        audioOff.play();
        this.videoRecorderSessionId = null;
        this.isLoadingRecording = false;
      }
    });
    this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onTrackRemoved);

    this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
    this.room.on(JitsiMeetJS.events.conference.USER_JOINED, (id) => {
      this.remoteTracks[id] = [];
      this.onUserJoined(id);
    });
    this.room.on(JitsiMeetJS.events.conference.TRACK_VIDEOTYPE_CHANGED, (type) => console.log(type));
    this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
    this.room.on(JitsiMeetJS.events.conference.P2P_STATUS, (track) => console.log('track P@P :>> ', track));
    this.room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (id, text, ts) => {
      console.log(`${text} - Message Received From ${id} ${ts}`);
    });
    this.room.on(JitsiMeetJS.events.conference.CONFERENCE_ERROR, (errorCode) => {
      console.log(`CONFERENCE_ERROR ${errorCode}`);
    });
    // this.room.on(JitsiMeetJS.events.conference.ENDPOINT_MESSAGE_RECEIVED, (Message) => {
    //   console.log(`ENDPOINT_MESSAGE_RECEIVED`, Message);
    // });
    this.room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, (track) => this.onAudioMuteStateChanged);
    this.room.on(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, this.onUserRoleChanged);
    this.room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED, (userID, displayName) => console.log(userID - displayName));
    this.room.on(JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED, (speaker, audioLevel) =>
      this.onAudioLevelChanged(speaker, audioLevel)
    );
    this.room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () =>
      console.log(this.room.getPhoneNumber() - this.room.getPhonePin())
    );
    this.room.on(JitsiMeetJS.events.conference.CONNECTION_INTERRUPTED, () => {
      console.log('CONNECTION INTERRUPTED');
    });

    this.room.on(JitsiMeetJS.events.conference.KICKED, () => {
      const userType = this.isGuest ? 'guestUser' : 'internal';
      window.location.href = `/meetings/${this.meeting.meetingId}/review-page?userType=${userType}`;
    });
    await this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, async (track) => {
      // this.checkTrackForP2P(track);
      track && (await this.onRemoteTrack(track));
    });
    // this.localTracks &&
    //   this.localTracks.forEach(async (track) => {
    //     (await !track.disposed) && this.room.addTrack(track);
    //   });
    Honeybadger.notify('USER_JOINED', {
      context: {
        audioLevel: this.audioTrackLocal && this.audioTrackLocal.audioLevel,
        track: this.audioTrackLocal,
        devices: this.allDevices,
      },
    });
    this.room.join();

    // console.log(this.currentUser.userName || this.currentUser.email);

    /** Sending logged in User's username to Jitsi-meet */
    const emailAddress = this.isGuest
      ? JSON.parse(sessionStorage.getItem('guestUser')).email
      : JSON.parse(localStorage.getItem('memoriaUser')).email;
    if (!this.currentUser || typeof this.currentUser.userName == 'undefined' || this.currentUser.userName === '') {
      if (this.guestUserDisplayName && this.guestUserDisplayName !== '') {
        this.room.setDisplayName(`${this.guestUserDisplayName}_____${emailAddress}`);
        this.displayName = this.guestUserDisplayName;
      } else {
        this.room.setDisplayName(`${this.currentUser.email}_____${emailAddress}`);
      }
    } else {
      const { firstName, lastName, userName } = this.currentUser;
      if (firstName && lastName) {
        this.room.setDisplayName(`${firstName} ${lastName}_____${emailAddress}`);
      } else {
        this.room.setDisplayName(`${userName}_____${emailAddress}`);
      }
    }
    this.firstTimeSharing = true;
  };
  handleNoisymic = () => {
    console.warn('Noisy');
  };
  onAudioLevelChanged = (speaker, audioLevel) => {
    let audioLevelMap = this.idAudioLevelMap ? this.idAudioLevelMap : new Map();
    audioLevelMap.set(speaker, audioLevel);
    this.idAudioLevelMap = audioLevelMap;
    // console.log(speaker, audioLevel, 'Global');
  };
  //   onAudioLevelChangedSpeaker = (audioLevel, speaker) => {
  // if(_.isNil(speaker))
  // {

  // }
  // };
  connStats = (jitsiId) => {
    let map = this.connectionQualityMap ? this.connectionQualityMap : new Map();
    let videoMap = this.idTrackMap ? this.idTrackMap : new Map();
    let videoTrackStatus = videoMap.get(jitsiId)
      ? videoMap.get(jitsiId).muted
        ? AUDIO_TRACK.MUTE
        : AUDIO_TRACK.UNMUTE
      : AUDIO_TRACK.TRACK_NOT_AVAILABLE;
    let audioMap = this.idAudioTrackMap ? this.idAudioTrackMap : new Map();
    let audioTrackStatus = audioMap.get(jitsiId)
      ? audioMap.get(jitsiId).muted
        ? AUDIO_TRACK.MUTE
        : AUDIO_TRACK.UNMUTE
      : AUDIO_TRACK.TRACK_NOT_AVAILABLE;
    if (jitsiId === this.selfParticipantId) {
      videoTrackStatus = this.videoTrackLocal
        ? this.isVideoMuted
          ? AUDIO_TRACK.MUTE
          : AUDIO_TRACK.UNMUTE
        : AUDIO_TRACK.TRACK_NOT_AVAILABLE;
      audioTrackStatus = this.audioTrackLocal
        ? this.isAudioMuted
          ? AUDIO_TRACK.MUTE
          : AUDIO_TRACK.UNMUTE
        : AUDIO_TRACK.TRACK_NOT_AVAILABLE;
    }
    return (this.connectionStats = {
      id: jitsiId,
      statsObject: map.get(jitsiId),
      videoTrackStatus: videoTrackStatus,
      audioTrackStatus: audioTrackStatus,
    });
  };
  /**Executes when new user joins @param id
   *
   *  @param {string} id Jitsi Participant ID
   * function called on USER_JOINED event
   */

  onUserJoined = async (id) => {
    const participant = await this.room.getParticipantById(id);
    if (participant && !participant._hidden) {
      let idColorMap = this.idAvatarColorMap;
      idColorMap.set(id, getRandomColorHex());
      this.idAvatarColorMap = idColorMap;
      //id-displayName map, for connection quality card component
      let participant = getParticipantDisplayName(this.room.getParticipantById(id)._displayName, 0);
      // console.log(participant, ' has joined');
      let map = this.idDisplayNameMap;
      console.log('first', this.room.getParticipantById(id));
      let participants = await this.room.getParticipants();

      participants.forEach(async (_participant) => {
        map.set(_participant._id, getParticipantDisplayName(_participant._displayName, 0));
        // this.audioMap.set(_participant._id, false);
      });
      let imageElement = (await this.getProfilePicture(participant)) ? (
        <div className="thumbOuter">
          <i>
            <img src={this.getProfilePicture(participant)} alt="Smiley face" className="small-screen" id={id} />
          </i>
          <span className="displayname">{shortenString(participant, 12)}</span>
        </div>
      ) : (
        <div className="img" id={id}>
          <Avatar className="small-screen" round={true} size={100} color={`#${this.idAvatarColorMap.get(id)}`} name={participant} />
          <span className="displayname">{shortenString(participant, 12)}</span>
        </div>
      );

      let smallScreensMap = this.smallScreensMap;
      id && smallScreensMap.set(id, imageElement);
      this.smallScreensMap = smallScreensMap;
      this.userName = participant;
      this.hasUserJoinedAlert = true;
      this.participantsList = participants;
      this.idDisplayNameMap = map;
      await this.conditionalSwitchBigScreen(null, id);

      //If joined user elected as Moderator, send localStreamStart ping to all participants
      if (this.isModerator) {
        if (this.isLocalStreaming) {
          this.room.sendCommandOnce('localstream', {
            value: true,
            attributes: {},
            children: [],
          });
        }
      }
    }
    //this.writeLog("user-joined", `${participant}-with-id-${id}`, "info");
  };

  switchScreenIfNotOnlyUser = () => {
    console.log('switchScreenIfNotOnlyUser');
    try {
      if (this.room && this.room.getParticipants().length === 0) {
        if (this.videoTrackLocal) {
          this.hasMoreThanOneParticipant = false;
          this.switchBigScreen(this.videoTrackLocal, this.myJitsiId);
        }
      } else {
        if (this.room) {
          if (this.idTrackMap) {
            let track = this.idTrackMap.values().next().value;
            //show avatar if track is null
            if (track) {
              try {
                // this.room.selectParticipant(track.getParticipantId());
                this.room.pinParticipant(track.getParticipantId());
              } catch (error) {
                console.log("couldn't select for high quality video");
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onUserLeft = async (id) => {
    try {
      console.log(`onUserLeft ----> IsModerator: ${this.isModerator} - MeetingId: ${this.meeting.meetingId}`);
      // if (this.isModerator) {
      //   let userMessagesRef = firebaseconfig.database().ref('local/user-messages').child(this.meeting.meetingId);
      //   await userMessagesRef.update({ meetingFlags: { isRecordingStarted: false } });
      // }
      // let participant = this.participantsList.filter((user) => user._id === id);
      // console.log(participant.map((user) => user._displayName));
      console.log('=== Is Audio Recording Started - False === inside onUserLeft');
      this.isAudioRecordingStarted = false;
      if (this.pinnedParticipant === id) this.pinnedParticipant = null;
      if (this.speakerOnScreen === id || (this.room && this.room.getParticipants().length === 0)) {
        this.switchScreenIfNotOnlyUser();
      }
      this.removeDiv(id);
      this.removeDiv(id + 'screenshare');
      let map = this.idDisplayNameMap ? this.idDisplayNameMap : new Map();
      if (this.room) {
        if (this.remoteTracks[id]) {
          this.remoteTracks[id] = [];
        }

        let participants = await this.room.getParticipants();
        participants.forEach((participant) => {
          map.set(participant._id, getParticipantDisplayName(participant._displayName, 0));
        });

        this.smallScreensMap && this.smallScreensMap.delete(id);
        this.smallScreensMap && this.smallScreensMap.delete(id + 'screenshare');
        this.idTrackMap && this.idTrackMap.delete(id);
        this.idTrackMap && this.idTrackMap.delete(id + 'screenshare');
        this.participantsList = participants;
        this.removeDiv(id);
        this.removeDiv(id + 'screenshare');
      }
    } catch (error) {
      console.log(`onUserLeft ----> ${this.isModerator}`, error);
    }
  };

  getDisplayName = (id) => {
    if (id) {
      const jitsiId = id.replace('video', '');
      const displayName = this.participantsList.find((participant) => participant._id === jitsiId);
      return displayName ? shortenString(getParticipantDisplayName(displayName._displayName, 0), 12) : '';
    }
  };
  isReallyLowBandWidthFun = () => {
    const isReallyLowBandWidth = this.connectionBandwidthQuality && this.connectionBandwidthQuality.connectionQuality < 25;
    if (isReallyLowBandWidth) {
      return true;
    }
    return false;
  };
  /**
   * @author Nikhil
   * @param {string} elementId
   * HTML element ID of the remote element
   *
   * this function removes the dead div elements after user leaves a conference
   */
  removeDiv = (elementId) => {
    const videoElement = document.getElementById(elementId);
    const audioElement = document.getElementById(elementId + 'audio');
    videoElement && videoElement.remove();
    audioElement && audioElement.remove();
  };

  restrictAllUserForMute = () => {
    if (this.isRestrictedAudio) {
      firebaseconfig.database().ref(`local/user-messages/${this.meeting.meetingId}/webinarFlags`).update({ isRestrictForMute: false });
    } else {
      this.handleMuteAllParticipant();
      firebaseconfig.database().ref(`local/user-messages/${this.meeting.meetingId}/webinarFlags`).update({ isRestrictForMute: true });
    }
  };

  restrictAllUserForChat = async () => {
    if (this.isRestrictedChat) {
      firebaseconfig.database().ref(`local/user-messages/${this.meeting.meetingId}/webinarFlags`).update({ isRestrictForChat: false });
    } else {
      firebaseconfig.database().ref(`local/user-messages/${this.meeting.meetingId}/webinarFlags`).update({ isRestrictForChat: true });
    }
  };

  handleUnrestrict = async (member) => {
    // const db = firebaseconfig.database();
    let speakerRef = firebaseconfig
      .database()
      .ref('local/user-messages')
      .child(this.meeting.meetingId)
      // .child('webinarFlags')
      .child('/webinarSpeakers');

    speakerRef
      .orderByChild('ID')
      .equalTo(getParticipantDisplayName(member._displayName, 1))
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach(function (snap) {
            speakerRef.child(snap.key).remove();
          });
        } else {
          const newSpeaker = speakerRef.push();
          newSpeaker.set({
            name: getParticipantDisplayName(member._displayName, 0),
            ID: getParticipantDisplayName(member._displayName, 1),
          });
        }
      });
  };

  getProfilePicture = (user) => {
    const selectedParticipant = this.participantsDetails.find(
      (participant) => participant.email === user || participant.firstName + ' ' + participant.lastName === user
    );
    const avtarLink = selectedParticipant && selectedParticipant.avatar_url;
    if (avtarLink) {
      return avtarLink;
    } else {
      return;
    }
  };
}
