import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { getLoggedInUserObject } from './utils';

/**
 * @author Nikhil
 * @param {Date} timeInUTC JS Date Object
 * @returns {Date} MomentJS DateTime Object - Converted DateTime in user-selected/local timezone
 *
 * Checks whether user defined timezone is present in the DB. If it is
 * then converts datetime to that timezone, if not, converts to local timezone (gets from Browser)
 *
 */
export const convertDateTimeFromUTCtoLocal = (timeInUTC) => {
  let currentUser = getLoggedInUserObject();
  if (currentUser && currentUser.timezone != null && momentTimeZone.tz.zone(currentUser.timezone))
    return momentTimeZone.tz(moment.utc(timeInUTC), currentUser.timezone);
  else return moment.utc(timeInUTC).local();
};

/**
 * @author Nikhil
 * @returns {string} DateTime in desired format
 *
 * Checks whether the converted start and end time falls on the same day and returns
 * appropriate string accordingly
 */
export const displayDateTime = (startTime, endTime) => {
  let startTimeLocal = convertDateTimeFromUTCtoLocal(startTime);
  let endTimeLocal = convertDateTimeFromUTCtoLocal(endTime);
  if (moment(startTimeLocal).isSame(endTimeLocal, 'day'))
    return startTimeLocal.format('MMMM D, YYYY') + ' from ' + startTimeLocal.format('h:mma - ') + endTimeLocal.format('h:mma ');
  else
    return (
      startTimeLocal.format('MMMM D, YYYY') +
      ' ' +
      startTimeLocal.format('h:mma - ') +
      endTimeLocal.format('MMMM D, YYYY') +
      ' ' +
      endTimeLocal.format('h:mma ')
    );
};

export const formatDate = (date) => {
  let startTimeLocal = convertDateTimeFromUTCtoLocal(date);
  return startTimeLocal.format('MMMM D, YYYY');
};

export const formatMeetingTimeUTC = (startTime, endTime) => {
  const startTimeUTC = convertDateTimeFromUTCtoLocal(startTime);
  const endTimeUTC = convertDateTimeFromUTCtoLocal(endTime);
  return `${moment(startTimeUTC).format('h:mma')} - ${moment(endTimeUTC).format('h:mma')}`;
};
export const formatMeetingTime = (startTime, endTime) => `${moment(startTime).format('h:mma')} - ${moment(endTime).format('h:mma')}`;

export const parseInStoredTimezone = (time) => momentTimeZone.tz(time, getLoggedInUserObject().timezone);

export const getTimezoneOffset = (zone) => momentTimeZone.tz(zone).format('Z');

export const getTimeZoneAbbreviation = (zone) => momentTimeZone.tz(zone).format('z');

export const guessCurrentTimezone = () => momentTimeZone.tz.guess();

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};
