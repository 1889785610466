import React, { Component } from 'react';
import './styles.scss';
// import { analytics } from '../../firebaseconfig';
class LauncherPage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isMeetingPage: true,
    };
    var body = document.body;
    body.classList.add('launcher_overflow');
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.state) {
      const { isMeetingPage } = this.props && this.props.location && this.props.location.state;
      this.setState({
        isMeetingPage: isMeetingPage,
      });
    } else {
      this.setState({
        isMeetingPage: false,
      });
    }
  }

  componentWillUnmount() {
    var body = document.body;
    body.classList.remove('launcher_overflow');
  }

  handleJoinMeeting = (e) => {
    e.preventDefault();
    // const { URL } = this.props && this.props.location && this.props.location.state;
    const searchParams = this.props && this.props.location && this.props.location.search;
    const nextURL = new URLSearchParams(searchParams);
    const isGuestUser = this.props && this.props.location && this.props.location.state && this.props.location.state.userType === 'guest';
    if (nextURL) {
      window.location.href = `memoriaapp://${isGuestUser ? 'login/guest?' : 'login/internal'}${nextURL}`;
    }
    // if (userType === 'guest') {
    //   const { userData } = this.props && this.props.location && this.props.location.state;
    //   const { email, accountId, password } = userData.guestUser;
    //   window.location.href = `memoriaapp://login/${userType}?email=${email}&password=${password}&accountId=${accountId}&meetingId=${userData.meetingId}`;
    // } else if (userType === 'internal') {
    //   const { nextpage } = this.props && this.props.location && this.props.location.state;
    //   window.location.href = `memoriaapp://login/${userType}?nextpage=${nextpage}`;
    // }
    // analytics.logEvent('join_meeting', {
    //   meeting: userData.meetingId,
    //   userId: localStorage.getItem('uid'),
    //   email: userType === 'internal' ? userData.users.email : userData.guestUser.email,
    // });
  };

  render() {
    const { isMeetingPage } = this.state;
    return (
      <div className="main_wrapper">
        <section id="wrapper">
          <header className="app-header">
            <div className="headLogo">
              <span className="logoMain"></span>
            </div>
          </header>

          <section id="container">
            <div className="container">
              <p className="pb-60">
                <span className="launch_heading">First-time user of Memoria?</span>
                <a href="https://apps.apple.com/is/app/memoria-video-conferencing/id1512603106" className="btn-download">
                  Download from App Store
                </a>
              </p>

              <p className="pb-60">
                <span className="launch_heading">Already Installed Memoria?</span>
                <a href="/#" onClick={this.handleJoinMeeting} className={`btn btnBor ${isMeetingPage && 'disabled'}`}>
                  Join Meeting
                </a>
              </p>
            </div>
          </section>
        </section>
      </div>
    );
  }
}

export default LauncherPage;
