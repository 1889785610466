import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import './App.scss';
import firebaseconfig, { messaging, askForPermissioToReceiveNotifications } from './firebaseconfig';
import { PrivateRoute } from './helpers/PrivateRoutes';
import { getCookie, isBrowserSafari, isMobileSafari, lazyRetry, setCookie, updateUserToken } from './helpers/utils';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import GlobalStyles from './styles/globalStyles';
import { ProfileProvider } from './context/profileContext';
import avatar from '../src/assets/img/avatar.png';
import lockImg from '../src/assets/img/lock.svg';
import LauncherPage from './views/LauchPage';
import { observer } from 'mobx-react';
import SearchStore from './helpers/stores/SearchStore';
import Page403Invalid from './views/Pages/Page403/Page403Invalid';
import { MdOutlineClear } from 'react-icons/md';
import LoginPage from './views/Login/LoginPage';
// import { InstallPWA } from './InstallPWA';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => lazyRetry(() => import('./containers/DefaultLayouts/DefaultLayout'), 'DefaultLayout'));

// Pages
const ReviewPage = React.lazy(() => lazyRetry(() => import('./views/Review'), 'ReviewPage'));
const Action = React.lazy(() => lazyRetry(() => import('./views/Action/Action'), 'Action'));
const ForgotPassword = React.lazy(() => lazyRetry(() => import('./views/Login/ForgotPasswordPage'), 'ForgotPassword'));
const GuestLogin = React.lazy(() => lazyRetry(() => import('./views/Login/GuestLogin'), 'GuestLogin'));
const SignUpPage = React.lazy(() => lazyRetry(() => import('./views/Login/SignUpPage'), 'SignUpPage'));
// const NewMeeting = React.lazy(() => lazyRetry(() => import('./views/Meetings/MeetingPage/MeetingPage'), 'NewMeeting'));
const NewMeeting = React.lazy(() => lazyRetry(() => import('./jitsiSDK/MeetingWithSDK'), 'NewMeeting'));
const RecurringMeeting = React.lazy(() => lazyRetry(() => import('./jitsiSDK/RecurringMeeting'), 'RecurringMeeting'));
const GuestTranscriptView = React.lazy(() =>
  lazyRetry(() => import('./views/Meetings/MeetingTranscript/GuestTranscriptView'), 'GuestTranscriptView')
);
const Page403 = React.lazy(() => lazyRetry(() => import('./views/Pages/Page403/Page403'), 'Page403'));
const GuestMeetingEnded = React.lazy(() => lazyRetry(() => import('./views/Pages/MeetingEnded/GuestMeetingEnded'), 'GuestMeetingEnded'));
const notificationHelper = 'https://intercom.help/MemoriaCall/en/articles/6295524-how-to-set-up-memoriacall-notifications-in-chrome';
const Payment = React.lazy(() => lazyRetry(() => import('./helpers/payment/Payment'), 'Payment'));
const PaymentSuccessPage = React.lazy(() => lazyRetry(() => import('./views/Pages/Payment/SuccessPage'), 'PaymentSuccessPage'));
const FailedPaymentPage = React.lazy(() => lazyRetry(() => import('./views/Pages/Payment/FailedPaymentPage'), 'FailedPaymentPage'));
const SuccessSocialRegistered = React.lazy(() =>
  lazyRetry(() => import('./views/Social/SuccessSocialRegistered'), 'SuccessSocialRegistered')
);
const WebinarIndexPage = React.lazy(() => lazyRetry(() => import('./views/webinar/Webinar'), 'WebinarIndexPage'));
const FreePlanSubscriptionPage = React.lazy(() =>
  lazyRetry(() => import('./views/Users/ManageSubscription/FreePlanSubscriptionPage'), 'FreePlanSubscriptionPage')
);

class App extends Component {
  setProfilePic = (pic) => {
    this.setState({ profilePic: pic });
  };

  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      isGuest: false,
      loggedInUser: props.userStore.getloggedinUserdetails(),
      profilePic: avatar,
      setProfilePic: this.setProfilePic,
      isNotificationErrorModalOpen: false,
      isAndroidModal: false,
      webinarMeetingDetails: {},
    };
    this.updateDeviceToken();
  }

  updateDeviceToken = () => {
    try {
      if (messaging) {
        if (!isMobileSafari() && !isBrowserSafari()) {
          Notification.requestPermission().then((permission) => {
            if (permission !== 'denied') {
              messaging
                .getToken()
                .then((token) => {
                  this.props.userStore.deviceToken = token;
                  updateUserToken(token);
                })
                .then(() => askForPermissioToReceiveNotifications());
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  /** checking the authorization whenever the component is loaded */
  componentDidMount() {
    firebaseconfig.auth().onAuthStateChanged((user) => {
      user
        ? this.setState({ authenticated: user }, () => {
            if (this.state.authenticated && !this.state.isGuest) {
              this.props.userStore.getAllUserAccounts();
              const Notification = window.Notification || window.mozNotification || window.webkitNotification;
              if (!isMobileSafari() && !isBrowserSafari()) {
                Notification.requestPermission().then((permission) => {
                  if (permission !== 'granted') {
                    if (getCookie('isNotificationEnabled') !== 'not-enabled') {
                      this.setState({ isNotificationErrorModalOpen: true }, () => {
                        document.body.style.overflowY = 'hidden';
                      });
                    }
                    setCookie('isNotificationEnabled', 'not-enabled');
                    updateUserToken(this.props.userStore.deviceToken, true);
                  } else {
                    setCookie('isNotificationEnabled', 'enabled');
                    updateUserToken(this.props.userStore.deviceToken, false);
                  }
                });
              }
            }
          })
        : this.setState({ authenticated: null }, () => {
            if (!sessionStorage.getItem('guestUser')) {
              localStorage.clear();

              return <Redirect to="/" />;
            }
          });
    });
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isAndroid = /android/i.test(userAgent);
    if (isAndroid) {
      this.setState({ isAndroidModal: true }, () => {
        document.body.style.overflowY = 'hidden';
      });
    }
    if (!isMobileSafari() && !isBrowserSafari()) {
      navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
        notificationPerm.onchange = () => {
          if (Boolean(this.state.authenticated)) {
            if (notificationPerm.state === 'granted') {
              setCookie('isNotificationEnabled', 'enabled');
              this.setState({ isNotificationErrorModalOpen: false }, () => {
                document.body.style.overflowY = 'visible';
              });
              updateUserToken(this.props.userStore.deviceToken, false);
            } else {
              setCookie('isNotificationEnabled', 'not-enabled');
              this.setState({ isNotificationErrorModalOpen: true }, () => {
                document.body.style.overflowY = 'hidden';
              });
              updateUserToken(this.props.userStore.deviceToken, true);
            }
          }
        };
      });
    }
    // this.props.featureLimitStore.getRemainingFeatureLimit();
    this.initiateIntercom();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (process.env.REACT_APP_ENVIRONMENT_NAME !== 'development') console.log = () => {};
    if (this.state.loggedInUser !== prevState.loggedInUser) {
      this.initiateIntercom();
    }
  }

  initiateIntercom = () => {
    let user = this.state.loggedInUser;
    user &&
      window.Intercom('boot', {
        app_id: 'dxhy85mi',
        name: `${user.firstName ? user.firstName : user.email}`, // Full name
        email: user.email, // Email address
        user_id: user.userUid,
        account: localStorage.getItem('accountName'),
        is_guest: false, //tracks if guest user or not
        environment: process.env.REACT_APP_ENVIRONMENT_NAME, //should be Production or Development
      });
    window.intercomSettings = {
      app_id: 'dxhy85mi',
      custom_launcher_selector: '.question-circle',
    };
  };

  setGuestUser = (guestUser) => {
    this.setState({ isGuest: true, guestUser: guestUser });
  };

  setCurrentUser = (user) => {
    this.setState({ loggedInUser: user });
  };

  /** Method for signing out the user */
  signOut = async () => {
    await updateUserToken(this.props.userStore.deviceToken, true);
    window.Intercom('shutdown');
    try {
      this.props.webSocketStore.disconnectWebSocket();
      await firebaseconfig.auth().signOut();
      localStorage.clear();
      sessionStorage.clear();
      this.setState({ authenticated: false });
    } catch (error) {
      console.log('error', error);
    }
  };

  setWebinarMeetingDetails = (meetingDetails) => {
    this.setState({ webinarMeetingDetails: meetingDetails });
  };

  render() {
    const { isNotificationErrorModalOpen } = this.state;
    return (
      <ProfileProvider value={this.state}>
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              {/* <InstallPWA /> */}
              <Switch>
                <Route
                  exact
                  path="/launcher"
                  name="Login Page"
                  render={(props) => <LauncherPage {...props} authenticated={this.state.authenticated} user={this.setCurrentUser} />}
                />
                <Route
                  exact
                  path="/meetings/:meetingId/review-page"
                  name="Review Page"
                  render={(props) => <ReviewPage {...props} authenticated={this.state.authenticated} user={this.setCurrentUser} />}
                />
                <Route
                  exact
                  path="/__/customAuth/action"
                  render={(props) => (
                    <Action
                      {...props}
                      authenticated={this.state.authenticated}
                      user={this.setCurrentUser}
                      userStore={this.props.userStore}
                    />
                  )}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <LoginPage user={this.setCurrentUser} updateDeviceToken={this.updateDeviceToken} {...props} />}
                />
                <PrivateRoute
                  exact
                  roles="ROLE_USER,ROLE_GUEST_USER,ROLE_ADMIN"
                  path="/payment"
                  authenticated={this.props.authenticated}
                  name="Payment Page"
                  component={Payment}
                  webSocketStore={this.props.webSocketStore}
                />
                <PrivateRoute
                  exact
                  roles="ROLE_USER,ROLE_GUEST_USER,ROLE_ADMIN"
                  path="/view-plans"
                  authenticated={this.props.authenticated}
                  name="View Plans Page"
                  webSocketStore={this.props.webSocketStore}
                  component={FreePlanSubscriptionPage}
                />
                <Route
                  exact
                  path="/login/internal"
                  name="Login Page"
                  render={(props) => <LoginPage {...props} user={this.setCurrentUser} />}
                />
                <Route
                  exact
                  path="/login/guest"
                  name=" Guest Login Page"
                  render={(props) => <GuestLogin {...props} guest={this.setGuestUser} />}
                />
                <Route exact path="/register" name="Register Page" component={SignUpPage} />
                <Route exact path="/register/success" name="Register Success Page" component={SuccessSocialRegistered} />
                <Route exact path="/forgot-password" name="Forgot Password Page" component={ForgotPassword} />
                <Route
                  exact
                  path="/guest-transcript-view"
                  render={(props) => (
                    <GuestTranscriptView
                      {...props}
                      searchStore={new SearchStore()}
                      featureLimitStore={this.props.featureLimitStore}
                      signOut={this.signOut}
                    />
                  )}
                />
                <Route
                  exact
                  path="/guest-meeting-ended"
                  render={() => (
                    <GuestMeetingEnded
                      searchStore={new SearchStore()}
                      featureLimitStore={this.props.featureLimitStore}
                      signOut={this.signOut}
                    />
                  )}
                />
                <Route exact path="/403" render={(props) => <Page403 {...props} />} />
                <Route exact path="/403Invalid" render={(props) => <Page403Invalid {...props} />} />

                <PrivateRoute
                  exact
                  path="/accounts/:accountId/meetings/:meetingId/launch"
                  roles="ROLE_USER,ROLE_GUEST_USER,ROLE_ADMIN"
                  name="MeetingPage"
                  component={NewMeeting}
                  authenticated={this.props.authenticated}
                  store={this.props.store}
                  userStore={this.props.userStore}
                  deviceToken={this.props.userStore.deviceToken}
                  signOut={this.signOut}
                  featureLimitStore={this.props.featureLimitStore}
                  webSocketStore={this.props.webSocketStore}
                />
                <PrivateRoute
                  exact
                  path="/accounts/:accountId/meetings/:meetingId/launch/:recurringMeetingId"
                  roles="ROLE_USER,ROLE_GUEST_USER,ROLE_ADMIN"
                  name="MeetingPage"
                  component={RecurringMeeting}
                  authenticated={this.props.authenticated}
                  store={this.props.store}
                  userStore={this.props.userStore}
                  deviceToken={this.props.userStore.deviceToken}
                  signOut={this.signOut}
                  featureLimitStore={this.props.featureLimitStore}
                  webSocketStore={this.props.webSocketStore}
                />
                <PrivateRoute
                  exact
                  path="/paymentSuccess"
                  roles="ROLE_USER,ROLE_GUEST_USER,ROLE_ADMIN"
                  name="paymentSuccessPage"
                  component={PaymentSuccessPage}
                  webSocketStore={this.props.webSocketStore}
                  authenticated={this.props.authenticated}
                />
                <PrivateRoute
                  exact
                  path="/paymentCancel"
                  roles="ROLE_USER,ROLE_GUEST_USER,ROLE_ADMIN"
                  name="FailedPaymentPage"
                  component={FailedPaymentPage}
                  authenticated={this.props.authenticated}
                  webSocketStore={this.props.webSocketStore}
                />
                <Route
                  path="/instantMeeting/:accountId/:meetingId/:webinarUserToken"
                  exact
                  render={(props) => (
                    <WebinarIndexPage
                      meetingDetails={this.state.webinarMeetingDetails}
                      setMeetingDetails={this.setWebinarMeetingDetails}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/webinar/:accountId/:meetingId/:webinarUserToken"
                  exact
                  render={(props) => (
                    <WebinarIndexPage
                      meetingDetails={this.state.webinarMeetingDetails}
                      setMeetingDetails={this.setWebinarMeetingDetails}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => (
                    <DefaultLayout
                      {...props}
                      userStore={this.props.userStore}
                      featureLimitStore={this.props.featureLimitStore}
                      webSocketStore={this.props.webSocketStore}
                      signOut={this.signOut}
                      authenticated={this.state.authenticated}
                    />
                  )}
                />
              </Switch>
            </ThemeProvider>
            {isNotificationErrorModalOpen ? (
              <div className="permiPop">
                <div className="permiPopInn">
                  <div className="permiPopCont">
                    <div className="permiPopTitle">
                      Please enable Notifications
                      <MdOutlineClear
                        onClick={() => {
                          setCookie('isNotificationEnabled', 'not-enabled');
                          this.setState({ isNotificationErrorModalOpen: false }, () => {
                            document.body.style.overflowY = 'visible';
                          });
                        }}
                      />
                    </div>
                    <p>
                      To use our SpeedCall™ feature please enable notifications permissions. To do so, click on
                      <img className="lockImg" src={lockImg} alt="lockImg" />
                      in the address bar and turn on the notification switch.
                    </p>
                    <p>
                      If you would like more details on how to do this, please visit the{' '}
                      <a href={notificationHelper} target="_blank" rel="noopener noreferrer">
                        Help Center.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </React.Suspense>
        </BrowserRouter>
      </ProfileProvider>
    );
  }
}

export default observer(App);
