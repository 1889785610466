const theme = {
  colors: {
    primary: '#00c6cc',
    primaryActive: '#fff',
    grayScale1: '#040406',
    grayScale2: '#a0a0a0',
    grayScale3: '#d1d1d1',
    grayScale4: '#cccccc',
    grayScale5: '#f9f9f9',

  },
  font: {
    family: 'Gotham',
    h1: '2.8rem',
    h2: '2.4rem',
    h3: '2rem',
    h4: '1.6rem',
    h5: '1.4rem',
    label: '1.4rem',
  },
  // borderRadius: '.4rem',
  // border: '.1rem solid',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.05)',
  margin: {
    p: '0 0 0 1rem;'
  }
}


export default theme; 