import { toast, cssTransition } from 'react-toastify';
import React from 'react';
import { MdOutlineClear } from 'react-icons/md';

const options = {
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: true,
  newestOnTop: true,
  draggable: false,
  pauseOnHover: true,
  transition: cssTransition({
    enter: 'fadeIn',
    exit: 'fadeOut',
  }),
  closeButton: false,
  position: toast.POSITION.TOP_RIGHT,
};

export const toastSuccess = (message, toastId = '', autoClose = 6000) => {
  if (toastId !== null) {
    options.toastId = toastId;
    options.autoClose = autoClose;
    if (!toast.isActive(toastId)) {
      toast.success(
        <div className="d-flex justify-content-between align-items-center">
          <span />
          <span>{message}</span>
          <MdOutlineClear className="fa-2x" />
        </div>,
        options
      );
    }
  } else {
    toast.success(
      <div className="d-flex justify-content-between align-items-center">
        <span />
        <span>{message}</span>
        <MdOutlineClear className="fa-2x" />
      </div>,
      options
    );
  }
};

export const toastError = (message, toastId = '', autoClose = 6000) => {
  if (toastId !== null) {
    options.toastId = toastId;
    options.autoClose = autoClose;
    if (!toast.isActive(toastId)) {
      toast.error(
        <div className="d-flex justify-content-between align-items-center">
          <span />
          <span>{message}</span>
          <MdOutlineClear className="fa-2x" />
        </div>,
        options
      );
    }
  } else {
    toast.error(
      <div className="d-flex justify-content-between align-items-center">
        <span />
        <span>{message}</span>
        <MdOutlineClear className="fa-2x" />
      </div>,
      options
    );
  }
};

export const toastInfo = (message, toastId = '', autoClose = 6000) => {
  if (toastId !== null) {
    options.toastId = toastId;
    options.autoClose = autoClose;
    if (!toast.isActive(toastId)) {
      toast.info(
        <div className="d-flex justify-content-between align-items-center">
          <span />
          <span>{message}</span>
          <MdOutlineClear className="fa-2x" />
        </div>,
        options
      );
    }
  } else {
    toast.info(
      <div className="d-flex justify-content-between align-items-center">
        <span />
        <span>{message}</span>
        <MdOutlineClear className="fa-2x" />
      </div>,
      options
    );
  }
};

export const toastWarning = (message, toastId = '', autoClose = 6000) => {
  if (toastId !== null) {
    options.toastId = toastId;
    options.autoClose = autoClose;
    if (!toast.isActive(toastId)) {
      toast.warn(
        <div className="d-flex justify-content-between align-items-center">
          <span />
          <span>{message}</span>
          <MdOutlineClear className="fa-2x" />
        </div>,
        options
      );
    }
  } else {
    toast.warn(
      <div className="d-flex justify-content-between align-items-center">
        <span />
        <span>{message}</span>
        <MdOutlineClear className="fa-2x" />
      </div>,
      options
    );
  }
};
