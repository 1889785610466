import axios from 'axios';
import firebaseconfig from '../firebaseconfig';
import {
  AUTHENTICATE_URL,
  GUEST_AUTHENTICATE_URL,
  forgotPasswordUrl,
  isUserRegisteredUrl,
  createAccountUrl,
  verifyCaptchaUrl,
  getUserAccountUrl,
  getAllUserAccountsUrl,
  updateDeviceTokenUrl,
} from '../urls';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const onLogin = async (email, password, accountID) => {
  try {
    /** Firebase Authentication API */
    const firebaseLoginResponse = await firebaseconfig.auth().signInWithEmailAndPassword(email, password);
    const _token = firebaseLoginResponse.user._lat;
    let body = {
      userUid: firebaseLoginResponse.user.uid,
      token: _token,
      emailAddress: email,
    };
    localStorage.setItem('uid', firebaseLoginResponse.user.uid);

    /** Memoria Server authentication api */
    let loginResponse = await axios.post(AUTHENTICATE_URL, body, { headers: headers });

    localStorage.setItem('memoriaUser', JSON.stringify(loginResponse.data.users));
    localStorage.setItem('authorization', loginResponse.headers.authorization);
    localStorage.setItem('accountId', accountID ? accountID : loginResponse.data.users.accountId);
    localStorage.setItem('accountName', loginResponse.data.accountName);
    localStorage.setItem('x-authorization', loginResponse.headers['x-authorization']);
    if (accountID) {
      loginResponse = await getUserAccount(accountID, loginResponse.data.users.userUid);

      localStorage.setItem('memoriaUser', JSON.stringify(loginResponse.data.users));
      localStorage.setItem('authorization', loginResponse.headers.authorization);
      localStorage.setItem('accountId', loginResponse.data.users.accountId);
      localStorage.setItem('accountName', loginResponse.data.accountName);
      localStorage.setItem('x-authorization', loginResponse.headers['x-authorization']);
    }

    return loginResponse;
  } catch (error) {
    return error;
  }
};

export const loginWithGoogle = async (userUid, token, email) => {
  try {
    let body = {
      userUid: userUid,
      token: token,
      emailAddress: email,
    };
    localStorage.setItem('uid', userUid);

    /** Memoria Server authentication api */
    const loginResponse = await axios.post(AUTHENTICATE_URL, body, { headers: headers });
    localStorage.setItem('memoriaUser', JSON.stringify(loginResponse.data.users));
    localStorage.setItem('authorization', loginResponse.headers.authorization);
    localStorage.setItem('accountId', loginResponse.data.users.accountId);
    localStorage.setItem('accountName', loginResponse.data.accountName);
    localStorage.setItem('x-authorization', loginResponse.headers['x-authorization']);
    return loginResponse;
  } catch (error) {
    return error;
  }
};

export const onGuestLogin = async (body) => {
  return axios.post(GUEST_AUTHENTICATE_URL, body);
};

export const forgotPassword = async (body) => {
  return axios.patch(forgotPasswordUrl(), body, { headers: headers });
};

export const createAccount = async (body) => axios.post(createAccountUrl(), body, { headers: headers });

export const isUserRegistered = async (body) => axios.post(isUserRegisteredUrl(), body, { headers: headers });

export const verifyCaptcha = async (body) => axios.post(verifyCaptchaUrl(), body, { headers: headers });

export const getUserAccount = async (accountId, userId) =>
  axios.get(getUserAccountUrl(accountId, userId), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      accountId: accountId,
      authorization: localStorage.getItem('authorization'),
    },
  });

export const getAllUserAccounts = async (accountId, userUid) =>
  axios.get(getAllUserAccountsUrl(accountId, userUid), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      accountId: accountId,
      authorization: localStorage.getItem('authorization'),
    },
  });
export const updateDeviceToken = async (body) => {
  return axios.patch(updateDeviceTokenUrl(localStorage.getItem('accountId')), body, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      accountId: localStorage.getItem('accountId'),
      authorization: localStorage.getItem('authorization'),
    },
  });
};
