import { action, makeObservable, observable } from 'mobx';
import { searchMeetings } from '../../services/MeetingService';
import React from 'react';
import meetingIcon from '../../assets/img/meeting-icon.png';
import transIcon from '../../assets/img/trans-icon.png';
import fileIcon from '../../assets/img/files-icon.png';
import { NavLink } from 'react-router-dom';
import { formatDate } from '../timeUtils';

export default class SearchStore {
  constructor() {
    makeObservable(this, {
      searchText: observable,
      transcriptSearchText: observable,
      inMeetingTitle: observable,
      inTranscript: observable,
      inChat: observable,
      inAttachments: observable,
      showResult: observable,
      getSearchResults: action.bound,
      reset: action.bound,
      searchResult: action.bound,
      lastSearchedText: observable,
    });
  }
  lastSearchedText = '';
  searchText = '';
  transcriptSearchText = '';
  inMeetingTitle = {};
  inTranscript = {};
  inChat = {};
  inAttachments = {};
  showResult = false;
  reset = () => {
    this.searchText = '';
    this.inMeetingTitle = {};
    this.inTranscript = {};
    this.inChat = {};
    this.inAttachments = {};
    this.showResult = false;
    this.transcriptSearchText = '';
    this.lastSearchedText = '';
  };
  formatMeetingDate = (dateTime) => formatDate(dateTime);

  getSearchResults = async (keyword) => {
    let results = await searchMeetings(keyword);
    this.inMeetingTitle = results['inMeetingTitle'];
    this.inTranscript = results['inTranscript'];
    this.inChat = results['inChat'];
    this.inAttachments = results['inAttachments'];
  };
  handleSearchList = () => {
    this.showResult = true;
    this.searchText = '';
  };
  searchResult = (meeting, type) => (
    <dd key={meeting.meetingId}>
      <NavLink to={`/meetings/${meeting.meetingId}#your_transcribed_meeting_tab_${type}`} onClick={this.handleSearchList}>
        <span>
          <img
            alt="Meetings"
            src={
              type === 'meeting'
                ? meetingIcon
                : type === 'transcript'
                ? transIcon
                : type === 'chat'
                ? fileIcon
                : type === 'attachments'
                ? fileIcon
                : undefined
            }
          />
        </span>
        {meeting.title} <span className="smallDate">{this.formatMeetingDate(meeting.startTime)}</span>
      </NavLink>
    </dd>
  );
}
