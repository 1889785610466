/*eslint-disable no-useless-escape*/

import React, { useEffect, useState } from 'react';
import firebaseconfig from '../../firebaseconfig';
import resource from '../../resourse';
import { Form, Spinner } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { isEmailValid, getLoggedInUserObject, AllowedIOSRoute } from '../../helpers/utils';
import { createAccount, getUserAccount, isUserRegistered, loginWithGoogle, onLogin } from '../../services/LoginService';
import { parse } from 'query-string';
import { getTimezoneOffset, guessCurrentTimezone } from '../../helpers/timeUtils';
import { removeGuestUserAuthorise } from '../../services/MeetingService';
import timezones from '../../helpers/timezones';
import { analytics } from '../../firebaseconfig';
import './loginPage.scss';
import { toastError } from '../../helpers/toastNotification';

import googleImg from '../../assets/img/google.svg';
import Honeybadger from '@honeybadger-io/js';
// import appleImg from '../../assets/img/apple.svg';

const LoginPage = (props) => {
  const queryParam = parse(props.location.search);
  const history = useHistory();
  const [formState, setFormState] = useState({ email: '', password: '', remember_me: false });
  const [formStateErrors, setFormStateErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  // const [isAppleLoading, setIsAppleLoading] = useState(false);
  const systemTimeZone = timezones.find((tzOpt) => getTimezoneOffset(guessCurrentTimezone()) === getTimezoneOffset(tzOpt.value));

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid(formState.email)) {
      setLoading(true);
      setFormStateErrors({});
      const response = await onLogin(formState.email, formState.password, queryParam.account_id);
      if (response.status >= 200 && response.status < 300) {
        typeof queryParam.nextpage == 'undefined'
          ? history.push('/meetings')
          : queryParam.planName
          ? history.push({ pathname: queryParam.nextpage, search: `?planName=${queryParam.planName}` })
          : history.push(queryParam.nextpage);

        props.user(response.data.users);
        props.updateDeviceToken();
      } else {
        setLoading(false);
        if (response.code === 'auth/wrong-password' || response.code === 'auth/user-not-found')
          setFormStateErrors({ ...formStateErrors, logInErr: 'The entered email or password is invalid.' });
        else {
          setFormStateErrors({ ...formStateErrors, logInErr: response.message });
        }
      }
    } else {
      setFormStateErrors({ ...formStateErrors, emailError: 'Please enter valid email address.' });
    }
  };

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem('isGuestUser') === '1') {
        removeGuestUserAuthorise();
      }
      let currentUser = getLoggedInUserObject();
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (!!queryParam.account_id && currentUser && currentUser.userUid) {
        const loginResponse = await getUserAccount(queryParam.account_id, currentUser.userUid);

        localStorage.setItem('memoriaUser', JSON.stringify(loginResponse.data.users));
        localStorage.setItem('authorization', loginResponse.headers.authorization);
        localStorage.setItem('accountId', loginResponse.data.users.accountId);
        localStorage.setItem('accountName', loginResponse.data.accountName);
        localStorage.setItem('x-authorization', loginResponse.headers['x-authorization']);
        currentUser = getLoggedInUserObject();
      }

      // // const isAndroid = /android/i.test(userAgent);
      const isiOS = /iPhone/.test(userAgent) && !window.MSStream;
      Honeybadger.notify('POC::IOS_DETECTION', {
        context: {
          isiOS: isiOS,
          userAgent: userAgent,
          MSStream: window.MSStream,
          opera: window.opera,
          vendor: navigator.vendor,
          file_name: 'login page',
          location: window.location.href,
        },
      });
      if (isiOS && !AllowedIOSRoute.some((v) => window.location.href.includes(v))) {
        if (queryParam.nextpage) {
          history.push({
            pathname: '/launcher',
            search: `?url=${document.URL.replace(/.*\/\/[^\/]*/, '')}`,
            state: { isMeetingPage: false, userType: 'internal', nextpage: queryParam.nextpage },
          });
        } else {
          history.push({
            pathname: '/launcher',
            search: `?url=${document.URL.replace(/.*\/\/[^\/]*/, '')}`,
            state: { isMeetingPage: false },
          });
        }
        return () => {};
      }

      //navigating directly to conference if user is logged in already
      if (queryParam.nextpage && currentUser) {
        if (currentUser.role === 'ROLE_USER' || currentUser.role === 'ROLE_ADMIN') {
          typeof queryParam.nextpage == 'undefined'
            ? history.push('/meetings')
            : queryParam.planName
            ? history.push({ pathname: queryParam.nextpage, search: `?planName=${queryParam.planName}` })
            : history.push(queryParam.nextpage);
        }
        //To prevent user to navigate to login page if he's already logged in
      } else if (currentUser) {
        typeof queryParam.nextpage == 'undefined'
          ? history.push('/meetings')
          : queryParam.planName
          ? history.push({ pathname: queryParam.nextpage, search: `?planName=${queryParam.planName}` })
          : history.push(queryParam.nextpage);
      }
    })();
  }, []);

  const handleGoogleLogin = () => {
    var provider = new firebaseconfig.auth.GoogleAuthProvider();
    firebaseconfig
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        if (result) {
          const userUid = result.user.uid;
          const token = result.user._lat;
          const email = result.user.email;
          const payload = {
            email: email,
          };
          setIsGoogleLoading(true);
          const isUserRegisteredRes = await isUserRegistered(payload);
          const { status } = isUserRegisteredRes.data;
          if (status) {
            const response = await loginWithGoogle(userUid, token, email);
            if (response.status >= 200 && response.status < 300) {
              typeof queryParam.nextpage == 'undefined'
                ? history.push('/meetings')
                : queryParam.planName
                ? history.push({ pathname: queryParam.nextpage, search: `?planName=${queryParam.planName}` })
                : history.push(queryParam.nextpage);
              setIsGoogleLoading(false);
              props.user(response.data.users);
              props.updateDeviceToken();
            } else {
              setLoading(false);
              setIsGoogleLoading(false);
              if (response.code === 'auth/wrong-password' || response.code === 'auth/user-not-found')
                setFormStateErrors({ ...formStateErrors, logInErr: 'The entered email or password is invalid.' });
              else {
                setFormStateErrors({ ...formStateErrors, logInErr: response.message });
              }
            }
          } else {
            handleNewRegister(email);
          }
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorMessage = error.message;
        setFormStateErrors({ ...formStateErrors, logInErr: errorMessage });
      });
  };

  const handleNewRegister = async (email) => {
    try {
      const response = await createAccount({
        email: email,
        nextPage: queryParam.nextPage,
        planName: queryParam.planName,
        timezone: systemTimeZone.value,
      });
      setIsGoogleLoading(false);
      // setIsAppleLoading(false);
      history.push({
        pathname: '/register/success',
        state: { email: email },
      });
      analytics.logEvent('new_register', { userId: response.data.uuid, email: email });
    } catch (error) {
      if (error.response.status === 400) {
        toastError(error.response.data.message, 'SIGN_UP_ERROR');
      } else if (error.response.status === 501) {
        toastError(error.response.data.message, 'SIGN_UP_ERROR');
      }
      setIsGoogleLoading(false);
      // setIsAppleLoading(false);
    }
  };

  // const handleAppleLogin = () => {
  //   var provider = new firebaseconfig.auth.OAuthProvider('apple.com');
  //   firebaseconfig
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then(async (result) => {
  //       if (result) {
  //         console.log('result :>> ', result);
  //         const userUid = result.user.uid;
  //         const token = result.user._lat;
  //         const email = result.user.email;
  //         const payload = {
  //           email: email,
  //         };
  //         setIsAppleLoading(true);
  //         const isUserRegisteredRes = await isUserRegistered(payload);
  //         const { status } = isUserRegisteredRes.data;
  //         if (status) {
  //           const response = await loginWithGoogle(userUid, token, email);
  //           console.log('response :>> ', response);
  //           if (response.status >= 200 && response.status < 300) {
  //             typeof queryParam.nextpage == 'undefined'
  //               ? history.push('/meetings')
  //               : queryParam.planName
  //               ? history.push({ pathname: queryParam.nextpage, search: `?planName=${queryParam.planName}` })
  //               : history.push(queryParam.nextpage);
  //             setIsAppleLoading(false);
  //             props.user(response.data.users);
  //             props.updateDeviceToken();
  //           } else {
  //             setLoading(false);
  //             setIsAppleLoading(false);
  //             if (response.code === 'auth/wrong-password' || response.code === 'auth/user-not-found')
  //               setFormStateErrors({ ...formStateErrors, logInErr: 'The entered email or password is invalid.' });
  //             else if (response.code === 'auth/too-many-requests') {
  //               setFormStateErrors({ ...formStateErrors, logInErr: response.message });
  //             } else {
  //               setFormStateErrors({ ...formStateErrors, logInErr: 'Server Error. Please try in some time' });
  //             }
  //           }
  //         } else {
  //           handleNewRegister(email);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('error :>> ', error);
  //       // Handle Errors here.
  //       const errorMessage = error.message;
  //       setFormStateErrors({ ...formStateErrors, logInErr: errorMessage });
  //     });
  // };

  const handleRegisterRedirect = () => {
    queryParam.nextpage
      ? queryParam.planName
        ? history.push(`/register?nextPage=${queryParam.nextpage}&planName=${queryParam.planName}`)
        : history.push(`/register?nextPage=${queryParam.nextpage}`)
      : history.push('/register');
  };

  return (
    <div className="billLogin">
      <header className="billHeader">
        <a href="/" className="billLogo">
          <img alt="Memoria Xall" src={resource.MemoriaLogo} />
        </a>
      </header>
      <section className="billCont">
        <div className="billLoginCont">
          <Form onSubmit={handleSubmit}>
            <dl>
              <dt>Sign In</dt>
              <dd>
                <input
                  className={formStateErrors && formStateErrors.emailError ? 'border border-danger' : ''}
                  type="text"
                  placeholder="Your email address"
                  name="email"
                  onChange={handleChange}
                  value={formState.email}
                  required
                />
                {formStateErrors && formStateErrors.emailError ? (
                  <p className="m-0 ml-4 text-danger text-left">{formStateErrors.emailError}</p>
                ) : null}
              </dd>
              <dd>
                <input
                  type="password"
                  placeholder="Your password"
                  name="password"
                  onChange={handleChange}
                  value={formState.password}
                  required
                />
              </dd>
              <dd className="forg">
                <label className="customCheck">
                  Remember Me
                  <input
                    type="checkbox"
                    name="remember_me"
                    checked={formState.remember_me}
                    onChange={() => {
                      setFormState({ ...formState, remember_me: Boolean(!formState.remember_me) });
                    }}
                  />
                  <span className="checkmark"></span>
                </label>
                <Link to="/forgot-password">Forgot Password?</Link>
              </dd>
              <dd>
                <button type="submit">
                  {isLoading ? (
                    <>
                      <Spinner className="mb-1" /> Signing In...
                    </>
                  ) : (
                    'Sign In'
                  )}
                </button>
              </dd>
              <dd>
                <div className="orOpt">
                  <span>or</span>
                </div>
              </dd>
              <dd className="twiceButton">
                <button type="button" className="google-btn" onClick={handleGoogleLogin}>
                  <i>
                    <img src={googleImg} alt="googleIcn" />
                  </i>
                  {isGoogleLoading ? (
                    <>
                      <Spinner className="mb-1" />
                    </>
                  ) : (
                    'Sign in with Google'
                  )}
                </button>
                {/* <button type="button" className="apple-btn" onClick={handleAppleLogin}>
                  <i>
                    <img src={appleImg} alt="appleIcn" />
                  </i>
                  {isAppleLoading ? (
                    <>
                      <Spinner className="mb-1" />
                    </>
                  ) : (
                    'Sign in with Apple'
                  )}
                </button> */}
              </dd>

              {formStateErrors && formStateErrors.logInErr ? (
                <dd>
                  <p className="text-danger m-0 mb-4">{formStateErrors.logInErr}</p>
                </dd>
              ) : null}
              <dd className="billSignUp">
                <button type="button" onClick={handleRegisterRedirect}>
                  Sign up with email
                </button>
              </dd>
            </dl>
          </Form>
        </div>
      </section>

      <div className="logFoo">
        By logging in to MemoriaCall, you agree to our
        <br />
        <a href="https://memoriacall.com/term-of-service" target="_blank" rel="noopener noreferrer">
          Terms & Conditions
        </a>{' '}
        and{' '}
        <a href="https://memoriacall.com/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
