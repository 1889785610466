import { action, makeObservable, observable } from 'mobx';
import { getRemainingFeatureLimit } from '../../services/MeetingService';
import { toastError } from '../toastNotification';
// import { getAllUserAccountsHelper } from '../utils';

export default class FeatureLimitStore {
  constructor() {
    makeObservable(this, {
      getRemainingFeatureLimit: action,
      featureLimitObject: observable,
      //   userAuthenticated: observable,
      //   deviceToken: observable,
      //   isNotificationReceived: observable,
      //   payload: observable,
      //   identity: observable,
      //   getLoggedInUserIdentity: action,
      //   allAccounts: observable,
      //   billingPlanData: observable,
    });
  }
  featureLimitObject = {};
  reset = () => {
    this.featureLimitObject = {};
  };
  getRemainingFeatureLimit = async () => {
    try {
      const limitRes = await getRemainingFeatureLimit();
      this.featureLimitObject = limitRes;
    } catch (error) {
      const { data } = error.response || {};
      if (data) {
        toastError(data.message, 'GET_FEATURE_LIMIT');
      } else {
        toastError(error.message || 'Something went wrong', 'GET_FEATURE_LIMIT');
      }
    }
  };
}
