import React, { Component } from 'react';
import { Button, Col, Container, InputGroup, Row } from 'reactstrap';
import { removeGuestUserAuthorise } from '../../../services/MeetingService';

class Page403Invalid extends Component {
  handleLogin = () => {
    if (sessionStorage.getItem('isGuestUser') === '1') {
      removeGuestUserAuthorise();
    }
    this.props.history.push('/login');
  };

  render() {
    return (
      <div className="app flex-row align-items-center parent-class">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">403</h1>
                <h4 className="pt-3">Forbidden</h4>
                <p className="text-muted float-left">
                  Guser user meeting not found. Please check emails if there is any updated meeting invites.
                </p>
              </div>
              <InputGroup>
                <Button color="info" onClick={() => this.handleLogin()}>
                  Go to Login
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page403Invalid;
