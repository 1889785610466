import BackArrow from './assets/img/back-arrow.svg';
import CheckCircle from './assets/img/check-circle.svg';
import MemoriaLogo from './assets/img/brand/Memoria.svg';
import CalendarPrev from './assets/img/calp.svg';
import CalendarNext from './assets/img/caln.svg';
import Lock from './assets/img/lock.svg';
import CalendlyLogo from './assets/img/calendly.svg';
import CalendlyStep1IMG from './assets/img/cale01.webp';
import CalendlyStep2IMG from './assets/img/cale02.webp';
import CalendlyStep3IMG from './assets/img/cale03.webp';
import NextArrow from './assets/img/next.svg';
import PrevArrow from './assets/img/prev.svg';
import editB from './assets/img/edit-b.svg';
import editW from './assets/img/edit-w.svg';
import microphone from './assets/img/microphone.svg';
import warning from './assets/img/warning.svg';
import successIcon from './assets/img/success-ico.svg';

export default {
  BackArrow,
  CalendarNext,
  CalendlyLogo,
  CalendlyStep1IMG,
  CalendlyStep2IMG,
  CalendlyStep3IMG,
  CalendarPrev,
  CheckCircle,
  MemoriaLogo,
  microphone,
  NextArrow,
  PrevArrow,
  Lock,
  editB,
  editW,
  warning,
  successIcon,
};
