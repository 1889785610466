import React from 'react';
import SessionImg from '../../../assets/img/session.svg';
import { updateUserToken } from '../../../helpers/utils';
import firebaseconfig from '../../../firebaseconfig';
import './ErrorComponent.scss';

const ErrorComponent = ({ userStore }) => {
  const handleRedirect = async () => {
    await updateUserToken(userStore.deviceToken, true);
    window.Intercom('shutdown');
    try {
      await firebaseconfig.auth().signOut();
      localStorage.clear();
      sessionStorage.clear();
    } catch (error) {
      console.log('error', error);
    }
  };
  return (
    <section className="sessionPop">
      <div className="sessionInn">
        <div className="sessionCont">
          <div className="sessIco">
            <img src={SessionImg} alt="session img" />
          </div>
          <div className="sessTitle">Your session has expired</div>
          <p>Please refress the page. Don't worry, We kept all of your filters and breakdowns in place.</p>
          <a href="#!" onClick={handleRedirect}>
            Refresh
          </a>
        </div>
      </div>
    </section>
  );
};

export default ErrorComponent;
