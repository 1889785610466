import { createGlobalStyle } from 'styled-components';
import gothamThin from './fonts/Gotham-Thin.woff2';
import gothamExtraLightItalic from './fonts/Gotham-ExtraLightItalic.woff2';
import gothamXLight from './fonts/Gotham-XLight.woff2';
import gothamBook from './fonts/Gotham-Book.woff2';
import gothamMedium from './fonts/Gotham-Medium.woff2';
import gothamBold from './fonts/Gotham-Bold.woff2';
import gothamUltra from './fonts/Gotham-Ultra.woff2';
import gothamBlack from './fonts/Gotham-Black.woff2';

const GlobalStyles = createGlobalStyle`
  body, html {
    font-size: 62.5%;
    font-family: Gotham, 'sans-serif';
    background-color: ${(props) => props.theme.colors.grayScale5};
    outline: none;
    // min-width: 48rem;
    input:focus,
    textarea:focus,
    select:focus,
    button:focus {
      outline: none;
    }
  }

  input, html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video, button {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  h1 {
    font-size: ${(props) => props.theme.font.h1}
  }
  h2 {
    font-size: ${(props) => props.theme.font.h2};
    font-weight: bold;
  }
  h3 {
    font-size: ${(props) => props.theme.font.h3};
    font-weight: bold;
  }
  h4 {
    font-size: ${(props) => props.theme.font.h4}
  }
  h5 {
    font-size: ${(props) => props.theme.font.h5}
  }
  
  a {
    color: ${(props) => props.theme.colors.primary};
    &:hover {
      text-decoration: none;
      color: ${(props) => props.theme.colors.primaryActive};
    }
  }
  p{
    margin-bottom: 1rem;
  }
  p,div,span {
    font-size: 1.4rem;
  }

  a.btn, 
  .btn {
    background: #00c6cc;
    color: #fff !important;
    font-weight: 400;
    padding: 0 30px;
    text-align: center;
    line-height: 50px;
    font-size: 17px;
    border-radius: 30px;
    display: inline-block;
    border:0;
    a {
      // color: white;
    }
    :hover {
      color: white;
      background-color: #000;
    }
  }

  .btn-secondary {
    color:#ffffff !important;
    background-color:#000 ;
    border:0;

    &:hover {
      background: #000;
      color: #fff !important;
    }

  }
  .close {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-size: 1.4rem;
    border-radius: ${(props) => props.theme.borderRadius};
    border: #ccc 1px solid;
    min-height: 4.8rem;
    height: 4.8rem;
    width: 100%;
    padding: 1.3rem;
    &:focus {
      border-color: ${(props) => props.theme.colors.primary};
      box-shadow: none;
    }

    ::-webkit-input-placeholder { /* Edge */
    color: #999;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #999;
}

::placeholder {
  color: #999;
}
  }
  input[type="checkbox"] {
    height: 1.5rem;
  }
  input:focus {
      border-color: ${(props) => props.theme.colors.primary};
      outline: 0;
      box-shadow: none;
  }
  label {
    position: relative;
    font-size: ${(props) => props.theme.font.label};
    display: inline-block;
    margin-bottom: 0;
    color: ${(props) => props.theme.colors.grayScale1};
  }
   select, textarea {
    font-size: 1.4rem;
    min-height: 4.8rem;
  }
  .grecaptcha-badge { 
    visibility: hidden; 
  }

  button.react-datepicker__navigation{
    min-height:auto;
    border-radius: 0;
    border-width: 7px;
  }
  .react-datepicker__navigation--previous{
    border-right-color: #00c6cc !important;
  }
  .react-datepicker__navigation--next {
    border-left-color: #00c6cc !important;
  }
  button.react-datepicker__navigation:hover{
    background:none;
  }
  .react-datepicker__time-list{
    overflow-x:hidden;
  }
  .react-datepicker__time-list::-webkit-scrollbar {
    width: 10px;
  }
  .react-datepicker__time-list::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    background: #00c6cc; 
  }
  .react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
    background: #00c6cc; 
  }
  .react-datepicker__day--selected, 
  .react-datepicker__day--in-selecting-range, 
  .react-datepicker__day--in-range, 
  .react-datepicker__month-text--selected, 
  .react-datepicker__month-text--in-selecting-range, 
  .react-datepicker__month-text--in-range, 
  .react-datepicker__quarter-text--selected, 
  .react-datepicker__quarter-text--in-selecting-range, 
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__month-text--keyboard-selected, 
  .react-datepicker__quarter-text--keyboard-selected{
    background: #00c6cc !important; 
    color:#fff !important;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
     height: 233px!important;
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    font-size:1.5rem !important;
  }
  .css-1a0s1k1-control, .css-ygquqd-control{
    border-color: #ccc !important;
  }
  .searchWrap {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }
  .meetingResult {
    color: #4d5156;
    padding: 20px 0;
  }
  .searchCont {
    padding: 40px;
    background: #fff;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
  }
  .meetingTitle {
    color: #000000;
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 20px;
    font-weight: bold;
  }
  .globeSearchTitle {
    color: #00c7cc;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 10px;
    margin-bottom: 0;
  }
  .globeSearchList {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: #eae8e8 1px solid;
  }
  .globeSearchList p{
    margin-bottom: 0;
  }
  .gSerchOuter {
    width: 292px;
    position: relative;
  }
  .gSerchOuter i{
    position:absolute;
    left: 20px;
    top: 14px;
    font-size:20px;
    
  }
  .gSerchOuter input {
    border: 0;
    padding: 0px 24px 0px 54px;
    border-radius: 24px;
    width:100%;
    height: 48px;
    color: #808080;
    box-shadow:inset rgb(0 0 0 / 10%) 0 5px 5px;
    position:relative;
    z-index:2;
    cursor:pointer;
  }
  .gSerchOuter input:focus {
    box-shadow:inset rgb(0 0 0 / 10%) 0 5px 5px;
  }
  .gSerchOuter input:focus +  i{
    z-index:3
  }
  .gSearchOption .smallDate{ 
    font-size: small;
    font-style: italic;
    padding-top: 3px;
    padding-left: 5px;
  }
  .gSearchOption {
    position: absolute;
    left: 22px;
    top: 100%;
    background: #fff;
    border: #ccc 1px solid;
    padding: 10px 20px;
    border-radius: 10px;
    width: 420px;
    max-height: 350px;
    overflow: auto;
  }
  .app-header .gSerchOuter{
    position:relative;
    height:48px;
    width:48px;
  }
  .app-header .gSerchOuter input{
    width:0;
    position:absolute;
    left:0;
    top:0;
    opacity:0;
    transition: all 1s
  }
  .app-header .gSerchOuter input:focus {
    width:292px;
    opacity:1;
  }
 
  .headLogo{
    width:292px;
  }
  .meetingContList  {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: #eae8e8 1px solid;
  }
  .meetingContList a{
    color: #23282c;
  }
  .meetingContTitle{
    color: #23282c;
    font-size: 20px;
    line-height: 24px;
    font-weight:normal;
    font-style:normal;
  }
  .meetingContList a li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 0;
    line-height: 16px;
  }
  .meetingContList a li i, .your_transcribed_meeting_page_new_left p i{
    display: inline-block;
    padding-right: 7px;
    height: 16px;
  }
  .meetingContList a li i.secImg, .your_transcribed_meeting_page_new_left p i.secImg{
    padding-left: 30px;
  }
    .gSearchOption::-webkit-scrollbar {
      width: 10px;
    }
    .gSearchOption::-webkit-scrollbar-track {
      background: #F1F1F1;
    }
    .gSearchOption::-webkit-scrollbar-thumb {
      background: #00c7cc;
    }
    .gSearchOption::-webkit-scrollbar-thumb:hover {
      background: #00c7cc;
    }    
  
  .gSearchOption dl {
    padding: 10px 0;
  }
  .gSearchOption dt {
    color: #00c7cc;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 10px;
    text-transform: uppercase;
    text-align: left;
  }
  .gSearchOption dd {
    padding: 7px 0;
    border-bottom: #eae8e8 1px solid;
  }
  .gSearchOption dd a {
    color: #000;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .gSearchOption dd span {
    display: inline-block;
    padding-right: 12px;
  }
  .gSerchCancel {
    background: #585656;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    top: 13px;
    color: #fff !important;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
  }
  .app-header .gSerchOuter .gSerchCancel{
    opacity: 1!important;
    right: 6px;
    position: absolute;
    top: 14px;
  }
  .app-header .gSerchOuter:hover .gSerchCancel{
    opacity:1 !important:
  }
.navbar-brand {
  width:195px !important;
}
.navbar-brand img {
  width:100%;
  height:auto;
}

  @font-face {
    font-family: 'Gotham';
    src: url(${gothamThin}) format('woff2');
    font-weight: 100;
    font-style: normal;
  };
  @font-face {
      font-family: 'Gotham';
      src: url(${gothamExtraLightItalic}) format('woff2');
      font-weight: 200;
      font-style: italic;
  };
  @font-face {
      font-family: 'Gotham';
      src: url(${gothamXLight}) format('woff2');
      font-weight: 300;
      font-style: normal;
  };
  @font-face {
      font-family: 'Gotham';
      src: url(${gothamBook}) format('woff2');
      font-weight: normal;
      font-style: normal;
  };
  @font-face {
      font-family: 'Gotham';
      src: url(${gothamMedium}) format('woff2');
      font-weight: 500;
      font-style: normal;
  };
  @font-face {
      font-family: 'Gotham';
      src: url(${gothamBold}) format('woff2'),
      url('./fonts/Gotham-Bold.woff') format('woff');
      font-weight: bold;
      font-style: normal;
  };
  @font-face {
      font-family: 'Gotham Ultra';
      src: url(${gothamUltra}) format('woff2');
      font-weight: 800;
      font-style: normal;
  };
  @font-face {
      font-family: 'Gotham';
      src: url(${gothamBlack}) format('woff2');
      font-weight: 900;
      font-style: normal;
  };
`;

export default GlobalStyles;
