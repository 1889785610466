/*eslint-disable no-useless-escape*/

import Honeybadger from '@honeybadger-io/js';
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLoggedInUserObject, getLoggedInGuestUserObject, AllowedIOSRoute } from './utils';

export const PrivateRoute = ({ component: Component, authenticated, webSocketStore, roles, ...rest }) => {
  useEffect(() => {
    const isAuthorisedUser = getLoggedInUserObject();
    if (isAuthorisedUser) {
      // Connect WebSocket when component mounts
      webSocketStore.connectWebSocket();
    }
    // Disconnect WebSocket when component unmounts (tab close)
    window.addEventListener('beforeunload', () => {
      webSocketStore.disconnectWebSocket();
    });

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('beforeunload', () => {
        webSocketStore.disconnectWebSocket();
      });
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        const currentUser = getLoggedInUserObject() || getLoggedInGuestUserObject();
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const isiOS = /iPhone/.test(userAgent) && !window.MSStream;
        Honeybadger.notify('POC::IOS_DETECTION', {
          context: {
            isiOS: isiOS,
            userAgent: userAgent,
            MSStream: window.MSStream,
            opera: window.opera,
            vendor: navigator.vendor,
            file_name: 'private route page',
            location: window.location.href,
          },
        });
        if (isiOS && !AllowedIOSRoute.some((v) => window.location.href.includes(v))) {
          props.history.push({
            pathname: '/launcher',
            search: `?url=${document.URL.replace(/.*\/\/[^\/]*/, '')}`,
            state: { isMeetingPage: false, userType: 'internal' },
          });
        }
        if (!currentUser) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: '/login' }} />;
        }
        let allowed = false;
        let rolesArray = roles.split(',');
        // check if route is restricted by role
        rolesArray.forEach((role) => {
          if (role === currentUser.role) {
            allowed = true;
          }
        });
        if (!allowed) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/login' }} />;
        }

        // authorised so return component
        return <Component authenticated {...props} {...rest} />;
      }}
    />
  );
};
