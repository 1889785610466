// @flow

import {
    CLEAR_TIMEOUT,
    TIMEOUT_TICK,
    SET_TIMEOUT,
    timerWorkerScript
} from './TimeWorker';

/**
 * An enumeration of the different virtual background types.
 *
 * @enum {string}
 */
export const VIRTUAL_BACKGROUND_TYPE = {
    IMAGE: 'image',
    ADD_IMAGE: 'add-image',
    DESKTOP_SHARE: 'desktop-share',
    BLUR: 'blur',
    NONE: 'none'
};

export default class JitsiStreamBackgroundEffect {

       /**
     * Represents a modified video MediaStream track.
     *
     * @class
     * @param {Object} model - Meet model.
     * @param {Object} options - Segmentation dimensions.
     */
    constructor(model: Object, options: Object) {
        this._options = options;

        if (this._options.virtualBackground.backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
            || this._options.virtualBackground.backgroundType === VIRTUAL_BACKGROUND_TYPE.ADD_IMAGE) {
            this._virtualImage = document.createElement('img');
            this._virtualImage.crossOrigin = 'anonymous';
            this._virtualImage.src = this._options.virtualBackground.virtualSource;
        }
        if (this._options.virtualBackground.backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {
            this._virtualVideo = document.createElement('video');
            this._virtualVideo.autoplay = true;
            this._virtualVideo.srcObject = this._options.virtualBackground.virtualSource.stream;
        }
        this._model = model;
        this._segmentationPixelCount = this._options.width * this._options.height;
        // console.log('Benjamin this._options.width', this._options.width);

        // Bind event handler so it is only bound once for every instance.
        this._onMaskFrameTimer = this._onMaskFrameTimer.bind(this);

        // Workaround for FF issue https://bugzilla.mozilla.org/show_bug.cgi?id=1388974
        this._outputCanvasElement = document.createElement('canvas');
        this._outputCanvasElement.getContext('2d');
        this._inputVideoElement = document.createElement('video');
    }

    /**
     * EventHandler onmessage for the maskFrameTimerWorker WebWorker.
     *
     * @private
     * @param {EventHandler} response - The onmessage EventHandler parameter.
     * @returns {void}
     */
    _onMaskFrameTimer(response: Object) {
        if (response.data.id === TIMEOUT_TICK) {
            this._renderMask();
        }
    }


    /**
     * Represents the run post processing.
     *
     * @returns {void}
     */
    runPostProcessing() {
        const { backgroundType } = this._options.virtualBackground;
    
        // const track = this._stream.getVideoTracks()[0];
        // const { height, width } = track.getConstraints();
        // this._outputCanvasElement.height = height;
        // this._outputCanvasElement.width = width;
        // console.log('Benjamin height, width, backgroundType, this._options.virtualBackground.backgroundType',height, width, backgroundType, this._options.virtualBackground.backgroundType);
        // console.log(this._stream.getVideoTracks()[0]);

        this._outputCanvasCtx.globalCompositeOperation = 'copy';

        // Draw segmentation mask.

        // Smooth out the edges.
        if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
            || backgroundType === VIRTUAL_BACKGROUND_TYPE.ADD_IMAGE)
        {
            this._outputCanvasCtx.filter = 'blur(4px)';
        }
        else
        {
            this._outputCanvasCtx.filter = 'blur(8px)';
        }
        if (backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {
            // Save current context before applying transformations.
            this._outputCanvasCtx.save();

            // Flip the canvas and prevent mirror behaviour.
            this._outputCanvasCtx.scale(-1, 1);
            this._outputCanvasCtx.translate(-this._outputCanvasElement.width, 0);
        }
        this._outputCanvasCtx.drawImage(
            this._segmentationMaskCanvas,
            0,
            0,
            this._options.width,
            this._options.height,
            0,
            0,
            this._inputVideoElement.width,
            this._inputVideoElement.height
        );
        if (backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {
            this._outputCanvasCtx.restore();
        }
        this._outputCanvasCtx.globalCompositeOperation = 'source-in';
        this._outputCanvasCtx.filter = 'none';

        // Draw the foreground video.
        if (backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {
            // Save current context before applying transformations.
            this._outputCanvasCtx.save();

            // Flip the canvas and prevent mirror behaviour.
            this._outputCanvasCtx.scale(-1, 1);
            this._outputCanvasCtx.translate(-this._outputCanvasElement.width, 0);
        }
        this._outputCanvasCtx.drawImage(this._inputVideoElement, 0, 0);
        if (backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {
            this._outputCanvasCtx.restore();
        }

        // Draw the background.

        this._outputCanvasCtx.globalCompositeOperation = 'destination-over';
        if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
            || backgroundType === VIRTUAL_BACKGROUND_TYPE.ADD_IMAGE
            || backgroundType === VIRTUAL_BACKGROUND_TYPE.DESKTOP_SHARE) {

            let img;
            if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE
                || backgroundType === VIRTUAL_BACKGROUND_TYPE.ADD_IMAGE) {
                img = this._virtualImage;
            }
            else {
                img = this._virtualVideo;
            }

            this._outputCanvasCtx.drawImage(
                img,
                0,
                0,
                this._outputCanvasElement.width,
                this._outputCanvasElement.height
            );
        } else if (backgroundType !== VIRTUAL_BACKGROUND_TYPE.NONE) {
            this._outputCanvasCtx.filter = `blur(${this._options.virtualBackground.blurValue}px)`;
            this._outputCanvasCtx.drawImage(this._inputVideoElement, 0, 0);
        }
        else {
            this._outputCanvasCtx.drawImage(this._inputVideoElement, 0, 0);
        }
    }

    /**
     * Represents the run Tensorflow Interference.
     *
     * @returns {void}
     */
    runInference() {
        this._model._runInference();
        const outputMemoryOffset = this._model._getOutputMemoryOffset() / 4;

        for (let i = 0; i < this._segmentationPixelCount; i++) {
            const background = this._model.HEAPF32[outputMemoryOffset + (i * 2)];
            const person = this._model.HEAPF32[outputMemoryOffset + (i * 2) + 1];
            const shift = Math.max(background, person);
            const backgroundExp = Math.exp(background - shift);
            const personExp = Math.exp(person - shift);

            // Sets only the alpha component of each pixel.
            this._segmentationMask.data[(i * 4) + 3] = (255 * personExp) / (backgroundExp + personExp);
        }
        this._segmentationMaskCtx.putImageData(this._segmentationMask, 0, 0);
    }

    /**
     * Loop function to render the background mask.
     *
     * @private
     * @returns {void}
     */
    _renderMask() {
        this.resizeSource();
        this.runInference();
        this.runPostProcessing();

        this._maskFrameTimerWorker.postMessage({
            id: SET_TIMEOUT,
            timeMs: 1000 / 30
        });
    }

    /**
     * Represents the resize source process.
     *
     * @returns {void}
     */
    resizeSource() {
        this._segmentationMaskCtx.drawImage(
            this._inputVideoElement,
            0,
            0,
            this._inputVideoElement.width,
            this._inputVideoElement.height,
            0,
            0,
            this._options.width,
            this._options.height
        );

        const imageData = this._segmentationMaskCtx.getImageData(
            0,
            0,
            this._options.width,
            this._options.height
        );
        const inputMemoryOffset = this._model._getInputMemoryOffset() / 4;

        for (let i = 0; i < this._segmentationPixelCount; i++) {
            this._model.HEAPF32[inputMemoryOffset + (i * 3)] = imageData.data[i * 4] / 255;
            this._model.HEAPF32[inputMemoryOffset + (i * 3) + 1] = imageData.data[(i * 4) + 1] / 255;
            this._model.HEAPF32[inputMemoryOffset + (i * 3) + 2] = imageData.data[(i * 4) + 2] / 255;
        }
    }

    /**
     * Checks if the local track supports this effect.
     *
     * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
     * @returns {boolean} - Returns true if this effect can run on the specified track
     * false otherwise.
     */
    isEnabled(jitsiLocalTrack: Object) {
        return jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === 'camera';
    }

    /**
     * Starts loop to capture video frame and render the segmentation mask.
     *
     * @param {MediaStream} stream - Stream to be used for processing.
     * @returns {MediaStream} - The stream with the applied effect.
     */
    startEffect(stream: MediaStream) {
        this._stream = stream;
        this._maskFrameTimerWorker = new Worker(timerWorkerScript, { name: 'Blur effect worker' });
        this._maskFrameTimerWorker.onmessage = this._onMaskFrameTimer;
        const firstVideoTrack = this._stream.getVideoTracks()[0];
        const { height, frameRate, width }
            = firstVideoTrack.getSettings ? firstVideoTrack.getSettings() : firstVideoTrack.getConstraints();

        this._segmentationMask = new ImageData(this._options.width, this._options.height);
        this._segmentationMaskCanvas = document.createElement('canvas');
        this._segmentationMaskCanvas.width = this._options.width;
        this._segmentationMaskCanvas.height = this._options.height;
        this._segmentationMaskCtx = this._segmentationMaskCanvas.getContext('2d');

        this._outputCanvasElement.width = parseInt(width, 10);
        this._outputCanvasElement.height = parseInt(height, 10);
        this._outputCanvasCtx = this._outputCanvasElement.getContext('2d');
        this._inputVideoElement.width = parseInt(width, 10);
        this._inputVideoElement.height = parseInt(height, 10);
        this._inputVideoElement.autoplay = true;
        this._inputVideoElement.srcObject = this._stream;
        this._inputVideoElement.onloadeddata = () => {
            this._maskFrameTimerWorker.postMessage({
                id: SET_TIMEOUT,
                timeMs: 1000 / 30
            });
        };

        // console.log('Benjamin startEffect END frameRate',frameRate);
        // console.log('Benjamin startEffect this._outputCanvasElement.width height',this._outputCanvasElement.width, this._outputCanvasElement.height);
        // console.log('Benjamin startEffect this._inputVideoElement.width height',this._inputVideoElement.width, this._inputVideoElement.height);

        return this._outputCanvasElement.captureStream(parseInt(frameRate, 10));
        // return stream;
    }

    /**
     * Stops the capture and render loop.
     *
     * @returns {void}
     */
    stopEffect() {
        this._maskFrameTimerWorker.postMessage({
            id: CLEAR_TIMEOUT
        });

        this._maskFrameTimerWorker.terminate();
    }











    // constructor(model: Object, options: Object) {
    //     // const videoTrack = videoStream.getVideoTracks()[0];
    //     // const { height, width, frameRate } = videoTrack.getConstraints();
    //     // console.log("JitsiStreamEffect videoTrack.getConstraints(): ", videoTrack.getConstraints());
    // }

    // /**
    //  * Checks if the local track supports this effect.
    //  *
    //  * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
    //  * @returns {boolean} - Returns true if this effect can run on the
    //  * specified track, false otherwise.
    //  */
    // isEnabled(jitsiLocalTrack: Object) {
    //     console.log("JitsiStreamEffect isEnabled jitsiLocalTrack.isVideoTrack(): ", jitsiLocalTrack.isVideoTrack())
    //     console.log("JitsiStreamEffect isEnabled jitsiLocalTrack.videoType: ", jitsiLocalTrack.videoType)
    //     return jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === 'camera';
    // }

    // startEffect(videoStream: MediaStream) {
    //     const constraints = {
    //         width: {min: 640, max: 640, ideal: 640} ,
    //         height: {min: 480, max: 480, ideal: 480} 
    //     };
    //     const track = videoStream.getVideoTracks()[0];
    //     track.applyConstraints(constraints)
    //     return videoStream;
    // }

    // /**
    //  * Stops the capture and render loop.
    //  *
    //  * @returns {void}
    //  */
    // stopEffect() {
    // }

}

// /**
//  * Represents a modified MediaStream that adds video as pip on a desktop stream.
//  * <tt>JitsiStreamEffect</tt> does the processing of the original
//  * desktop stream.
//  */
// export default class JitsiStreamEffect {
//     _canvas: HTMLCanvasElement;
//     _ctx: CanvasRenderingContext2D;
//     _desktopElement: HTMLVideoElement;
//     _desktopStream: MediaStream;
//     _frameRate: number;
//     _onVideoFrameTimer: Function;
//     _onVideoFrameTimerWorker: Function;
//     _renderVideo: Function;
//     _videoFrameTimerWorker: Worker;
//     _videoElement: HTMLVideoElement;
//     isEnabled: Function;
//     startEffect: Function;
//     stopEffect: Function;

//     /**
//      * Represents a modified MediaStream that adds a camera track at the
//      * bottom right corner of the desktop track using a HTML canvas.
//      * <tt>JitsiStreamPresenterEffect</tt> does the processing of the original
//      * video stream.
//      *
//      * @param {MediaStream} videoStream - The video stream which is user for
//      * creating the canvas.
//      */
//     constructor(videoStream: MediaStream) {
//         const videoDiv = document.createElement('div');
//         const firstVideoTrack = videoStream.getVideoTracks()[0];
//         const { height, width, frameRate } = firstVideoTrack.getConstraints();

//         this._canvas = document.createElement('canvas');
//         this._ctx = this._canvas.getContext('2d');

//         this._desktopElement = document.createElement('video');
//         this._videoElement = document.createElement('video');
//         videoDiv.appendChild(this._videoElement);
//         videoDiv.appendChild(this._desktopElement);
//         if (document.body !== null) {
//             document.body.appendChild(videoDiv);
//         }

//         // Set the video element properties
//         this._frameRate = parseInt(frameRate, 10);
//         this._videoElement.width = parseInt(width, 10);
//         this._videoElement.height = parseInt(height, 10);
//         this._videoElement.autoplay = true;
//         this._videoElement.srcObject = videoStream;

//         // autoplay is not enough to start the video on Safari, it's fine to call play() on other platforms as well
//         this._videoElement.play();

//         // set the style attribute of the div to make it invisible
//         videoDiv.style.display = 'none';

//         // Bind event handler so it is only bound once for every instance.
//         this._onVideoFrameTimer = this._onVideoFrameTimer.bind(this);
//     }

//     /**
//      * EventHandler onmessage for the videoFrameTimerWorker WebWorker.
//      *
//      * @private
//      * @param {EventHandler} response - The onmessage EventHandler parameter.
//      * @returns {void}
//      */
//     _onVideoFrameTimer(response) {
//         if (response.data.id === INTERVAL_TIMEOUT) {
//             this._renderVideo();
//         }
//     }

//     /**
//      * Loop function to render the video frame input and draw presenter effect.
//      *
//      * @private
//      * @returns {void}
//      */
//     _renderVideo() {
//         // adjust the canvas width/height on every frame incase the window has been resized.
//         const [ track ] = this._desktopStream.getVideoTracks();
//         const { height, width } = track.getConstraints();

//         this._canvas.width = parseInt(width, 10);
//         this._canvas.height = parseInt(height, 10);
//         this._ctx.drawImage(this._desktopElement, 0, 0, this._canvas.width, this._canvas.height);
//         this._ctx.drawImage(this._videoElement, this._canvas.width - this._videoElement.width, this._canvas.height
//             - this._videoElement.height, this._videoElement.width, this._videoElement.height);

//         // draw a border around the video element.
//         this._ctx.beginPath();
//         this._ctx.lineWidth = 2;
//         this._ctx.strokeStyle = '#A9A9A9'; // dark grey
//         this._ctx.rect(this._canvas.width - this._videoElement.width, this._canvas.height - this._videoElement.height,
//             this._videoElement.width, this._videoElement.height);
//         this._ctx.stroke();
//     }

//     /**
//      * Checks if the local track supports this effect.
//      *
//      * @param {JitsiLocalTrack} jitsiLocalTrack - Track to apply effect.
//      * @returns {boolean} - Returns true if this effect can run on the
//      * specified track, false otherwise.
//      */
//     isEnabled(jitsiLocalTrack: Object) {
//         console.log("JitsiStreamEffect isEnabled jitsiLocalTrack.isVideoTrack():", jitsiLocalTrack.isVideoTrack())
//         console.log("JitsiStreamEffect isEnabled jitsiLocalTrack.videoType:", jitsiLocalTrack.videoType)
//         return jitsiLocalTrack.isVideoTrack() && jitsiLocalTrack.videoType === 'video';
//     }

//     /**
//      * Starts loop to capture video frame and render presenter effect.
//      *
//      * @param {MediaStream} desktopStream - Stream to be used for processing.
//      * @returns {MediaStream} - The stream with the applied effect.
//      */
//     startEffect(desktopStream: MediaStream) {
//         const firstVideoTrack = desktopStream.getVideoTracks()[0];
//         const { height, width } = firstVideoTrack.getConstraints();

//         // set the desktop element properties.
//         this._desktopStream = desktopStream;
//         this._desktopElement.width = parseInt(width, 10);
//         this._desktopElement.height = parseInt(height, 10);
//         this._desktopElement.autoplay = true;
//         this._desktopElement.srcObject = desktopStream;

//         // autoplay is not enough to start the video on Safari, it's fine to call play() on other platforms as well
//         this._desktopElement.play();

//         this._canvas.width = parseInt(width, 10);
//         this._canvas.height = parseInt(height, 10);
//         this._videoFrameTimerWorker = new Worker(timerWorkerScript, { name: 'Presenter effect worker' });
//         this._videoFrameTimerWorker.onmessage = this._onVideoFrameTimer;
//         this._videoFrameTimerWorker.postMessage({
//             id: SET_INTERVAL,
//             timeMs: 1000 / this._frameRate
//         });

//         return this._canvas.captureStream(this._frameRate);
//     }

//     /**
//      * Stops the capture and render loop.
//      *
//      * @returns {void}
//      */
//     stopEffect() {
//         this._videoFrameTimerWorker.postMessage({
//             id: CLEAR_INTERVAL
//         });
//         this._videoFrameTimerWorker.terminate();
//     }

// }
