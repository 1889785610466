import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import './index.css';
import ErrorComponent from './views/Pages/ErrorComponent/ErrorComponent';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Honeybadger from '@honeybadger-io/js';
import ErrorBoundary from '@honeybadger-io/react';
import JitsiConferenceProvider from './lib/jitsiManager/JitsiConference';
import makeInspectable from 'mobx-devtools-mst';
import UserStore from './helpers/stores/UserStore';
import FeatureLimitStore from './helpers/stores/FeatureLimitStore';
import WebSocketStore from './helpers/stores/WebSocketStore';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-toastify/dist/ReactToastify.css';

Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_ENVIRONMENT_NAME,
});
const store = new JitsiConferenceProvider();
const userStore = new UserStore();
const featureLimitStore = new FeatureLimitStore();
const webSocketStore = new WebSocketStore();

makeInspectable(store);

// askForPermissioToReceiveNotifications().then((token) => {
//   userStore.deviceToken = token;
// });
ReactDOM.render(
  <React.Fragment>
    <ErrorBoundary honeybadger={Honeybadger} ErrorComponent={<ErrorComponent userStore={userStore} />}>
      <ToastContainer />
      <App store={store} featureLimitStore={featureLimitStore} userStore={userStore} webSocketStore={webSocketStore} />
    </ErrorBoundary>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
