import { action, makeObservable, observable } from 'mobx';
import Stomp from 'stompjs';
import { getLoggedInUserObject } from '../utils';

export default class WebSocketStore {
  constructor() {
    makeObservable(this, {
      stompClient: observable,
      disconnectWebSocket: action,
      sendStatusUpdate: action,
      connectWebSocket: action,
      websocketReadyState: observable, // Add observable for WebSocket ready state
    });
  }

  stompClient = null;
  heartbeatInterval = null;
  retryCount = 0;
  maxRetries = 4;

  websocketReadyState = WebSocket.CONNECTING; // Initialize WebSocket ready state to CONNECTING

  reset = () => {
    this.stompClient = null;
  };

  // Getter method for WebSocket ready state
  getWebSocketReadyState() {
    return this.websocketReadyState;
  }

  sendStatusUpdate = (status) => {
    if (!this.stompClient || this.websocketReadyState !== WebSocket.OPEN) {
      // If not connected, establish connection first
      this.connectWebSocket();
    }

    // After ensuring connection, send the status update
    if (this.stompClient && this.websocketReadyState === WebSocket.OPEN) {
      const internalUserDetails = getLoggedInUserObject();
      const message = {
        userUid: internalUserDetails && internalUserDetails.userUid,
        accountId: internalUserDetails && internalUserDetails.accountId,
        status: status,
      };
      this.stompClient.send('/app/broadcast', {}, JSON.stringify(message));
    }
  };

  disconnectWebSocket = () => {
    if (this.stompClient) {
      clearInterval(this.heartbeatInterval);
      this.sendStatusUpdate(0);
      this.stompClient.disconnect();
      this.stompClient = null;
      console.log('Disconnected from WebSocket server');
      this.websocketReadyState = WebSocket.CLOSED; // Set WebSocket ready state to CLOSED
    }
  };

  connectWebSocket = () => {
    console.log('called connected');
    console.log('this.stompClient :>> ', this.stompClient);
    console.log('this.websocketReadyState :>> ', this.websocketReadyState);
    console.log('this.retryCount  :>> ', this.retryCount);
    if (!this.stompClient && this.websocketReadyState !== WebSocket.OPEN && this.retryCount < this.maxRetries) {
      console.log('goes inside connect');
      const url = `${process.env.REACT_APP_STOMP_WEBSOCKET_BASE_URL}/broadcast`;
      const socket = new WebSocket(url);
      const client = Stomp.over(socket);
      client.connect(
        {},
        () => {
          console.log('Connected to WebSocket server');
          this.stompClient = client;
          this.websocketReadyState = WebSocket.OPEN; // Set WebSocket ready state to OPEN

          this.startHeartbeat();
          this.sendStatusUpdate(1);

          // Subscribe to a topic to receive server responses
          this.stompClient.subscribe('/app/broadcast', (response) => {
            // Handle the response here
            console.log('Received server response:', response.body);
          });

          // Update websocketReadyState when WebSocket is closed
          this.stompClient.ws.onclose = () => {
            this.websocketReadyState = WebSocket.CLOSED;
            this.retryCount = 0;
            this.stompClient = null;
            console.log('WebSocket connection closed.');
          };
        },
        (error) => {
          console.error('Error connecting to WebSocket server:', error);
          // Increment the retry count
          this.retryCount++;
          // Retry connecting after a delay (e.g., 5 seconds)
          setTimeout(this.connectWebSocket, 10000);
        }
      );
    } else {
      console.log('Max retries exceeded. Could not connect to WebSocket server.');
    }
  };

  startHeartbeat = () => {
    if (!this.stompClient || this.websocketReadyState !== WebSocket.OPEN) {
      // If not connected, establish connection first
      this.connectWebSocket();
    }
    console.log('this.heartbeatInterval :>> ', this.heartbeatInterval);
    // After ensuring connection, start sending heartbeat messages
    this.heartbeatInterval = setInterval(() => {
      if (this.stompClient && this.websocketReadyState === WebSocket.OPEN) {
        console.log('this.websocketReadyState :>> ', this.websocketReadyState);
        console.log('WebSocket.OPEN :>> ', WebSocket.OPEN);
        console.log('this.heartbeatInterval interval:>> ', this.heartbeatInterval);
        const internalUserDetails = getLoggedInUserObject();
        const message = {
          userUid: internalUserDetails && internalUserDetails.userUid,
          accountId: internalUserDetails && internalUserDetails.accountId,
          status: 1,
        };
        this.stompClient.send('/app/heartbeat', {}, JSON.stringify(message));
      }
    }, 30000);
  };
}
