import { createContext } from "react";


// this is the equivalent to the createStore method of Redux


const ProfileContext = createContext();


// creating Provider and Consumer and exporting them.

export const ProfileProvider = ProfileContext.Provider

export const ProfileConsumer = ProfileContext.Consumer


export default ProfileContext;