import axios from 'axios';
import axiosRetry from 'axios-retry';
import firebaseconfig from '../firebaseconfig';
import {
  createMeetingUrl,
  getChatUrl,
  getUserByUidUrl,
  GET_ACCESS_TOKEN_URL,
  postChatUrl,
  searchUsersUrl,
  updateMeetingUrl,
  setChatFlagUrl,
  inviteUserURL,
  getMeetingByIdUrl,
  logEventUrl,
  getMergeTranscriptUrl,
  externalUserAuthenticationUrl,
  sendTranscriptToExternalUserUrl,
  sendInviteToRegisterUrl,
  searchMeetingsUrl,
  createUserUrl,
  getAllUsersUrl,
  updateUserActiveStatusUrl,
  resetPasswordUrl,
  getUserByEmailUrl,
  editProfileUrl,
  editUserEmailUrl,
  createTeamUrl,
  searchTeamsUrl,
  getTeamByIdUrl,
  getAllTeamsUrl,
  getAllAccessibleTeamsUrl,
  editTeamUrl,
  searchChildUrl,
  cancelMeetingUrl,
  searchContactsUrl,
  searchOnlyContactsUrl,
  createContactUrl,
  getAllContactsUrl,
  deleteContactUrl,
  updateContactUrl,
  getDocumentListUrl,
  deleteDocumentByIdUrl,
  addDocumentUrl,
  addFilesUrl,
  saveProfilePictureUrl,
  getProfilePictureUrl,
  signedUrlApiEndpoint,
  updateAccountName,
  getallConversationFlagUrl,
  createConversationFlagUrl,
  updateConversationFlagUrl,
  deleteConversationFlagUrl,
  deleteTeamUrl,
  getGuestUserNameUrl,
  resendEmailUrl,
  getMeetingByDateUrl,
  quickCallStatusUrl,
  checkUsersStatusUrl,
  callActionUrl,
  getViewTranscriptUrl,
  reviewFeedbackUrl,
  saveMeetingNotesUrl,
  getNotesForMeetingUrl,
  getMeetingByDateRangeUrl,
  getRemainingFeatureLimitUrl,
  disconnectCallUrl,
  getAllGroupUrl,
  searchGroupsUrl,
  getAllUsersAndContactsUrl,
  postCreateGroupUrl,
  getGroupDetailsUrl,
  updateGroupDetailsUrl,
  deleteGroupUrl,
  getFullTranscriptAudioUrl,
  getWebinarTokenUrl,
  registerWebinarUrl,
  getSummarizationUrl,
  getActionItemsUrl,
  getPinnedActionItemsUrl,
  postTranslateUrl,
  getTranscriptPromptUrl,
  leaveAccountUrl,
  getPromptHistoryUrl,
  postUserActionItemsUrl,
  getUserActionItemsUrl,
  getMeetingShareLinkUrl,
  getRecurringMeetingByIdUrl,
  updateRecurringMeetingUrl,
  deleteRecurringMeetingUrl,
  globalNlpPromptUrl,
} from '../urls';
import { forceClearSession, getLoggedInUserObject } from '../helpers/utils';

axiosRetry(axios, { retries: 3 });

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorResponse = error.response;

    const internalUserDetails = JSON.parse(localStorage.getItem('memoriaUser')) || null;
    const guestUserDetails = JSON.parse(sessionStorage.getItem('guestUser')) || null;
    const isGuestUser = guestUserDetails && guestUserDetails !== null ? true : false;
    if (isTokenExpiredError(errorResponse)) {
      let body = {
        uuid: isGuestUser ? guestUserDetails && guestUserDetails.guestUserId : internalUserDetails && internalUserDetails.userUid,
        email: isGuestUser ? guestUserDetails && guestUserDetails.email : internalUserDetails && internalUserDetails.email,
        role: isGuestUser ? guestUserDetails && guestUserDetails.role : internalUserDetails && internalUserDetails.role,
        accountId: isGuestUser ? sessionStorage.getItem('guestAccountId') : localStorage.getItem('accountId'),
      };
      return await getAccessToken(GET_ACCESS_TOKEN_URL, body)
        .then((response) => {
          isGuestUser
            ? sessionStorage.setItem('guestAuthorization', response.data.token)
            : localStorage.setItem('authorization', response.data.token);
          error.config.headers.authorization = isGuestUser
            ? sessionStorage.getItem('guestAuthorization')
            : localStorage.getItem('authorization');
          return axios(errorResponse.config);
        })
        .catch(() => {
          firebaseconfig.auth().signOut();
          localStorage.clear();
        });
    }
    if (isUnAuthorized()) {
      window.location.href = process.env.REACT_APP_MEMORIA_ORIGIN;
    }
    return Promise.reject(error);
  }
);

let getHeaders = () => {
  if (!isTokenAvailable() || !isAccountIdValid()) {
    forceClearSession();
  }

  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    accountId: sessionStorage.getItem('isGuestUser') === '1' ? sessionStorage.getItem('guestAccountId') : localStorage.getItem('accountId'),
    authorization:
      sessionStorage.getItem('isGuestUser') === '1' ? sessionStorage.getItem('guestAuthorization') : localStorage.getItem('authorization'),
  };
};

let getFormDataHeaders = () => {
  if (!isTokenAvailable() || !isAccountIdValid()) {
    forceClearSession();
  }

  return {
    'Content-Type': 'multipart/form-data',
    // Accept: 'application/json',
    accountId: sessionStorage.getItem('isGuestUser') === '1' ? sessionStorage.getItem('guestAccountId') : localStorage.getItem('accountId'),
    authorization:
      sessionStorage.getItem('isGuestUser') === '1' ? sessionStorage.getItem('guestAuthorization') : localStorage.getItem('authorization'),
  };
};

export const getAccountId = () =>
  sessionStorage.getItem('guestAccountId')
    ? sessionStorage.getItem('guestAccountId').trim()
    : localStorage.getItem('accountId')
    ? localStorage.getItem('accountId').trim()
    : localStorage.clear();

const isTokenExpiredError = (errorResponse) => {
  return errorResponse && errorResponse.status === 401 ? true : false;
};

export const removeGuestUserAuthorise = () => {
  sessionStorage.setItem('isGuestUser', '0');
  sessionStorage.removeItem('guestAuthorization');
  sessionStorage.removeItem('guest-x-authorization');
  sessionStorage.removeItem('guestUser');
  sessionStorage.removeItem('guestAccountId');
  sessionStorage.removeItem('guestAccountName');
};

const isUnAuthorized = (errorResponse) => {
  return errorResponse && errorResponse.status === 403 ? true : false;
};

const isTokenAvailable = () =>
  (sessionStorage.getItem('guestAuthorization') || localStorage.getItem('authorization')) &&
  (sessionStorage.getItem('guestAuthorization') !== 'undefined' || localStorage.getItem('authorization') !== 'undefined');

const isAccountIdValid = () =>
  (sessionStorage.getItem('guestAccountId') || localStorage.getItem('accountId')) &&
  (sessionStorage.getItem('guestAccountId') !== 'undefined' || localStorage.getItem('accountId') !== 'undefined');

const getAccessToken = (url, body) => {
  return axios.post(url, body);
};

export const getMeetings = async (url) => (await axios.get(url, { headers: getHeaders() })).data;

export const searchMeetings = async (query) =>
  (
    await axios.get(searchMeetingsUrl(getAccountId(), query), {
      headers: getHeaders(),
    })
  ).data;

export const getMergeTranscription = async (meetingId) =>
  await axios.get(getMergeTranscriptUrl(getAccountId(), meetingId), {
    headers: getHeaders(),
  });
export const getViewTranscription = async (meetingId) =>
  await axios.get(getViewTranscriptUrl(getAccountId(), meetingId), {
    headers: getHeaders(),
  });
export const getFullTranscriptAudio = async (meetingId) =>
  await axios.get(getFullTranscriptAudioUrl(getAccountId(), meetingId), {
    headers: getHeaders(),
  });
export const getSummarization = async (meetingId, queryParams) =>
  await axios.get(getSummarizationUrl(getAccountId(), meetingId, queryParams), {
    headers: getHeaders(),
  });

export const getPromptHistory = async (meetingId, userId) =>
  await axios.get(getPromptHistoryUrl(getAccountId(), meetingId, userId), {
    headers: getHeaders(),
  });

export const getTranscriptPrompt = async (meetingId, payload) =>
  await axios.post(getTranscriptPromptUrl(getAccountId(), meetingId), payload, {
    headers: getHeaders(),
  });

export const postTranslate = async (meetingId, payload) =>
  await axios.post(postTranslateUrl(getAccountId(), meetingId), payload, {
    headers: getHeaders(),
  });

export const updateSummarization = async (meetingId, payload) =>
  await axios.put(getSummarizationUrl(getAccountId(), meetingId), payload, {
    headers: getHeaders(),
  });

export const getUserActionItems = async () =>
  await axios.get(getPinnedActionItemsUrl(getAccountId()), {
    headers: getHeaders(),
  });

export const putUserActionItems = async (payload) =>
  await axios.put(getUserActionItemsUrl(getAccountId()), payload, {
    headers: getHeaders(),
  });

export const getMeetingShareLink = async (meetingId) =>
  await axios.get(getMeetingShareLinkUrl(getAccountId(), meetingId), { headers: getHeaders() });

export const postUserActionItem = async (payload) =>
  await axios.post(postUserActionItemsUrl(getAccountId()), payload, {
    headers: getHeaders(),
  });

export const updateActionItems = async (meetinId, payload) =>
  await axios.put(getActionItemsUrl(getAccountId(), meetinId), payload, {
    headers: getHeaders(),
  });

export const getDocumentList = async (meetingId) =>
  await axios.post(getDocumentListUrl(getAccountId(), meetingId), null, {
    headers: getHeaders(),
  });

export const addDocument = async (body, meetingId) =>
  await axios.post(addDocumentUrl(getAccountId(), meetingId), JSON.stringify(body), {
    headers: getHeaders(),
  });

export const deleteDocumentById = async (Id) =>
  await axios.post(deleteDocumentByIdUrl(getAccountId(), Id), null, {
    headers: getHeaders(),
  });

export const createMeeting = async (body, callBack) => {
  return await axios.post(createMeetingUrl(getAccountId()), body, {
    headers: getFormDataHeaders(),
    onUploadProgress: function progressEvent(progress) {
      callBack && callBack(Number(Math.round((progress.loaded / progress.total) * 100)));
    },
  });
};

export const addFiles = async (formData, meetingId, fileSource, callBack) => {
  return await axios.post(addFilesUrl(getAccountId(), meetingId, fileSource), formData, {
    headers: getFormDataHeaders(),
    onUploadProgress: function progressEvent(progress) {
      callBack(Number(Math.round((progress.loaded / progress.total) * 100)));
    },
  });
};

export const saveProfilePicture = async (formData, emailID) => {
  return await axios.post(saveProfilePictureUrl(getAccountId(), emailID), formData, { headers: getFormDataHeaders() });
};

export const getProfilePicture = async () => {
  try {
    return await axios.post(getProfilePictureUrl(getAccountId()), '', {
      headers: getHeaders(),
    });
  } catch (error) {
    console.log('error while getting profile picture, cause: ' + error);
    return '';
  }
};

export const createTeam = async (body) => {
  return await axios.post(createTeamUrl(getAccountId()), JSON.stringify(body), { headers: getHeaders() });
};

export const editTeam = async (body, groupId) => {
  return await axios.put(editTeamUrl(getAccountId()) + groupId, JSON.stringify(body), { headers: getHeaders() });
};

export const createGroup = async (body) => {
  return await axios.post(postCreateGroupUrl(getAccountId()), JSON.stringify(body), { headers: getHeaders() });
};

export const deleteGroup = async (groupId) => {
  return await axios.delete(deleteGroupUrl(getAccountId(), groupId), { headers: getHeaders() });
};

export const getAllGroup = async (body) => {
  return (
    await axios.get(getAllGroupUrl(getAccountId()), {
      headers: getHeaders(),
    })
  ).data;
};

export const getGroupDetails = async (groupId) => {
  return (
    await axios.get(getGroupDetailsUrl(getAccountId(), groupId), {
      headers: getHeaders(),
    })
  ).data;
};

export const updateGroupDetails = async (groupId, payload) => {
  return (
    await axios.put(updateGroupDetailsUrl(getAccountId(), groupId), JSON.stringify(payload), {
      headers: getHeaders(),
    })
  ).data;
};

export const getMeetingById = async (meetingId) => {
  return (
    await axios
      .get(getMeetingByIdUrl(getAccountId()) + meetingId, {
        headers: getHeaders(),
      })
      .then((response) => (response.status === 200 ? response : null))
  ).data;
};

export const getRecurringMeetingById = async (meetingId, recurringMeetingId) => {
  return (
    await axios
      .get(getRecurringMeetingByIdUrl(getAccountId(), meetingId, recurringMeetingId), {
        headers: getHeaders(),
      })
      .then((response) => (response.status === 200 ? response : null))
  ).data;
};

export const getTeamById = async (groupId) => {
  return (
    await axios.get(getTeamByIdUrl(getAccountId()) + groupId, {
      headers: getHeaders(),
    })
  ).data;
};

export const searchUsers = async (query) => {
  return (
    await axios.get(searchUsersUrl(getAccountId()) + query.toLowerCase(), {
      headers: getHeaders(),
    })
  ).data;
};

// search contacts will search participants, contacts
export const searchContacts = async (query) => {
  return (
    await axios.get(searchContactsUrl(getAccountId()) + query.toLowerCase(), {
      headers: getHeaders(),
    })
  ).data;
};

// search only contacts will search only contacts
export const searchOnlyContacts = async (query) => {
  return (
    await axios.get(searchOnlyContactsUrl(getAccountId()) + query.toLowerCase(), {
      headers: getHeaders(),
    })
  ).data;
};

export const getGuestUserName = async (email) => {
  return (
    await axios.get(getGuestUserNameUrl(getAccountId(), email), {
      headers: getHeaders(),
    })
  ).data;
};

export const searchTeams = async (query) => {
  return (
    await axios.get(searchTeamsUrl(getAccountId()) + query + '?hasAccess=false', {
      headers: getHeaders(),
    })
  ).data;
};

export const searchAccesibleTeams = async (query) => {
  return (
    await axios.get(
      searchTeamsUrl(getAccountId()) + query + `?hasAccess=${getLoggedInUserObject().role === 'ROLE_ADMIN' ? 'false' : 'true'}`,
      { headers: getHeaders() }
    )
  ).data;
};

export const getAllUsersAndContacts = async () => {
  return (await axios.get(getAllUsersAndContactsUrl(getAccountId()), { headers: getHeaders() })).data;
};

export const searchGroups = async (query) => {
  return (await axios.get(searchGroupsUrl(getAccountId()) + query + '?hasAccess=false', { headers: getHeaders() })).data;
};

export const searchChild = async (query) => {
  return (
    await axios.get(searchChildUrl(getAccountId()) + query, {
      headers: getHeaders(),
    })
  ).data;
};

export const getUserByUid = async (userUid) => {
  return (
    await axios.get(getUserByUidUrl(getAccountId()) + userUid, {
      headers: getHeaders(),
    })
  ).data;
};

export const updateMeeting = async (body, meetingId, callBack) => {
  return await axios.put(updateMeetingUrl(getAccountId()) + meetingId, JSON.stringify(body), {
    headers: getHeaders(),
    onUploadProgress: function progressEvent(progress) {
      if (callBack) {
        callBack(Number(Math.round((progress.loaded / progress.total) * 100)));
      }
    },
  });
};

export const updateRecurringMeeting = async (body, meetingId, recurringMeetingId, callBack) => {
  return await axios.post(updateRecurringMeetingUrl(getAccountId(), meetingId, recurringMeetingId), body, {
    headers: getFormDataHeaders(),
    onUploadProgress: function progressEvent(progress) {
      if (callBack) {
        callBack(Number(Math.round((progress.loaded / progress.total) * 100)));
      }
    },
  });
};

export const deleteRecurringMeeting = async (meetingId, recurringMeetingId) => {
  return await axios.put(deleteRecurringMeetingUrl(getAccountId(), meetingId, recurringMeetingId), {}, { headers: getHeaders() });
};

/**
 * Send chat message to server to store
 * @param {Object} body Chat-Object
 * @param {number} meetingId
 * @returns {Object} Meeting-Object
 */
export const postChat = async (body, meetingId) => {
  return await axios.post(postChatUrl(getAccountId(), meetingId), JSON.stringify(body), { headers: getHeaders() });
};

/**
 * Get chat by meetingId
 * @param {number} meetingId
 */
export const getChat = async (meetingId) => {
  return (
    await axios.get(getChatUrl(getAccountId(), meetingId), {
      headers: getHeaders(),
    })
  ).data;
};

/**
 * Setting hasChat flag to {true} on first chat message of a meeting
 * @param {number} meetingId
 * @returns {Object} Meeting
 */
export const setChatFlag = async (meetingId) => {
  return await axios.patch(setChatFlagUrl(getAccountId(), meetingId), null, {
    headers: getHeaders(),
  });
};
/**
 * @param {number} meetingId
 * @param {Object} body
 * @returns {Object} Updated Meeting Object
 */
export const inviteUser = async (meetingId, body) => {
  return (await axios.post(inviteUserURL(getAccountId(), meetingId), JSON.stringify(body), { headers: getHeaders() })).data;
};

export const resendInviteUser = async (meetingId, body) => {
  return (await axios.post(resendEmailUrl(getAccountId(), meetingId), JSON.stringify(body), { headers: getHeaders() })).data;
};

export const logEventApi = (meetingId, body) => {
  axios.post(logEventUrl(getAccountId(), meetingId), body, {
    headers: getHeaders(),
  });
};

export const externalUserAuthentication = async (token, accountId) => {
  return await axios.post(externalUserAuthenticationUrl(token, accountId));
};

export const sendTranscriptToExternalUser = async (meetingId, body) => {
  return await axios.post(sendTranscriptToExternalUserUrl(getAccountId(), meetingId), body, { headers: getHeaders() });
};

export const sendEmailToRegister = async (emailId, isMemoriaUser) => {
  return (
    await axios.get(sendInviteToRegisterUrl(getAccountId(), emailId, isMemoriaUser), {
      headers: getHeaders(),
    })
  ).data;
};

export const createUser = async (body) => {
  return axios.post(createUserUrl(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const getAllUsers = async () => {
  return (await axios.get(getAllUsersUrl(getAccountId()), { headers: getHeaders() })).data;
};

export const createContact = async (body) => {
  return axios.post(createContactUrl(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const updateContact = (body) => {
  return axios.patch(updateContactUrl(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const getAllContacts = async () => {
  return (
    await axios.get(getAllContactsUrl(getAccountId()), {
      headers: getHeaders(),
    })
  ).data;
};

export const deleteContact = (body) => {
  return axios.patch(deleteContactUrl(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const deleteTeam = (teamID) => {
  return axios.patch(deleteTeamUrl(getAccountId(), teamID), null, {
    headers: getHeaders(),
  });
};

export const getAllTeams = async () => {
  return (await axios.get(getAllTeamsUrl(getAccountId()), { headers: getHeaders() })).data;
};

export const getAllAccessibleTeams = async () => {
  return (
    await axios.get(getAllAccessibleTeamsUrl(getAccountId()), {
      headers: getHeaders(),
    })
  ).data;
};

export const updateUserActiveStatus = (body) => {
  return axios.patch(updateUserActiveStatusUrl(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const checkUserStatusForQuickCall = (payload) => {
  return axios.post(checkUsersStatusUrl(getAccountId()), payload, { headers: getHeaders() });
};

export const leaveAccount = (payload) => {
  return axios.post(leaveAccountUrl(getAccountId()), payload, { headers: getHeaders() });
};

export const resetPassword = (body) => {
  return axios.patch(resetPasswordUrl(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const getUserByToken = async () =>
  (
    await axios.get(getUserByEmailUrl(getAccountId()), {
      headers: getHeaders(),
    })
  ).data;

export const editProfile = async (body) =>
  (
    await axios.patch(editProfileUrl(getAccountId()), body, {
      headers: getHeaders(),
    })
  ).data;

export const updateAccount = async (body) => {
  return axios.patch(updateAccountName(getAccountId()), body, {
    headers: getHeaders(),
  });
};
export const editUserEmail = async (body) =>
  (
    await axios.patch(editUserEmailUrl(getAccountId()), body, {
      headers: getHeaders(),
    })
  ).data;

export const cancelMeeting = async (meetingId) => {
  return await axios.put(cancelMeetingUrl(getAccountId(), meetingId), null, {
    headers: getHeaders(),
  });
};

export const getSignedUrl = async (body) => {
  return axios.post(signedUrlApiEndpoint(getAccountId()), body, {
    headers: getHeaders(),
  });
};

export const createConversationFlag = async (body, meetingId) => {
  return axios.post(createConversationFlagUrl(getAccountId(), meetingId), body, {
    headers: getHeaders(),
  });
};
export const getallConversationFlag = async (meetingId) => {
  return (
    await axios.get(getallConversationFlagUrl(getAccountId(), meetingId), {
      headers: getHeaders(),
    })
  ).data;
};
export const updateConversationFlag = async (body, meetingId, flagId) =>
  (
    await axios.patch(updateConversationFlagUrl(getAccountId(), meetingId, flagId), body, {
      headers: getHeaders(),
    })
  ).data;
export const deleteConversationFlag = async (meetingId, flagId) =>
  (
    await axios.delete(deleteConversationFlagUrl(getAccountId(), meetingId, flagId), {
      headers: getHeaders(),
    })
  ).data;
export const getMeetingByDate = async () =>
  (
    await axios.get(getMeetingByDateUrl(getAccountId()), {
      headers: getHeaders(),
    })
  ).data;
export const quickCallStatus = async (body) =>
  (
    await axios.post(quickCallStatusUrl(getAccountId()), body, {
      headers: getHeaders(),
    })
  ).data;
export const guestQuickCallStatus = async (header, accountd, body) =>
  (
    await axios.post(quickCallStatusUrl(accountd), body, {
      headers: header,
    })
  ).data;

export const postGlobalNlpPrompt = async (payload) =>
  await axios.post(globalNlpPromptUrl(), payload, {
    headers: getHeaders(),
  });

export const callAction = async (body) =>
  (
    await axios.post(callActionUrl(getAccountId()), body, {
      headers: getHeaders(),
    })
  ).data;
export const guestCallAction = async (header, accountId, body) =>
  (
    await axios.post(callActionUrl(accountId), body, {
      headers: header,
    })
  ).data;

export const reviewFeedbackMeeting = async (meetingId, body) => {
  return await axios.put(reviewFeedbackUrl(getAccountId(), meetingId), body, {
    headers: getHeaders(),
  });
};

export const saveMeetingNotes = async (body, meetingId) => {
  return await axios.put(saveMeetingNotesUrl(getAccountId(), meetingId), JSON.stringify(body), { headers: getHeaders() });
};

export const updateMeetingNotes = async (body, meetingId) => {
  return await axios.patch(saveMeetingNotesUrl(getAccountId(), meetingId), JSON.stringify(body), { headers: getHeaders() });
};

export const getNotesForMeeting = async (userId, meetingId) => {
  return await await axios.get(getNotesForMeetingUrl(getAccountId(), meetingId, userId), { headers: getHeaders() });
};

export const getMeetingByDateRange = async (startDate, endDate) =>
  (
    await axios.get(getMeetingByDateRangeUrl(getAccountId(), startDate, endDate), {
      headers: getHeaders(),
    })
  ).data;
export const getRemainingFeatureLimit = async () =>
  (
    await axios.get(getRemainingFeatureLimitUrl(getAccountId()), {
      headers: getHeaders(),
    })
  ).data;

export const disconnetCall = async (body) =>
  (
    await axios.post(disconnectCallUrl(getAccountId()), body, {
      headers: getHeaders(),
    })
  ).data;

// webinar
export const getWebinarUserToken = (payload) => axios.post(getWebinarTokenUrl(), payload);

export const getWebinarMeetingDetails = (accountId, meetingId) =>
  axios.get(getMeetingByIdUrl(accountId) + meetingId, {
    headers: {
      authorization: localStorage.getItem('webinarUserToken'),
    },
  });

export const registerWebinar = (accountId, meetingId, payload, webToken) =>
  axios.post(registerWebinarUrl(accountId, meetingId), payload, {
    headers: {
      accountId: accountId,
      authorization: webToken,
    },
  });

export const startSSEAudioRecording = (meetingId) =>
  axios.post(`${process.env.REACT_APP_SSE_BASE_URL}/sse/v1/start-recording/${meetingId}/audio`, {}, { headers: getHeaders() });

export const stopSSEAudioRecording = (meetingId) =>
  axios.post(`${process.env.REACT_APP_SSE_BASE_URL}/sse/v1/stop-recording/${meetingId}/audio`, {}, { headers: getHeaders() });

export const startSSEVideoRecording = (meetingId) =>
  axios.post(`${process.env.REACT_APP_SSE_BASE_URL}/sse/v1/start-recording/${meetingId}/video`, {}, { headers: getHeaders() });

export const stopSSEVideoRecording = (meetingId) =>
  axios.post(`${process.env.REACT_APP_SSE_BASE_URL}/sse/v1/stop-recording/${meetingId}/video`, {}, { headers: getHeaders() });

export const bulkContactUpload = (payload, callBack) =>
  axios.put(`${process.env.REACT_APP_API_SERVER_BASE_URL}/api/accounts/${getAccountId()}/users/uploadContact`, payload, {
    headers: getFormDataHeaders(),
    onUploadProgress: function progressEvent(progress) {
      callBack && callBack(Number(Math.round((progress.loaded / progress.total) * 100)));
    },
  });
