/* eslint-disable no-useless-escape */
import { detect } from 'detect-browser';
import firebaseconfig from '../firebaseconfig';
import { getAllUserAccounts, updateDeviceToken } from '../services/LoginService';
import { getRemainingFeatureLimit, getUserByUid } from '../services/MeetingService';
import Cookies from 'universal-cookie';

/**
 * @author Nikhil
 */

const cookies = new Cookies();

export const shortenString = (string, maxLength) =>
  string && string.length <= maxLength ? string : string && string.substring(0, maxLength - 2) + '..';

export const isEmailValid = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
    ? true
    : false;

export const getCurrentUsersFullNameOrEmail = () => {
  const currentUser = getLoggedInUserObject();
  return currentUser
    ? currentUser.firstName && currentUser.lastName
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : currentUser.email
    : 'Unknown User';
};
export const fileMaxSize = () => 78643200; //75MB
export const getLoggedInUserObject = () => {
  let userobject;
  try {
    userobject = JSON.parse(localStorage.getItem('memoriaUser'));
  } catch (error) {
    console.log('error', error);
    forceClearSession();
  }
  return userobject;
};
export const getLoggedInGuestUserObject = () => {
  let userobject;
  try {
    userobject = JSON.parse(sessionStorage.getItem('guestUser'));
  } catch (error) {
    console.log('error', error);
    forceClearSession();
  }
  return userobject;
};

export const forceClearSession = async () => {
  try {
    window.Intercom('shutdown');
    localStorage.clear();
    await firebaseconfig.auth().signOut();
    window.location.href = process.env.REACT_APP_MEMORIA_ORIGIN;
  } catch (error) {
    console.log('error', error);
    alert(error);
  }
};

export const isMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
};

export const isBrowserFirefox = () => {
  if (detect().name === 'firefox') return true;
  return false;
};

export const isBrowserChrome = () => {
  if (detect().name === 'chrome') return true;
  return false;
};

export const isBrowserEdge = () => {
  if (detect().name === 'edge-chromium') return true;
  return false;
};

export const isMobileSafari = () => {
  var ua = navigator.userAgent.toLowerCase();
  let safari;
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      safari = false;
    } else {
      safari = true;
    }
  }
  return safari;
};

export const isBrowserSafari = () => {
  if (detect().name === 'safari') return true;
  return false;
};

export const isAndroidMobile = navigator.userAgent.match(/Android/i) && navigator.userAgent.match(/Mobile/i);
export const isAndroidTablet = navigator.userAgent.match(/Android/i) && !navigator.userAgent.match(/Mobile/i);
export const isiOSMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent) && !window.MSStream;
export const isiOSTablet = /iPad/i.test(navigator.userAgent) && !window.MSStream;
export const isIOSTAB = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const blockSpacebarKey = (event) => {
  if (event.keyCode === 32) event.preventDefault();
};
export const OSName = () => {
  return detect().os;
};
export const getRandomColorHex = () => {
  let letters = '0123456789ABCDEF';
  let color = '';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const updateUserToken = async (deviceToken, isFromSignOut = false) => {
  let userobject;
  userobject = JSON.parse(localStorage.getItem('memoriaUser'));
  if (userobject) {
    let deviceBody = {
      userUid: userobject.userUid,
      emailAddress: userobject.email,
      deviceToken_web: deviceToken,
      deleteToken: isFromSignOut,
      firstName: userobject.firstName,
      lastName: userobject.lastName,
    };
    await updateDeviceToken(deviceBody);
  }
};
export const QuickCallStatus = {
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  NOANSWER: 'NoAnswer',
  DECLINED: 'Decline',
  PICKUP: 'Pickup',
  DISCONNECT: 'Disconnect',
  REQUEST: 'QUICKCALL_REQUEST',
  INITIATE: 'Initiate',
  JOINED: 'Joined',
};
export const getAvatar = (uid) => {
  return getUserByUid(uid)
    .then((user) => user.avatar_url)
    .catch((error) => undefined);
};
export const userPresence = (userStore, user) => {
  //-*-Update Online/Offline in REALTIMEDATABASE-*-*-*-*

  var userStatusDatabaseRef = firebaseconfig.database().ref(`users/${userStore.userobject.accountId}/${user.uid}`);

  var isOfflineForDatabase = {
    status: 'offline',
    last_changed: firebaseconfig.database.ServerValue.TIMESTAMP,
  };
  var isOnlineForDatabase = {
    status: 'online',
    last_changed: firebaseconfig.database.ServerValue.TIMESTAMP,
  };
  firebaseconfig
    .database()
    .ref('.info/connected')
    .on('value', function (snapshot) {
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
};

export const getAllUserAccountsHelper = async () => {
  if (getLoggedInUserObject() && getLoggedInUserObject().userUid) {
    const allUserAccounts = await getAllUserAccounts(getLoggedInUserObject().accountId, getLoggedInUserObject().userUid);
    return allUserAccounts;
  }
};

export const getRemainingTranscriptLimit = async () => {
  return await getRemainingFeatureLimit()
    .then((data) => data.features.find((f) => f.featureName === 'Transcription_Time').remainingValueLimit)
    .catch((error) => {
      console.log(error);
    });
};
export const getRemainingDocLimit = async () => {
  return await getRemainingFeatureLimit()
    .then((data) => data.features.find((f) => f.featureName === 'File_Upload_Data_Size').remainingValueLimit)
    .catch((error) => console.log(error));
};

export const validateDocLimit = async (size) => {
  const remainingSize = await getRemainingDocLimit();
  const fileSize = (parseInt(size) / (1024 * 1024)).toFixed(2);
  return size && parseInt(size) && parseInt(remainingSize) - parseInt(fileSize);
};
export const validateDocsizeLimit = async (size, isInternalPlanActive) => {
  if (isInternalPlanActive) {
    return true;
  } else {
    const remaining = validateDocLimit(size);
    console.log(await remaining.then((val) => (val > 0 ? true : false)));
    return await remaining.then((val) => (val > 0 ? true : false));
  }
};

export const setCookie = (key, value) => {
  cookies.set(key, value, { path: '/' });
};

export const removeSpecialChars = (string) => {
  return string && string.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase();
};

export const getCookie = (key) => cookies.get(key);

export const linkify = (text) => {
  var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a href="' + url + '" style="color: blue; text-decoration: underline; cursor: pointer;" target="_blank" >' + url + '</a>';
  });
};

export const getParticipantDisplayName = (memberDisplayName, index) => {
  if (memberDisplayName && !memberDisplayName.includes('_____')) {
    return memberDisplayName;
  }
  return memberDisplayName ? memberDisplayName.split('_____')[index] : '';
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export const extractParams = (pattern, url) => {
  const paramNames = (pattern.match(/:\w+/g) || []).map((param) => param.slice(1));
  const regex = new RegExp(`^${pattern.replace(/:\w+/g, '([^\\/]+)')}$`);
  const match = url.match(regex);
  return match ? Object.fromEntries(paramNames.map((name, i) => [name, decodeURIComponent(match[i + 1])])) : {};
};

export const AllowedIOSRoute = ['payment', 'view-plans', 'manage-subscription', 'paymentCancel', 'paymentSuccess'];

export const isMemoriaUser = () => {
  const userEmail = getLoggedInUserObject() && getLoggedInUserObject().email;
  return userEmail ? userEmail.includes('memoria-inc.com') : false;
};
