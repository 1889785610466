import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/database';
import 'firebase/messaging';

const config = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

firebase.initializeApp(config);

export default firebase;
export const analytics = firebase.analytics();

export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

export const askForPermissioToReceiveNotifications = async () => {
  const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
  if (messaging) {
    try {
      const channel = new BroadcastChannel('sw-messages');
      await messaging.onMessage((payload) => {
        console.log('Payload Received - Foreground');
        channel.postMessage({
          type: 'foreground',
          data: payload,
        });
      });
      await Notification.requestPermission()
        .then((permission) => {
          console.log(`Permission ${permission}`);
          return permission;
        })
        .catch((e) => {});
    } catch (error) {
      console.error(error);
    }
  }
};
