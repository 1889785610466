import { action, makeObservable, observable } from 'mobx';
import { getProfilePicture, getUserByToken } from '../../services/MeetingService';
import { getAllUserAccountsHelper, getLoggedInUserObject, QuickCallStatus, removeSpecialChars } from '../utils';
import { toastWarning } from '../toastNotification';

export default class UserStore {
  constructor() {
    makeObservable(this, {
      searchText: observable,
      getloggedinUserdetails: action,
      userobject: observable,
      userAuthenticated: observable,
      deviceToken: observable,
      isNotificationReceived: observable,
      isSpeedCallLoading: observable,
      payload: observable,
      identity: observable,
      getLoggedInUserIdentity: action,
      allAccounts: observable,
      billingPlanData: observable,
      defaultAccount: observable,
    });
  }
  searchText = '';
  userobject = {};
  userAuthenticated = false;
  deviceToken = '';
  isNotificationReceived = false;
  isSpeedCallLoading = false;
  payload = {};
  identity = {};
  allAccounts = {};
  billingPlanData = [];
  defaultAccount = null;
  reset = () => {
    this.searchText = '';
    this.userobject = {};
    this.userAuthenticated = false;
    this.deviceToken = '';
    this.payload = {};
    this.isNotificationReceived = false;
    this.isSpeedCallLoading = false;
    this.identity = {};
    this.allAccounts = {};
    this.billingPlanData = [];
    this.defaultAccount = null;
  };
  getLoggedInUserIdentity = async () => {
    let identity = await getUserByToken();
    this.identity = identity.user;
  };

  getloggedinUserdetails = () => {
    try {
      this.userobject = JSON.parse(localStorage.getItem('memoriaUser'));
      if (this.userobject) {
        getProfilePicture().then((res) => {
          if (res) this.userobject = res.data;
        });
      }
    } catch (error) {
      console.log('error', error);
    }
    return this.userobject;
  };

  getAllUserAccounts = async () => {
    await getAllUserAccountsHelper()
      .then((accounts) => {
        if (accounts) {
          this.allAccounts = accounts.data;
          this.defaultAccount = this.allAccounts && accounts.data.find((account) => account.isDefault);

          if (this.defaultAccount) {
            localStorage.setItem('defaultAccount', this.defaultAccount.accountId);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  observeInComingCall = (speedCallData, isGroupCall) => {
    try {
      const arrUsers = speedCallData.val();
      var callRejectNum = 0;

      Object.keys(arrUsers).forEach((key) => {
        const data = arrUsers[key];
        switch (data) {
          case QuickCallStatus.JOINED:
            if (isGroupCall) {
              const emailID = getLoggedInUserObject().email;
              const isAccepted = key === removeSpecialChars(emailID);
              if (isAccepted) {
                this.isNotificationReceived = false;
                this.isSpeedCallLoading = false;
              }
            }
            break;
          case QuickCallStatus.ACCEPT:
            this.isSpeedCallLoading = true;
            break;
          case QuickCallStatus.REJECT:
            if (isGroupCall) {
              const emailID = getLoggedInUserObject().email;
              const isRejected = key === removeSpecialChars(emailID);
              if (isRejected) {
                this.isNotificationReceived = false;
                this.payload.data.deepLink = '';
              }
              callRejectNum = callRejectNum + 1;
              if (callRejectNum === Object.keys(arrUsers).length) {
                this.isNotificationReceived = false;
                this.payload.data.deepLink = '';
              }
            } else if (!isGroupCall) {
              this.isNotificationReceived = false;
              this.payload.data.deepLink = '';
            }
            break;
          case QuickCallStatus.DISCONNECT:
            this.isNotificationReceived = false;
            this.payload.data.deepLink = '';
            break;
          default:
            return null;
        }
      });
      // const speedCallDetails = speedCallData.val();
      // const callStatus = speedCallDetails.callStatus;
      // const accountId = localStorage.getItem('accountId');
      // switch (callStatus) {
      //   case QuickCallStatus.ACCEPT:
      //     // if (!isOutGoing && !isGroupCall) {
      //     //   this.isNotificationReceived = false;
      //     // } else if() {
      //     //   this.isNotificationReceived = false;
      //     //   history.push({
      //     //     pathname: `/accounts/${accountId}/meetings/${meetingId}/launch`,
      //     //   });
      //     // }
      //     if (isOutGoing) {
      //       history.push({
      //         pathname: `/accounts/${accountId}/meetings/${meetingId}/launch`,
      //       });
      //     } else {
      //       if (isGroupCall) {
      //       }
      //     }
      //     break;
      //   case QuickCallStatus.REJECT:
      //     this.isNotificationReceived = false;
      //     this.payload.data.deepLink = '';
      //     break;
      //   case QuickCallStatus.DISCONNECT:
      //     this.isNotificationReceived = false;
      //     break;
      //   default:
      //     return null;
      // }
    } catch (error) {
      console.log(error);
    }
  };

  observeOutgoingCall = (speedCallData, meetingId, history, isGroupCall) => {
    const arrUsers = speedCallData.val();
    var callRejectNum = 0;
    Object.keys(arrUsers).forEach((key) => {
      const data = arrUsers[key];

      const accountId = localStorage.getItem('accountId');
      switch (data) {
        case QuickCallStatus.JOINED:
          this.isNotificationReceived = false;
          this.isSpeedCallLoading = false;
          break;
        case QuickCallStatus.ACCEPT:
          this.isSpeedCallLoading = true;
          this.isNotificationReceived = false;
          history.push({
            pathname: `/accounts/${accountId}/meetings/${meetingId}/launch`,
          });
          break;
        case QuickCallStatus.REJECT:
          if (!isGroupCall) {
            this.isNotificationReceived = false;
            this.isSpeedCallLoading = false;
            this.payload.data.deepLink = '';
            toastWarning('The user you are trying to reach is unavailable.');
          } else if (isGroupCall) {
            callRejectNum = callRejectNum + 1;
            if (callRejectNum === Object.keys(arrUsers).length) {
              toastWarning('The user you are trying to reach is unavailable.');

              this.isNotificationReceived = false;
              this.isSpeedCallLoading = false;
              this.payload.data.deepLink = '';
            }
          }
          break;
        // case QuickCallStatus.DISCONNECT:
        //   this.isNotificationReceived = false;
        //   break;
        default:
          return null;
      }
    });
  };
}
