// @flow

import JitsiStreamBackgroundEffect from './JitsiStreamBackgroundEffect';
import { VIRTUAL_BACKGROUND_TYPE } from './JitsiStreamBackgroundEffect';
import wasmCheck from 'wasm-check';

declare function createTFLiteModule(): Promise<TFLite>;
declare function createTFLiteSIMDModule(): Promise<TFLite>;

const models = {
  model96: `${process.env.PUBLIC_URL}/virtual-background/models/segm_lite_v681.tflite`,
  model144: `${process.env.PUBLIC_URL}/virtual-background/models/segm_full_v679.tflite`,
};

const segmentationsDimensions = {
  model96: {
    height: 96,
    width: 160,
  },
  model144: {
    height: 144,
    width: 256,
  },
};

let tflite = null;
let segmentationDimensions = null;
let virtualBackgroundPreviousType = VIRTUAL_BACKGROUND_TYPE.NONE;

async function tfliteInit() {
  // console.log("tfliteInit");
  let modelResponse = null;

  if (wasmCheck.feature.simd) {
    tflite = await createTFLiteSIMDModule();
    segmentationDimensions = segmentationsDimensions.model144;
    modelResponse = await fetch(models.model144);
    // console.log(modelResponse);
  } else {
    tflite = await createTFLiteModule();
    segmentationDimensions = segmentationsDimensions.model96;
    modelResponse = await fetch(models.model96);
  }

  const modelBufferOffset = tflite._getModelBufferMemoryOffset();

  if (!modelResponse.ok) {
    throw new Error('Failed to download tflite model!');
  }

  const model = await modelResponse.arrayBuffer();
  tflite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
  tflite._loadModel(model.byteLength);
}

/**
 * Creates a new instance of JitsiStreamEffect.
 *
 * @param {MediaStream} stream - The video stream which will be used for
 * creating the presenter effect.
 * @returns {Promise<JitsiStreamEffect>}
 */
function flip(value) {
  var image = new Image();
  return new Promise((resolve) => {
    image.onload = () => {
      // console.log(image.width)
      // console.log(image.height)
      let canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      let canvasContext = canvas.getContext('2d');
      canvasContext.scale(-1, 1);
      canvasContext.drawImage(image, -image.width, 0);
      resolve(canvas.toDataURL());
    };
    image.src = value;
  });
}
export async function createJitsiStreamBackgroundEffect(type, value) {
  if (!MediaStreamTrack.prototype.getSettings && !MediaStreamTrack.prototype.getConstraints) {
    return Promise.reject(new Error('createJitsiStreamBackgroundEffect not supported!'));
  }

  if (type === VIRTUAL_BACKGROUND_TYPE.NONE) {
    virtualBackgroundPreviousType = VIRTUAL_BACKGROUND_TYPE.NONE;
    return Promise.resolve(undefined);
  }

  if (tflite === null || virtualBackgroundPreviousType !== type) await tfliteInit();

  virtualBackgroundPreviousType = type;

  const backgroundType = type;
  let blurValue = 8; // 4 or 8
  let virtualSource = '';
  console.log('backgroundType', backgroundType);

  if (backgroundType === VIRTUAL_BACKGROUND_TYPE.BLUR && value === '4') {
    blurValue = 4;
  } else if (backgroundType === VIRTUAL_BACKGROUND_TYPE.IMAGE) {
    const image = (await fetch(`${process.env.PUBLIC_URL}/virtual-background/backgrounds/${value}.jpg`)).url;
    virtualSource = await flip(image);
  } else if (backgroundType === VIRTUAL_BACKGROUND_TYPE.ADD_IMAGE) {
    virtualSource = await flip(value);
  }

  const virtualBackground = {
    backgroundType,
    blurValue,
    virtualSource,
  };
  const options = {
    ...segmentationDimensions,
    virtualBackground,
  };

  // console.log('createJitsiStreamBackgroundEffect options', options);

  return Promise.resolve(new JitsiStreamBackgroundEffect(tflite, options));
}
